import { getApp } from '@/helpers/feathers'

/**
 * Holt die Dashboard ID von dem eigenen Dashboard des Users, falls dieser ein
 * eigenes Dashboard hat. Ansonsten wird die erste ID der mit dem User
 * verknüpften Rollendashboards zurückgegeben. Falls der User gar kein Dashboard
 * hat, wird null rurückgegeben.
 *
 * @returns ID des eines Dashboards oder null
 */
export async function getDashboardID(): Promise<number | null> {
  let results

  const app = await getApp()

  let userDashboardId = null

  try {
    results = await app.service('dashboard-navigation').find()
  } catch (error) {
    throw new Error(`Service not available. Error: ${error}`)
  }
  if (typeof results === 'undefined' || results.length === 0) {
    return null
  }

  for (const result of results) {
    if (result.name === null) {
      userDashboardId = result.id
      break
    }
  }

  if (userDashboardId === null) {
    return results[0].id
  }

  return userDashboardId
}
