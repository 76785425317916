
import 'country-flag-icons/flags/3x2/flags.css'
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Zeigt die Länderflagge eines gegebenen Kürzels an.
 */
@Component({})
export default class CountryFlag extends Vue {
  /**
   * Länderkürzel.
   */
  @Prop({ type: String, required: true })
  public flag!: string

  /**
   * Konvertiert das übergebene Kürzel in das richtige Format.
   *
   * @returns Formatiertes Kürzel.
   */
  public get flagName(): string {
    const name = this.flag.toUpperCase()

    if (name === 'EN') {
      return 'GB'
    }

    if (name === 'UM') {
      return 'US'
    }

    if (name === 'UK') {
      return 'UA'
    }

    if (name === 'SL') {
      return 'SI'
    }

    return name
  }
}
