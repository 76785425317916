export const de = {
  system: {
    notification: {
      connectivity: {
        online: 'Sie sind nun online.',
        offline: 'Sie sind nun offline.'
      },
      languageOverridden:
        'Die zuvor von Ihnen ausgewählte Sprache ist auf diesem System nicht ' +
        'verfügbar. Die Sprache wurde auf die Standardsprache gesetzt.',
      licenseInvalid:
        'Ihre eplas-Lizenz ist am {expired} abgelaufen. Bitte wenden Sie ' +
        'sich an den eplas-Support',
      licenseCheckFailed:
        'Ihre eplas-Lizenz konnte nicht überprüft werden. Bitte wenden Sie ' +
        'sich an den eplas-Support'
    },
    languages: {
      cz: 'Čeština',
      de: 'Deutsch',
      en: 'English',
      es: 'Español',
      fi: 'Suomi',
      fr: 'Français',
      hr: 'Kroatisch',
      hu: 'Magyar',
      it: 'Italiano',
      nl: 'Nederlands',
      pl: 'Polski',
      ro: 'Română',
      ru: 'Русский',
      sl: 'Slovenščina',
      tr: 'Türkçe',
      gr: 'Ελληνικά',
      bg: 'български',
      uk: 'український'
    },
    employee: {
      nameFormat: [
        '-',
        '{firstName}',
        '{lastName}',
        '{firstName} {lastName}',
        '({personnelNumber})',
        '{firstName} ({personnelNumber})',
        '{lastName} ({personnelNumber})',
        '{firstName} {lastName} ({personnelNumber})'
      ]
    },
    contentList: {
      footer:
        'Keine Einträge gefunden | {0} Eintrag gefunden | {0} Einträge gefunden'
    },
    general: {
      print: 'Drucken',
      search: 'Suche',
      close: 'Schließen',
      create: 'Erstellen',
      edit: 'Bearbeiten',
      copy: 'Kopieren',
      back: 'Zurück',
      remove: 'Entfernen',
      abort: 'Abbrechen',
      finished: 'Fertig',
      ok: 'OK',
      cancel: 'Abbrechen',
      activate: 'Aktivieren',
      deactivate: 'Deaktivieren',
      update: 'Aktualisieren',
      yes: 'Ja',
      no: 'Nein'
    }
  },
  colorPicker: {
    color: {
      missing: 'keine Farbe gewählt'
    }
  },
  colorSettings: {
    header: {
      title: 'Farbeinstellungen'
    },
    colorPicker: {
      colorSelection: 'Farbe auswählen'
    },
    color: {
      primary: 'Primärfarbe',
      secondary: 'Sekundärfarbe',
      accent: 'Akzentfarbe'
    },
    changeColorInfo: {
      darkMode: {
        active:
          'Deaktivieren Sie die dunkle Oberfläche in den Benutzereinstellungen, um die Farben der Standardoberfläche anzupassen.',
        inactive:
          'Aktivieren Sie die dunkle Oberfläche in den Benutzereinstellungen, um deren Farben anzupassen.'
      }
    }
  },
  columnTree: {
    placeholder: {
      new: '<Neuer Eintrag>',
      rename: 'Bezeichnung eingeben'
    },
    layer: {
      empty: 'Keine Einträge'
    },
    action: {
      addEntry: 'Eintrag hinzufügen'
    }
  },
  dashboardViewer: {
    placeholder: {
      unnamedRow: 'unbenannte Reihe'
    },
    widgetTitle: {
      instructions: 'Unterweisungen',
      documents: 'Dokumente',
      appointments: 'Termine',
      tasks: 'Aufgaben',
      audits: 'Vorgänge'
    },
    widgetDescription: {
      open: 'offen',
      done: 'erledigt',
      total: 'gesamt',
      label: {
        first: 'Zähler',
        second: 'Nenner'
      },
      hint: {
        first: 'Gibt den Fortschritt als Zahl an',
        second:
          'Gibt die Anzahl des ausgewählten Bereichs insgesamt als Zahl an'
      },
      validationError: 'Feld darf nicht leer sein'
    },
    error: {
      noWidgetsFound:
        'Es wurden noch keine Widgets in diesem Dashboard hinterlegt, bitte wenden Sie sich an Ihren Vorgesetzten, um Zugriff auf die von ihnen benötigten Widgets zu bekommen.'
    }
  },
  substanceImport: {
    importForm: {
      pdfImportLabel: 'PDF-Import',
      excelImportLabel: 'Excel-Import',
      fileUpload: 'Datei-Upload',
      pdfUpload: 'Laden Sie ein Datenblatt als PDF-Datei hoch',
      excelUpload: 'Laden Sie ein Datenblatt als Excel-Datei hoch',
      noXML: 'Eine oder mehrere PDF-Dateien enthalten keine XML-Datei',
      wrongType: 'Falsches Dateiformat',
      import: 'Import',
      excelUploadFailed: 'Upload der Excel Datei fehlgeschlagen',
      xmlUploadFailed: 'Upload einer oder mehrerer XML Dateien fehlgeschlagen',
      extractingAttachmentsFailed: 'Auslesen der Anhänge fehlgeschlagen',
      processPdfFailed: 'Fehler beim Verarbeiten der PDF'
    },
    feedbackList: {
      result: 'Resultat',
      warning: 'WARNUNG',
      substance: 'Gefahrstoff',
      substanceExisting: 'Stoff bereits vorhanden',
      invalidValues: 'Ungültige Werte (siehe Details) ',
      error: 'FEHLER: Import aktuell nicht möglich',
      updated: 'erfolgreich aktualisiert',
      created: 'erfolgreich angelegt',
      and: 'und',
      save: 'Speichern',
      successfulImports: 'Erfolgreiche Importe',
      criticalImports: 'Kritische Importe',
      failedImports: 'Fehlgeschlagene Importe',
      importAnyway: 'Trotzdem importieren',
      all: 'Alle',
      logAll: 'Alle Logs speichern',
      unknownSubstance: 'Unbekannter Stoff'
    },
    excelLogs: {
      row: 'Zeile',
      nameNotFound: 'Stoffname nicht gefunden',
      invalidHStatement: 'ist kein gültiger H-Satz',
      invalidPStatement: 'ist kein gültiger P-Satz',
      notExistingHStatement: 'ist kein existierender H-Satz',
      notExistingPStatement: 'ist kein existierender P-Satz',
      invalidCompositionState: 'ist keine gültige Angabe über den Reinstoff',
      invalidGHSSymbol: 'ist kein gültiges GHS-Symbol',
      invalidSignalword: 'ist kein gültiges Signalwort',
      invalidStorageClass: 'ist keine gültige Lagerklasse',
      invalidUnNr: 'ist keine gültige UN-Nr',
      invalidWaterHazardClass: 'ist keine gültige Wassergefährdungsklasse',
      invalidBoilingPoint: 'ist kein gültiger Siedepunkt',
      invalidVaporPressure: 'ist kein gültiger Dampfdruck',
      invalidVaporPressureUnit: 'ist keine gültige Dampfdruck-Einheit',
      invalidPhysicalState: 'ist kein gültiger Aggregatzustand'
    }
  },
  mle: {
    title: 'Übersetzungen für {field} bearbeiten',
    fields: {
      title: 'Widget-Titel',
      description: 'Widget-Beschreibung',
      buttonLabel: 'Schaltflächenbeschreibung',
      dashboard: 'Dashbaord'
    },
    validationErrorLocale:
      'Titel für die aktuell gewählte Sprache darf nicht leer sein',
    validationErrorFallback:
      'Titel für die Fallback-Sprache darf nicht leer sein'
  },
  widgetEditor: {
    title: {
      new: 'Widget hinzufügen',
      update: 'Widget aktualisieren'
    },
    buttons: {
      create: '@:system.general.create',
      update: '@:system.general.update',
      cancel: '@:system.general.cancel'
    },
    types: {
      ProgressCircle: {
        name: 'Fortschrittsanzeige',
        description:
          'Gibt den Fortschritt eines bestimmten Bereichs an, z.B. für Daten wie `erledigt/unerledigt`.'
      },
      ActionButton: {
        name: 'Schaltfläche',
        description:
          'Diese Schaltfläche dient als Absprungpunkt zu diversen Orten in eplas oder als Schalter für Funktionen.'
      },
      ProgressCircleFromTo: {
        name: 'Fortschrittsanzeige (von / bis)',
        description:
          'Gibt den Fortschritt eines bestimmten Bereichs an, z.B. für eine prozentuale Darstellung der Werte.'
      },
      KeyNumber: {
        name: 'Kennzahlen',
        description: 'Gibt ein Ergebnis oder eine Kennzahl aus.'
      }
    },
    notification: {
      insert: {
        success: 'Widget wurde erfolgreich hinzugefügt.',
        error: 'Beim Hinzufügen des Widgets ist ein Fehler aufgetreten.'
      },
      update: {
        success: 'Widget wurde erfolgreich aktualisiert.',
        error: 'Beim Aktualisieren des Widgets ist ein Fehler aufgetreten.'
      }
    },
    fields: {
      titleColor: {
        label: 'Farbe der Widgetleiste'
      },
      titleIcon: {
        label: 'Titel Icon',
        hint: 'Zeichen, welches das Widget repräsentiert.'
      },
      title: {
        label: 'Widget Titel',
        hint: 'Der Name des Widgets. Erscheint in der Überschrift.',
        validationError: 'Dieses Widget benötigt einen Titel.'
      },
      widgetType: {
        label: 'Widget Typ'
      },
      size: {
        label: 'Widget Größe'
      },
      description: {
        label: 'Beschreibung des Widgets',
        hint: 'Beschreibung, was das Widget tut. Erscheint am unteren Ende des Widgets.'
      },
      query: {
        label: 'Datenquelle',
        validationError:
          'Dieser Widget Typ benötigt eine ausgewählte Datenquelle.'
      },
      action: {
        label: 'Aktion',
        hint: 'Sprungpunkt innerhalb eplas',
        validationError: 'Es muss eine Aktion für dieses Widget gewählt werden.'
      },
      actionIcon: {
        label: 'Schaltfläche Icon',
        hint: 'Zeichen, welches den Button darstellt.',
        validationError: 'Es muss ein Icon gewählt werden.'
      },
      actionLabel: {
        label: 'Schaltfläche Label',
        hint: 'Beschreibung unterhalb des Icons.'
      },
      progressCircleColor: {
        label: 'Farbe der Fortschrittsanzeige'
      },
      doneOrOpenAppointments: {
        label: 'Sprungziel offen/erledigt',
        hint: 'Zu offenen oder erldigten Terminen springen bei Klick auf Widget'
      },
      appointmentDate: {
        label: 'Startdatum für den Zeitfilter',
        hint: 'Termine anzeigen von gewähltem Datum bis heute + 2 Monate'
      }
    },
    queries: {
      2: {
        title: 'Aktive und inaktive Mitarbeiter',
        description:
          'Zeigt die Anzahl der aktiven und inaktiven Mitarbeiter in eplas.'
      },
      5: {
        title: 'Termine des Mitarbeiters',
        description:
          'Gibt die Zahl der erledigten und offenen Termine (Termine und Vorsorgen) des eingeloggten Users zurück.'
      },
      6: {
        title: 'Dokumente des Mitarbeiters',
        description:
          'Gibt die Zahl der erledigten und offenen Dokumente des eingeloggten Users zurück.'
      },
      7: {
        title: 'Themen des Mitarbeiters',
        description:
          'Gibt die Zahl der erledigten und offenen Themen des eingeloggten Users zurück.'
      },
      9: {
        title: 'Audits des Mitarbeiters',
        description:
          'Gibt die Zahl der erledigten und offenen Audits des eingeloggten Users zurück.'
      },
      10: {
        title: 'Aufgaben des Mitarbeiters',
        description:
          'Anzahl der offenen und abgeschlossenen Aufgaben des angemeldeten Users vom 1. des aktuellen Monats letzten Jahres bis 60 Tage von heute in der Zukunft.'
      },
      11: {
        title: 'Summe einer Checklisten-Variable',
        description: 'Gibt die Summe einer Checklisten-Variable aus.'
      },
      12: {
        title: 'Kennzahl',
        description: 'Gibt eine Kennzahl zurück.'
      },
      13: {
        title: 'Zwei Kennzahlen',
        description: 'Gibt zwei Kennzahlen zurück (z.B.: 4/9).'
      },
      14: {
        title: 'Überfällige Aufgaben des Mitarbeiters',
        description:
          'Gibt die Anzahl der überfälligen Aufgaben des angemeldeten Users zurück.'
      },
      15: {
        title: 'Unfälle: 1000 Mann Quote',
        description:
          'Gibt die TMQ eines Jahres in Bezug auf den angemeldeten Benutzer unter Berücksichtigung der angegebenen Zuständigkeiten an.'
      },
      16: {
        title: 'Unfälle: Ausfalltage gesamt',
        description:
          'Gibt die Summe der Ausfalltage eines Jahres an, die bei meldepflichtigen Unfällen aufgekommen sind. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      17: {
        title: 'Unfälle: offene Aufgaben',
        description:
          'Gibt die Summe der offenen Aufgaben aus, die in Zusammenhang mit Unfällen stehen. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      18: {
        title: 'Unfälle: erledigte Aufgaben',
        description:
          'Gibt die Summe der erledigten Aufgaben aus, die in Zusammenhang mit Unfällen stehen. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      19: {
        title: 'Unfälle: Ausfalltage pro Mitarbeiter',
        description:
          'Gibt die Summe der Ausfalltage eines Jahres bezogen auf die Anzahl der Mitarbeiter an, die bei meldepflichtigen Unfällen aufgekommen sind. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      20: {
        title: 'Unfälle: Ausfalltage pro Unfall',
        description:
          'Gibt die Summe der Ausfalltage eines Jahres bezogen auf die Anzahl der Unfälle an, die bei meldepflichtigen Unfällen aufgekommen sind. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      21: {
        title: 'Unfälle: Gesamtkosten',
        description:
          'Gibt die Gesamtkosten eines Jahres an, die durch Unfälle entstanden sind. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      22: {
        title: 'Unfälle: Durchschnittliche Kosten pro Mitarbeiter',
        description:
          'Gibt die Gesamtkosten eines Jahres, die durch Unfälle entstanden sind, bezogen auf die Anzahl der Mitarbeiter an. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      23: {
        title: 'Unfälle: Durchschnittliche Kosten pro Unfall',
        description:
          'Gibt die Gesamtkosten eines Jahres, die durch Unfälle entstanden sind, bezogen auf die Anzahl der Unfälle an. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      24: {
        title: 'Unfälle: Anzahl der Unfälle direkt unterstellter Mitarbeiter',
        description:
          'Gibt die Anzahl der Unfälle der Mitarbeiter zurück, die dem angemeldeten Benutzer direkt unterstellt sind.'
      },
      25: {
        title: 'Unfälle: Anzahl Unfälle nach Zuständigkeiten',
        description:
          'Gibt die Anzahl der Unfälle zurück. Die Zuständigkeiten des angemeldeten Benutzers werden hier berücksichtigt.'
      },
      26: {
        title: 'Unfälle: Anzahl Unfälle',
        description:
          'Gibt die Anzahl der Unfälle innerhalb eines Jahres zurück. Zuständigkeiten werden nicht berücksichtigt.'
      }
    }
  },
  queryFilters: {
    general: {
      noFilter: 'keine Filterung',
      noNumber: 'Dieses Feld muss eine Nummer enthalten oder leer sein',
      values: {
        true: '@:system.general.yes',
        false: '@:system.general.no'
      }
    },
    filter: {
      uwtyp: {
        label: 'Unterweisungstyp',
        hint: 'Schränkt das Ergebnis auf einen Unterweisungstyp ein.',
        values: {
          0: 'Unterweisungen',
          1: 'Termine',
          2: 'Dokumente',
          3: 'SCORM',
          4: 'Vorsorgeuntersuchungen'
        }
      },
      taskUserRole: {
        label: 'Benutzerrolle',
        hint: 'Schränkt das Ergebnis auf die Menge ein, in dem der Benutzer die angegebenen Rollen inne hat.',
        values: {
          0: 'Verantwortlich, Ausführender, Erfasser oder Beteiligter',
          1: 'Verantwortlich',
          2: 'Ausführender, Erfasser oder Beteiligter',
          4: 'Wirksamkeitsprüfer'
        }
      },
      reportable: {
        label: 'Meldepflicht',
        hint: 'Filtert die Unfälle nach meldepflichtig oder nicht-meldepfichtig',
        values: {
          0: 'Nur meldepflichtige Unfälle',
          1: 'Nur nicht-meldepflichtige Unfälle',
          2: 'Alle Unfälle'
        }
      },
      tovalue: {
        label: 'Nenner',
        hint: 'Gibt die hintere Zahl an, welche im Fortschrittskreis als Grundlage dient.'
      },
      checklistvariableid: {
        label: 'ID der Checklistenvariable',
        hint: 'Bezug der Daten'
      },
      year: {
        label: 'Jahreszahl',
        hint: 'Gibt das Jahr an, welches als Bezug gilt.'
      },
      showappointments: {
        label: 'Termine einbeziehen',
        hint: 'Ist der Filter ausgewählt, werden normale Termine mit in die Berechnung einbezogen.'
      },
      showhealthcare: {
        label: 'Vorsorgeuntersuchungen einbeziehen',
        hint: 'Ist der Filter ausgewählt, werden Vorsorgeuntersuchungen mit in die Berechnung einbezogen.'
      },
      category: {
        label: 'ID der Audit-Kategorie',
        hint: 'Wird die ID einer Audit-Kategorie angegeben, wird nur diese ausgewertet.'
      },
      thisyear: {
        label: 'aktuelles Jahr',
        hint: 'Gibt an, ob nur das aktuelle Kalenderjahr ausgewertet werden soll.'
      },
      total: {
        label: 'Nenner',
        hint: 'Überschreibt den Gesamtwert, der zurückgegeben wird.'
      },
      responsibilityDisciplinaric: {
        label: 'disziplinarische Zuständigkeit',
        hint: 'Bezieht Mitarbeiter auf Grund Ihrer disziplinarischen Zuständigkeit mit ein.'
      },
      responsibilityOrganisatoric: {
        label: 'organisatorische Zuständigkeit',
        hint: 'Bezieht Mitarbeiter auf Grund Ihrer organisatorischen Zuständigkeit mit ein.'
      }
    }
  },
  widgetAction: {
    fields: {
      placeholderChoose: 'Bitte wählen Sie eine Aktion',
      placeholderSet: 'Bitte geben Sie eine Aktion an'
    },
    notification: {
      requestId: {
        label: 'ID erforderlich',
        text: 'Die gewählte Aktion benötigt eine ID, um korrekt aufgerufen zu werden. Bitte geben Sie diese ID an.'
      }
    },
    actions: {
      name: {
        dashboardOverview: 'Dashboard: Übersicht',
        dashboardInstructions: 'Dashboard: Unterweisungen & SCORM',
        dashboardDocuments: 'Dashboard: Dokumente',
        dashboardAppointments: 'Dashboard: Termine & arbeitsmed. Vorsorge',
        dashboardTasks: 'Dashboard: Aufgaben & Maßnahmen',
        dashboardTasksResponsible:
          'Dashboard: Aufgaben & Maßnahmen als Verantwortlicher',
        dashboardAudits: 'Dashboard: Audits',
        dashboardEmployeeSearch: 'Dashboard: Mitarbeiter-Suche',
        dashboardSubstances: 'Dashboard: Gefahrstoffe',
        dashboardDirectives: 'Dashboard: Betriebsanweisungen',
        dashboardMySettings: 'Dashboard: Eigene Einstellungen',
        dashboardVoluntaryTopics: 'Dashboard: Wunschthemen',
        dashboardAuditOverview: 'Dashboard: Audit Übersicht',
        dashboardAccidentOverview: 'Dashboard: Elektr. Verbandbuch Übersicht',
        viewAccidentreportWizard: 'Unfallberichts-Assistent',
        viewAssignments: 'Zuweisungen',
        viewCallback: 'Rückfragen',
        viewEmployee: 'Mitarbeiter',
        viewFeedback: 'Feedback',
        viewGroupfeedback: 'Gruppenrückmeldung',
        viewInstructions: 'Unterweisungen',
        viewAppointments: 'Termine',
        viewDocuments: 'Dokumente',
        viewScorm: 'SCORM',
        viewVoluntarytopics: 'Wunschthemen',
        viewHazardanalysis: 'Gefährdungsbeurteilungen',
        viewSubstances: 'Gefahrstoffe',
        viewProviders: 'Anbieter',
        viewDirectiveMashine: 'Betriebsanweisung: Maschine',
        viewDirectiveBio: 'Betriebsanweisung: Biostoff',
        viewDirectiveSubstance: 'Betriebsanweisung: Gefahrstoff',
        viewDirectiveOrga: 'Betriebsanweisung: Organisation',
        viewDirectiveUser: 'Betriebsanweisung: Benutzer',
        viewAppointment: 'Terminverwaltung',
        viewAccidentReports: 'Liste der Unfallberichte',
        viewMedAppointments: 'Liste der arbeitsmed. Vorsorgen',
        viewHealthPrepResolver: 'Angebotsuntersuchungen zustimmen',
        openReport: 'Report drucken',
        createOfflineAudit: 'Offline Audit erstellen'
      }
    }
  },
  widgetBasic: {
    action: {
      title: 'Widget Aktion'
    },
    content: {
      missing: 'Fehlender Inhalt',
      deactivated: 'Widget deaktiviert'
    },
    remove: {
      titel: 'Widget entfernen',
      text: 'Sind Sie sicher, dass Sie dieses Widget entfernen möchten? Dem Dashboard zugewiesene Mitarbeiter haben keinen Zugriff mehr auf das Widget.'
    },
    deactivate: {
      title: 'Widget deaktivieren',
      text: 'Sind Sie sicher, dass Sie dieses Widget deaktivieren möchten? Dem Dashboard zugewiesene Mitarbeiter haben keinen Zugriff mehr auf das Widget.'
    },
    notification: {
      remove: {
        success: 'Erfolgreich entfernt.',
        error: 'Beim Entfernen des Widgets ist ein Fehler aufgetreten.'
      },
      update: {
        error: 'Beim Update des Widgets ist ein Fehler aufgetreten.'
      }
    }
  },
  actionButton: {
    title: {
      auditCategory: 'zur Übersicht',
      accidentAssistant: 'Unfall melden',
      activity: 'Vorgang erfassen'
    }
  },
  helpCenter: {
    tabs: {
      support: 'Support',
      manual: 'Handbuch'
    }
  },
  helpCenterManual: {
    manual: {
      title: 'Dashboard Handbuch',
      subtitle: 'Klicken Sie hier, um das Handbuch zum Dashboard zu lesen'
    }
  },
  helpCenterSupport: {
    title: 'Support kontaktieren',
    staff: {
      text: 'Unser Support ({email}) ist besetzt in der Zeit von {days} von {time} Uhr.',
      email: 'support@eplas.net',
      days: 'Montag bis Freitag'
    },
    reachability: {
      text: 'Einen Support-Mitarbeiter erreichen Sie telefonisch direkt in der Zeit von Montag - Freitag {from} bis {to} Uhr unter der Telefonnummer: {telephoneNumber}.',
      telephoneNumber: '+49 (0)2771 - 87 97 - 400'
    }
  },
  infoCenter: {
    tabs: {
      version: 'Version',
      changelog: 'Changelog',
      thirdParty: 'Lizenzierung'
    }
  },
  infoCenterVersion: {
    header: {
      title: 'eplas 10'
    },
    description: {
      version: 'Version {versionNumber}',
      channel: 'Diese Installation befindet sich auf dem {channelName}-Channel'
    },
    website: {
      link: 'https://www.eplas.net'
    }
  },
  infoCenterThirdParty: {
    title: 'Lizenzierung',
    subtitle:
      '{link} klicken, um die Liste der genutzten Drittanbietersoftware einzusehen.',
    link: 'Hier'
  },
  aboutSection: {
    text: {
      copyright: '(c) {year} Jochen Blöcher GmbH. Alle Rechte vorbehalten.'
    },
    statements: {
      imprintLabel: 'Impressum',
      privacyLabel: 'Datenschutzhinweise'
    }
  },
  sideNavigation: {
    subheader: {
      start: 'Start',
      administration: 'Verwaltung',
      develop: 'Entwicklung',
      classic: 'Klassisches eplas',
      activity: 'Vorgänge',
      overview: 'Übersichten'
    },
    header: {
      structure: 'Strukturen',
      system: 'System',
      activity: 'Vorgänge',
      dashboard: 'Dashboard'
    },
    listItem: {
      dashboard: 'Mein Dashboard',
      classicDashboard: 'Dashboard',
      classicAdministration: 'Administration',
      auditsOnline: 'Vorgänge Online',
      auditsOffline: 'Vorgänge Offline',
      checklistTest: 'Test Checklisten',
      settings: 'Einstellungen',
      playground: 'Spielwiese',
      playgroundWidgets: 'Spielwiese: Widgets',
      playgroundTree: 'Spielwiese: Baum',
      dashboardAdministration: 'Dashboard-Administration',
      substanceImport: 'Gefahrstoffimport'
    }
  },
  quickAccess: {
    subheader: {
      title: 'Schnellzugriff'
    },
    itemTitle: {
      email: 'E-Mail',
      report: 'Meldung erfassen',
      accidentReport: 'Unfallbericht'
    }
  },
  filterList: {
    title: {
      search: 'Suchen nur in den Spalten...',
      filter: 'Filter'
    },
    date: {
      from: 'Von',
      to: 'Bis'
    },
    buttonLabel: {
      apply: 'Anwenden'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Passwort',
      username: 'Benutzername'
    },
    welcomeMessage: {
      text: 'Willkommen bei eplas!'
    },
    forwardMessage: {
      title: 'Login erfolgreich',
      text: 'Sie werden jeden Moment weitergeleitet...'
    },
    buttonLabel: {
      login: 'Anmelden'
    },
    loginMethod: {
      local: 'Login',
      saml: 'Unternehmenslogin',
      ldap: 'Windowslogin',
      external: 'Fremdmitarbeiter',
      pin: 'PIN-Login'
    },
    forgotPassword: {
      button: 'Passwort vergessen?'
    },
    error: {
      noOfflineLogin: 'Im Offline Modus kann kein Login ermöglicht werden!',
      username:
        'Login fehlgeschlagen. Kontrollieren Sie Ihren Benutzernamen und Ihr Passwort.',
      usernameValidation: 'Ein Benutzername muss angegeben werden.',
      passwordValidation: 'Das Passwort-Feld darf nicht leer sein.',
      serverNotAvailable: {
        title: 'Sitzung abgelaufen',
        text:
          'Ihre Sitzung ist abgelaufen. Bitte laden Sie die Seite neu, ' +
          'um sich wieder anzumelden',
        button: 'Seite neu laden'
      }
    }
  },
  setPasswordContent: {
    password: {
      newPassword: 'Neues Passwort',
      passwordConfirmation: 'Passwort wiederholen',
      newPasswordConfirmation: 'Neues Passwort wiederholen',
      setPasswordConfirm: 'Passwort setzen',
      setPasswordTitle: 'Erste Anmeldung: Bitte ändern Sie darum ihr Passwort',
      success: 'Passwort erfolgreich geändert'
    },
    passwordRules: {
      title: 'Passwortrichtlinien',
      maximumLength: 'Maximallänge',
      minimumLength: 'Minimallänge',
      minimumDigits: 'Min. Anzahl an Zahlen',
      minimumUpperCase: 'Min. Anzahl an Großbuchstaben',
      minimumLowerCase: 'Min. Anzahl an Kleinbuchstaben',
      minimumSpecialCharacters: 'Min. Anzahl an Sonderzeichen',
      allowedSpecialCharacters: 'Erlaubte Sonderzeichen'
    },
    error: {
      general: 'Beim Ändern des Passworts ist ein Fehler aufgetreten.',
      passwordConfirmationValidation: 'Die Passwörter stimmen nicht überein.',
      maximumLength: 'Das Passwort ist zu lang.',
      minimumLength: 'Das Passwort ist zu kurz.',
      minimumDigits: 'Das Passwort enthält nicht genug Zahlen.',
      minimumUpperCase: 'Das Passwort enthält nicht genug Großbuchstaben.',
      minimumLowerCase: 'Das Passwort enthält nicht genug Kleinbuchstaben.',
      minimumSpecialCharacters: 'Das Passwort enthält keine Sonderzeichen.',
      allowedSpecialCharacters:
        'Das Passwort enthält nicht erlaubte Sonderzeichen.'
    },
    buttonLabel: {
      login: 'Zur Login Seite'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Passwort vergessen',
      email: 'E-Mail-Adresse Ihres Benutzers',
      confirm: 'E-Mail absenden',
      error: 'Die E-Mail-Adresse ist ungültig.',
      cancel: 'Abbrechen',
      hint:
        'Bitte geben Sie die E-Mail-Adresse Ihres Benutzers ein. Es wird ' +
        'Ihnen daraufhin eine E-Mail mit einem Link geschickt. Über diesen ' +
        'Link können Sie ein neues Passwort wählen. Falls Sie Ihre ' +
        'E-Mail Adresse nicht kennen oder Ihr Benutzer keine E-Mail Adresse ' +
        'hinterlegt hat, wenden Sie sich bitte an Ihren zuständigen ' +
        'Administrator.',
      emailSent: 'Es wurde eine E-Mail an die E-Mail-Adresse geschickt.'
    }
  },
  passwordReset: {
    title: 'Bitte ändern Sie Ihr Passwort'
  },
  passwordChange: {
    title: 'Passwort ändern',
    hint: 'Bitte wählen Sie eine neues Passwort',
    currentPassword: 'Aktuelles Passwort',
    error: {
      requiredField:
        'Das "@:passwordChange.currentPassword"-Feld darf nicht leer sein.'
    }
  },
  auditList: {
    column: {
      due: 'Fällig am',
      name: 'Bezeichnung',
      category: 'Kategorie',
      responsible: 'Verantwortlicher',
      executor: 'Ausführender',
      affected: 'Betroffener',
      creator: 'Ersteller',
      actions: 'Maßnahmen',
      progress: 'Fortschritt'
    },
    filter: {
      actionTrue: 'Offene Maßnahmen',
      doneTrue: 'Erledigt',
      doneFalse: 'Abgeschlossenen'
    },
    convert: {
      title: 'Alle Offline-Vorgänge umwandeln',
      start:
        'Möchten Sie wirklich alle Offline-Vorgänge zu Online-Vorgängen umwandeln? Danach sind die umgewandelten Vorgänge nicht mehr offline verfügbar.',
      working: '{count} von {total} Offline-Vorgänge umgewandelt…',
      success: 'Es wurden alle Offline-Vorgänge erfolgreich umgewandelt.',
      warning: 'Es konnten nicht alle Offline-Vorgänge umgewandelt werden.',
      failed: 'Umwandeln aller Offline-Vorgänge ist fehlgeschlagen.',
      convertButton: 'Umwandeln'
    }
  },
  auditView: {
    toolbarTitle: {
      title: '{prefix} {label} {suffix}'
    },
    convert: {
      title: 'Offline-Vorgang zu einem Online-Vorgang umwandeln',
      start:
        'Möchten Sie den Offline-Vorgang wirklich zu einem Online-Vorgang umwandeln? Dadurch wird der Offline-Vorgang gelöscht.',
      working: 'Offline-Vorgang wird umgewandelt…',
      completed:
        'Der Offline-Vorgang wurde erfolgreich zu einem Online-Vorgang umgewandelt.',
      failed:
        'Die Offline-Vorgänge konnte nicht zu einem Online-Vorgang umgewandelt werden.',
      showAuditListOffline: 'Offline-Vorgang Liste anzeigen',
      convertAuditButton: 'Offline-Vorgang umwandeln',
      showAuditOnline: 'Online-Vorgang anzeigen'
    },
    remove: {
      title: 'Offline-Vorgang löschen',
      text: 'Möchten Sie den Offline-Vorgang wirklich löschen? Dieser kann nicht wiederhergestellt werden.',
      continue: 'Fortfahren'
    },
    error: {
      notFound: 'Kein Vorgang gefunden.',
      missingCollectionID: 'Keine Checkliste gefunden.',
      createdFailed: 'Erstellen der Vorgänge fehlgeschlagen.'
    },
    notification: {
      error: {
        convertOfflineAudit:
          'Übertragen des Offline-Vorgangs im Offline-Modus nicht möglich.',
        offlineAuditDeleteFailed:
          'Der Offline-Vorgang konnte nicht gelöscht werden.'
      }
    }
  },
  employeePicker: {
    title: {
      overview: 'Mitarbeiter auswählen',
      filter: '@:employeePicker.title.overview - Filter',
      tree: '@:employeePicker.title.filter'
    },
    buttonLabel: {
      select:
        'Mitarbeiter auswählen | Mitarbeiter auswählen | Mitarbeiter auswählen ({count})',
      selected: {
        single:
          "Kein Mitarbeiter ausgewählt | Mitarbeiter '{entry}' ausgewählt | Mitarbeiter '{entry}' ausgewählt",
        multiple:
          "Keine Mitarbeiter ausgewählt | Mitarbeiter '{entry}' ausgewählt | {count} Mitarbeiter ausgewählt"
      }
    },
    buttonTooltip: {
      remove: 'Die Auswahl der Mitarbeiter aufheben.'
    }
  },
  itemPicker: {
    buttonLabel: {
      default:
        'kein Element ausgewählt | ein Element ausgewählt | {count} Elemente ausgewählt',
      locations:
        'kein Ort ausgewählt | ein Ort ausgewählt | {count} Orte ausgewählt',
      properties:
        'keine Eigenschaft ausgewählt | eine Eigenschaft ausgewählt | {count} Eigenschaften ausgewählt',
      departments:
        'keine Abteilung ausgewählt | eine Abteilung ausgewählt | {count} Abteilungen ausgewählt'
    },
    footer: {
      counter:
        'kein Element ausgewählt | ein Element ausgewählt | {count} Elemente ausgewählt'
    }
  },
  dashboardRoles: {
    remove: {
      title: 'Dashboard endgültig löschen',
      text: "Entfernen bestätigen - Sind Sie sicher, dass Sie das Dashboard '{role}' entfernen möchten? Alle auf dem Dashboard vorhandenen Widgets werden entfernt und zugewiesene Mitarbeiter haben keinen Zugriff mehr auf das Dashboard.",
      confirm: '@:system.general.remove',
      cancel: '@:system.general.cancel'
    },
    create: {
      title: 'Rolle wählen',
      text: 'Bitte wählen Sie die Rolle, für die ein neues Dashboard angelegt werden soll.',
      cancel: '@:system.general.cancel'
    },
    buttonTooltip: {
      add: 'Dashboard hinzufügen',
      edit: 'Dashboard bearbeiten',
      remove: 'Dashboard löschen'
    },
    notification: {
      add: {
        success: 'Dashboard erfolgreich angelegt.',
        error: 'Fehler beim Anlegen eines neuen Dashboards.'
      },
      remove: {
        error: 'Beim Entfernen des Dashboards ist ein Fehler aufgetreten.',
        success: 'Erfolgreich entfernt.'
      }
    }
  },
  router: {
    tab: {
      title: ' - eplas NXT'
    },
    title: {
      login: 'Login',
      audit: 'Vorgang',
      audits: 'Vorgänge',
      playgroundTree: 'Spielwiese: Baum',
      playgroundWidgets: 'Spielwiese: Widgets',
      playground: 'Spielwiese',
      debugConnectivity: 'Debug: Connectivity',
      settings: 'Einstellungen',
      dashboard: 'Dashboard',
      dashboardRoles: 'Dashboard-Administration',
      editDashboard: 'Dashboard bearbeiten',
      passwordReset: 'Passwort ersetzen',
      substanceImport: 'Gefahrstoffimport'
    }
  },
  addDashboardElement: {
    buttonLabel: {
      addWidget: 'Widget hinzufügen',
      addRow: 'Zeile hinzufügen',
      removeRow: 'Zeile entfernen'
    },
    notification: {
      addRow: {
        success: 'Zeile wurde erfolgreich hinzugefügt.',
        error: 'Fehler beim Hinzufügen einer neuen Zeile.'
      },
      removeRow: {
        success: 'Zeile wurde erfolgreich entfernt.',
        error: 'Beim Löschen der Reihe ist ein Fehler aufgetreten.'
      }
    }
  },
  autocompleteSearchField: {
    noData: {
      text: 'Keine Ergebnisse gefunden'
    }
  },
  externalLogin: {
    rule: {
      required: 'Bitte füllen Sie alle Pflichtfelder aus.',
      email: 'Die E-Mail-Adresse ist ungültig.',
      number: 'Dieses Feld muss eine Nummer enthalten.'
    },
    buttonLabel: {
      check: 'Überprüfen',
      proceed: 'Weiter',
      cancel: '@:system.general.cancel',
      ok: '@:system.general.ok',
      login: 'Anmelden',
      back: '@:system.general.back'
    },
    title: {
      additionalInfo: 'Weitere Informationen'
    },
    field: {
      lastName: 'Nachname',
      firstName: 'Vorname',
      company: 'Firma',
      country: 'Herkunftsland',
      visitReason: 'Besuchsgrund',
      email: 'E-Mail',
      id: 'Ausweis-Nr',
      street: 'Straße / Nr',
      zip: 'PLZ',
      location: 'Ort',
      phone: 'Telefon',
      birthdate: 'Geburtsdatum',
      training: 'Ausbildung',
      salutation: 'Anrede',
      gender: 'Geschlecht',
      titel: 'Titel',
      prefixName: 'Vorsatzwort',
      suffixName: 'Zusatzwort',
      city: 'Stadt',
      workingTime: 'Arbeitszeit',
      workingTasks: 'Beschäftigt als',
      citizenship: 'Staatsangehörigkeit',
      healthInsurance: 'Krankenkasse',
      healthInsuranceName: '@:externalLogin.field.healthInsurance: Name',
      healthInsuranceZip:
        '@:externalLogin.field.healthInsurance: @:externalLogin.field.zip',
      healthInsuranceCity:
        '@:externalLogin.field.healthInsurance: @:externalLogin.field.city',
      healthInsuranceStreet:
        '@:externalLogin.field.healthInsurance: @:externalLogin.field.street',
      birthplace: 'Geburtsort',
      letterSalutation: 'Briefanrede',
      adressAddition: 'Adresszusatz',
      hireDate: 'Einstellungsdatum',
      leavingDate: 'Austrittsdatum',
      fax: 'Fax',
      factory: 'Werk'
    },
    gender: {
      notSpecified: 'keine Angabe',
      female: 'Weiblich',
      male: 'Männlich',
      divers: 'Divers'
    },
    salutation: {
      notSpecified: '-',
      female: 'Frau',
      male: 'Herr'
    },
    factory: {
      notSpecified: 'kein spezielles Werk'
    },
    error: {
      maxLength: 'Maximale Länge wurde überschritten.',
      noVisitReason:
        'Es sind keine Besuchsgründe in dieser Sprache vorhanden. Bitte wählen Sie eine andere Sprache.'
    }
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Daten aktualisieren',
      gotoLogin: '@:setPasswordContent.buttonLabel.login'
    },
    text: {
      welcome: 'Schön, dass Sie sich zum ersten Mal in eplas anmelden.',
      employeeInfos:
        'Bitte füllen Sie die unten angezeigten Daten aus bzw. überprüfen Sie diese auf Richtigkeit.',
      password: 'Bitte wählen Sie ein Passwort',
      newUsername: 'Ihr neuer Anmeldename lautet',
      pleaseLogin: 'Bitte melden Sie sich mit diesem Namen neu an.'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'intern mobil',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    },
    error: {
      general: '@:setPasswordContent.error.general',
      passwordConfirmationValidation:
        '@:setPasswordContent.error.passwordConfirmationValidation',
      maximumLength: '@:setPasswordContent.error.maximumLength',
      minimumLength: '@:setPasswordContent.error.minimumLength',
      minimumDigits: '@:setPasswordContent.error.minimumDigits',
      minimumUpperCase: '@:setPasswordContent.error.minimumUpperCase',
      minimumLowerCase: '@:setPasswordContent.error.minimumLowerCase',
      minimumSpecialCharacters:
        '@:setPasswordContent.error.minimumSpecialCharacters',
      allowedSpecialCharacters:
        '@:setPasswordContent.error.allowedSpecialCharacters'
    }
  },
  error: {
    0x0000: {
      title: 'Fehler',
      text: 'Generischer Fehler: {message}',
      listItem: 'Eintrag {item}'
    },
    0x0001: {
      title: 'Fehler beim Validieren der Eingabe',
      text: 'Bitte geben Sie die Daten im entsprechenden Feld korrekt an.'
    },
    0x0002: {
      title: 'Wiederkehrender Fremdmitarbeiter',
      text: 'Wiederkehrende Fremdmitarbeiter sind nicht erlaubt.'
    },
    0x0003: {
      title: 'Server ist nicht erreichbar',
      text: 'Der Server ist im Moment nicht erreichbar. Bitte versuchen sie es später noch einmal. Details: {details}'
    },
    0x0004: {
      title: 'Kein API-Secret vorhanden',
      text: 'API-Secret zur Kommunikation mit dem Server ist nicht verfügbar.'
    },
    0x0005: {
      title: 'Keine Basis-URL vorhanden',
      text: 'Basis-URL des eplas-Frontends ist nicht verfügbar.'
    },
    0x0006: {
      title: 'Problem bei der Anmeldung',
      text: 'Ihre Anmeldung in eplas konnte nicht durchgeführt werden. Bitte wenden Sie sich persönlich an Ihren Ansprechpartner im Unternehmen.'
    },
    0x0007: {
      title: 'Ihr Token ist ungültig',
      text: 'Ihr Token ist leider ungültig oder abgelaufen. Fordern Sie über die "Passwort vergessen" Funktion einen neuen Link an und ändern Sie umgehend Ihr Passwort.'
    },
    0x000a: {
      title: 'Keine Informationen angegeben',
      text: 'Der Datensatz kann nicht mit ohne Informationen aktualisiert werden.'
    },
    0x000b: {
      title: 'Aktualisieren der Informationen fehlgeschlagen',
      text: 'Der Datensatz konnte mit den gegebenen Informationen nicht aktualisiert werden.'
    },
    0x000f: {
      title: 'Statement nicht gefunden',
      text: 'Angefordertes Statement "{statement}" konnte nicht gefunden werden.'
    }
  },
  statementCenter: {
    tabs: {
      imprint: 'Impressum',
      privacy: 'Datenschutzhinweise'
    }
  },
  languagePicker: {
    list: {
      title: 'Sprache ändern'
    }
  },
  userMenu: {
    info: {
      loggedInAs: 'Angemeldet als'
    },
    darkMode: {
      title: 'Dunkle Oberfläche',
      subtitle: 'Schaltet zwischen hell und dunkel um'
    },
    changePassword: {
      title: 'Passwort ändern',
      subtitle: 'Ändern Sie Ihr Passwort'
    },
    logout: {
      title: 'Abmelden',
      subtitle: 'Beendet die aktuelle Sitzung'
    },
    logoutAll: {
      title: 'Von allen Geräten abmelden',
      subtitle: 'Beendet alle Ihre aktiven Sitzungen'
    }
  },
  iconPicker: {
    dialog: {
      title: 'Icon wählen',
      reset: 'Auswahl aufheben',
      close: '@:system.general.close'
    },
    button: {
      selectText: 'Icon wählen'
    },
    search: {
      label: '@:system.general.search'
    },
    error: {
      noIconsFound: 'Keine Icons gefunden.'
    }
  }
}
