export { ConnectivityConfiguration } from './connectivity-configuration.interface'
export { AnalyzerData } from './analyzer-data.interface'
export { AnalyzerFunction } from './analyzer-function.type'
import { configuration } from './configuration'
import { ConnectivityDetector } from './connectivity-detector'

/**
 * Standard-Instanz des [[ConnectivityDetector]]. Sollte im Normalfall verwendet
 * werden.
 */
export const connectivityDetector = new ConnectivityDetector(configuration)
