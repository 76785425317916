
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * Hilfs-Interface, damit die Funktion callSlider von Typescript anerkannt wird.
 */
interface RefTab extends Vue {
  /**
   * Die Funktion berechnet die Breite des Sliders neu.
   */
  callSlider: Function
}

/**
 * <v-card> mit integrierten Tabs.
 */
@Component({})
export default class TabbedCard extends Vue {
  /**
   * Tab-Liste (mit Titeln).
   */
  @Prop({ type: Array, required: true })
  public tabs!: string[]

  /**
   * Grow-Prop der Vuetify-Tabs Komponente
   * Nutzt die gesamte Breite der Tabbar für die Tabs
   */
  @Prop({ type: Boolean, required: false, default: false })
  public grow!: boolean

  /**
   * showArrows-Prop der Vuetify-Tabs Komponente
   * Forciert bei vertikalem Scrollbedarf das Anzeigen von Pfeilbuttons
   */
  @Prop({ type: Boolean, required: false, default: false })
  public showArrows!: boolean

  /**
   * Benutzung des Sprachsystems. Die Namen der Tabs werden somit wie
   * Sprachstings verwendet.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public useLanguageData!: boolean

  /**
   * Wenn 'useLanguageData' aktiviert ist, wird dieser prop als Prefix an den
   * Sprachstring (Name des Tabs) gehängt
   */
  @Prop({ type: String, required: false, default: '' })
  public languageDataPrefix!: string

  /**
   * Legt die Hintergrundfarbe der Tabs fest
   */
  @Prop({ type: String, required: false, default: '' })
  public backgroundColor!: string

  /**
   * Key des standardmäßig aktiven Tabs
   */
  @Prop({ type: String, required: false, default: '' })
  public defaultTab!: string

  /**
   * Legt fest ob Tabs und Inhalte über eine Key verknüpft sind
   */
  @Prop({ type: Boolean, required: false, default: false })
  public useHref!: boolean

  /**
   * Aktiver Tab.
   */
  private localTab: number | string = ''

  /**
   * Der Wert des Tabs
   */
  @Prop({ type: [String, Number], required: false, default: undefined })
  public value?: string | number

  /**
   * Wird genutzt, um rerendern der Komponente zu triggern
   */
  @Prop({ type: Number, required: true })
  public rerenderKey = 0

  /**
   * Gibt den aktuellen Tab zurück.
   *
   * @returns den aktuellen Tab.
   */
  public get tab(): number | string {
    if (this.value !== undefined) {
      return this.value
    }

    return this.localTab
  }

  /**
   * Setzt den aktuellen Tab.
   *
   * @param value - der neu zu setzenden Tab.
   */
  public set tab(value: number | string) {
    if (this.value === undefined) {
      this.localTab = value
    } else {
      this.$emit('input', value)
    }
  }

  /**
   * Legt den standardmäßig ausgewählten Tab fest
   */
  public created(): void {
    if (this.defaultTab !== '') {
      this.tab = this.defaultTab
    }
  }

  /**
   * Erzwingt rerendern der Komponente
   */
  public forceRerender(): void {
    this.rerenderKey += 1
  }

  /**
   * Eine Funktion, die ein Event emittet, da sich der Tab geändert hat
   */
  @Watch('tab')
  public changedTab(): void {
    this.$emit('changedTab', this.tab)
  }

  /**
   * Wenn sich der Inhalt eines Tabs ändert.
   */
  @Watch('$i18n.locale')
  public changedLanguage(): void {
    // Sorgt dafür, dass die Breite des Sliders unterhalb des Tabs bei einer
    // Änderung der Sprache (und somit des Inhalts) in der korrekten Länge
    // dargestellt wird.
    ;(this.$refs.tabs as RefTab).callSlider()
    // Sorgt dafür, dass beim Sprachwechsel die Komponente neu gerenderd wird,
    // damit die Navigationspfeile bei zu langen Sprachstrings angezeigt werden
    this.forceRerender()
  }
}
