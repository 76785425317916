
import { OverlayMixin } from '@/mixins/overlay.mixin'
import { Component, Mixins } from 'vue-property-decorator'

/**
 * Drittanbieterbereich des Infobereichs.
 */
@Component({})
export default class InfoCenterThirdParty extends Mixins(OverlayMixin) {
  /**
   * Array mit Informationen zu Drittanbietersoftware.
   */
  public thirdParty = {
    title: 'infoCenterThirdParty.title',
    subtitle: 'infoCenterThirdParty.subtitle',
    href: window.location.origin + '/index.php/eplas/index/view/opensource'
  }

  /**
   * Ruft den Link zur Liste der Drittanbietersoftware auf
   */
  public goToThirdPartyList(): void {
    this.$emit('closeDialog')
    this.toggleLoadingAnimationOn()
    window.location.href = this.thirdParty.href
  }
}
