import { transformAndValidate } from 'class-transformer-validator'
import { Client } from './client'

/** Einstellungen für [[transformAndValidate]]. */
const TRANSFORM_VALIDATE_OPTIONS = {
  validator: {
    whitelist: true,
    forbidNonWhitelisted: true
  }
}

/**
 * Key, der für das Speichern der Konfigurationsdaten im localStorage verwendet
 * wird.
 */
const LOCALSTORAGE_KEY = 'clientConfig'

/**
 * Klasse zum Abrufen der Client-Konfiguration.
 */
class Config {
  /**
   * Konfigurationsdaten.
   */
  private _config?: Client

  /**
   * Transformiert und validiert Client-Konfigurationsdaten. Bei erfolgreicher
   * Validierung wird die Konfiguration in [[this._config]] gespeichert.
   *
   * @param data - Konfigurationsdaten als JSON-String oder als Objekt.
   */
  private async _validate(data: string | object): Promise<void> {
    let result: Client | Client[]

    if (typeof data === 'string') {
      result = await transformAndValidate(
        Client,
        data,
        TRANSFORM_VALIDATE_OPTIONS
      )
    } else {
      result = await transformAndValidate(
        Client,
        data,
        TRANSFORM_VALIDATE_OPTIONS
      )
    }

    if (result instanceof Array) {
      throw new Error(
        'JSON data contains an array where an object was expected.'
      )
    }

    this._config = result as Client

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(this._config))
  }

  /**
   * Lädt für die Entwicklungsumgebung generierte Konfigurationsdaten.
   *
   * @returns Leere Promise.
   */
  private _loadDevelopment(): Promise<void> {
    const url = new URL(window.location.origin)
    const https = url.protocol === 'https:'

    url.port = https ? '3031' : '3030'
    url.pathname = '/api'

    return this._validate({
      api: {
        url: url.toString()
      }
    })
  }

  /**
   * Lädt Konfigurationsdaten vom Server (`/config.json`).
   *
   * @returns Leere Promise.
   */
  private async _loadRemote(): Promise<void> {
    const result = await fetch('/config.json')
    const json = await result.text()

    return this._validate(json)
  }

  /**
   * Lädt zwischengespeicherte Konfigurationsdaten aus der `localStorage`.
   *
   * @returns Leere Promise.
   */
  private _loadLocal(): Promise<void> {
    const json = localStorage.getItem(LOCALSTORAGE_KEY)

    if (!json) {
      throw new Error('No local configuration data found.')
    }

    return this._validate(json)
  }

  /**
   * Zugriff auf die Client-Konfiguration. Die Konfigurationsdaten werden
   * geladen, falls diese noch nicht geladen sind.
   *
   * @returns Client-Konfiguration.
   */
  public async client(): Promise<Client> {
    if (!this._config) {
      if (process.env.NODE_ENV === 'development') {
        await this._loadDevelopment()
      } else {
        try {
          await this._loadRemote()
        } catch (ex) {
          await this._loadLocal()
        }
      }
    }

    if (!this._config) {
      // Dieser Code-Zweig sollte nie ausgeführt werden, da die obigen `_load`-
      // Funktionen selbstständig Fehler werfen.
      throw new Error('All attempts to load the client configuration failed.')
    }

    return this._config
  }
}

// Reihenfolge:
// 1. Dev-Modus? Dann Konfiguration generieren.
// 2a. fetch auf /config.json wenn online
// 2b. localStorage Config Cache wenn offline

export const config = new Config()
