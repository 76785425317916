import { Module } from 'vuex'
import { UIState } from './ui-state.interface'
import { RootState } from '../root-state.interface'
import { state } from './state'
import Getters from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export const ui: Module<UIState, RootState> = {
  namespaced: true,
  state,
  getters: Getters,
  mutations,
  actions
}
