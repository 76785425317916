import { DashboardTranslationsWithID } from '@/interfaces/DashboardTranslationsWithID.interface'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<DashboardTranslationsWithID[]> = {
  /**
   * Aktualisiert die Liste der Übersetzungen der Dashboardnamen.
   *
   * @param state - State im Store.
   * @param newDashboard - Die Übersetzungsdaten des Dashboards, welche hinzugefügt
   * oder aktualisiert werden sollen.
   */
  addDashboard(
    state: DashboardTranslationsWithID[],
    newDashboard: DashboardTranslationsWithID
  ) {
    let index = -1
    state.forEach(dashboard => {
      if (dashboard.id === newDashboard.id) {
        index = state.indexOf(dashboard)
      }
    })
    if (index === -1) {
      state.push(newDashboard)
    } else {
      state.splice(index, 1, newDashboard)
    }
  }
}
