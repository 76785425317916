export const tr = {
  externalLogin: {
    rule: {
      required: 'Lütfen, tüm zorunlu alanları doldurunuz.',
      email: 'E-posta adresi geçersiz',
      number: 'Bu alan bir numara içermelidir.'
    },
    buttonLabel: {
      check: 'Denetleme',
      proceed: 'Devam',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Giriş yap',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Daha fazla bilgi'
    },
    field: {
      lastName: 'Soyadı',
      firstName: 'Adı',
      company: 'Şirket',
      country: 'Menşei ülke:',
      visitReason: 'Ziyaret nedeni',
      email: 'E-posta',
      id: 'Kimlik No.',
      street: 'Cad./Sok. / No.',
      zip: 'Posta Kodu',
      location: 'Yer',
      phone: 'Telefon',
      birthdate: 'Doğum tarihi',
      training: 'Eğitim',
      salutation: 'Hitap',
      gender: 'Cinsiyet',
      titel: 'Başlık',
      prefixName: 'İsim ön eki',
      suffixName: 'İsim arka eki',
      city: 'Şehir',
      workingTime: 'Mesai saati',
      workingTasks: 'İstihdam türü',
      citizenship: 'Tabiiyeti',
      healthInsurance: 'Sağlık sigortası',
      healthInsuranceName: 'Sağlık sigortasının adı',
      healthInsuranceZip: 'Sağlık sigortası: Posta kodu',
      healthInsuranceCity: 'Sağlık sigortası: Şehir',
      healthInsuranceStreet: 'Sağlık sigortası: Cadde',
      birthplace: 'Doğum yeri',
      letterSalutation: 'Mektup hitabı',
      adressAddition: 'Adres ilavesi',
      hireDate: 'Oluşturma tarihi',
      leavingDate: 'Ayrılma tarihi',
      fax: 'Faks'
    },
    gender: {
      notSpecified: 'Bilgi yok',
      femail: 'Kadın',
      male: 'Erkek',
      divers: 'Diğer'
    },
    salutation: {
      notSpecified: '-',
      female: 'Bayan',
      male: 'Bay'
    },
    error: {
      maxLength: 'Maksimum uzunluk aşıldı.',
      noVisitReason:
        'Bu dilde ziyaret nedeni mevcut değil. Lütfen başka bir dil seçin.'
    }
  },
  error: {
    0x0000: {
      title: 'Hata',
      text: 'Genel hata: {message}'
    },
    0x0001: {
      title: 'Giriş doğrulanırken hata',
      text: 'Lütfen verileri ilgili alana doğru bir şekilde girin.'
    },
    0x0002: {
      title: 'Geri dönen yabancı eleman',
      text: 'Geri dönen yabancı elemanlara izin verilmez.'
    },
    0x0003: {
      title: 'Sunucuya erişilemiyor',
      text: 'Sunucuya şu an erişilemiyor. Lütfen daha sonra tekrar deneyin. Detaylar: {details}'
    },
    0x0004: {
      title: 'API-Secret mevcut değil',
      text: 'Sunucuyla iletişim için API-Secret mevcut değil.'
    },
    0x0005: {
      title: 'Temel URL mevcut değil',
      text: `eplas-Frontend temel URL'si mevcut değil`
    },
    0x0006: {
      title: 'Girş yaparken sorun',
      text: `eplas'a giriş işleminiz gerçekleştirilemedi. Lütfen şirketteki irtibat kişisi ile şahsen iletişime geçin.`
    },
    0x0007: {
      title: `Token'iniz geçersiz`,
      text: `Token'iniz geçersiz veya süresi doldu.`
    }
  },
  loginMask: {
    loginInput: {
      password: 'Parola',
      username: 'Kullanıcı adı'
    },
    welcomeMessage: {
      text: `eplas'a hoş geldiniz!`
    },
    forwardMessage: {
      title: 'Giriş başarılı!',
      text: 'Hemen yönlendirileceksiniz…'
    },
    buttonLabel: {
      login: 'Giriş yap'
    },
    loginMethod: {
      local: 'Giriş yap',
      saml: 'Şirket girişi',
      ldap: 'Windows girişi',
      external: 'Yabancı eleman',
      pin: 'PIN girişi'
    },
    forgotPassword: {
      button: 'Şifrenizi mi unuttunuz?'
    },
    error: {
      noOfflineLogin: 'Çevrim dışı modda giriş yapmak mümkün değildir!',
      username: 'Giriş başarısız. Kullanıcı adınızı ve şifrenizi kontrol edin.',
      usernameValidation: 'Kullanıcı adı zorunlu.',
      passwordValidation: 'Şifre zorunlu.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Şifreyi tekrarlayın',
      setPasswordConfirm: 'Şifreyi sıfırlayın',
      setPasswordTitle: 'İlk giriş: Lütfen bunun için şifrenizi değiştirin',
      success: 'Şifre başarıyla değiştirildi'
    },
    passwordRules: {
      title: 'Şifre kuralları',
      maximumLength: 'Şifre çok uzun.',
      minimumLength: 'Şifre çok kısa.',
      minimumDigits: 'Şifre yeterince rakam içermiyor.',
      minimumUpperCase: 'Şifre yeterince büyük harf içermiyor.',
      minimumLowerCase: 'Şifre yeterince küçük harf içermiyor.',
      minimumSpecialCharacters: 'Şifre yeterince özel karakter içermiyor.',
      allowedSpecialCharacters:
        'Şifre kabul edilen özel karakterler içermiyor.',
      general: 'Şifreyi değiştirirken bir hata meydana geldi.',
      passwordConfirmationValidation: 'Parolalar birbirine uymamaktadırlar.'
    },
    buttonLabel: {
      login: 'Giriş sayfasına git'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Şifrenizi mi unuttunuz?',
      email: 'Kullanıcınızın e-posta adresi',
      confirm: 'E-posta gönderin',
      error: 'E-posta adresi geçersiz.',
      cancel: 'İptal',
      hint: '',
      emailSent: 'Email gönderilmiştir.'
    }
  },
  passwordReset: {
    title: 'Lütfen şifrenizi değiştirin'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Verileri güncelleyin'
    },
    text: {
      welcome: `İlk defa eplas'a giriş yapıyor olmanız güzel!`,
      employeeInfos:
        'Lütfen aşağıda gösterilen verileri doldurun veya bunların doğruluğunu kontrol edin.',
      password: 'Lütfen bir şifre seçin'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'Dahili mobil',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Şu an çevrimiçisiniz.',
        offline: 'Şu an çevrim dışısınız.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Yazdır',
      search: 'Ara',
      close: 'Kapat',
      create: 'Oluştur',
      edit: 'Düzenle',
      copy: 'Kopyala',
      back: 'Geri',
      remove: 'Sil',
      abort: 'İptal',
      finished: 'Bitti',
      ok: 'Ok',
      cancel: 'İptal'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
