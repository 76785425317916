import { HookContext } from '@feathersjs/feathers'
import { NotImplemented } from '@feathersjs/errors'

/**
 * Schmeißt eine NotImplemented-Fehler wenn der Hook als 'before'-Hook verwendet
 * wird. Ansonsten wird garnichts geändert. Ist wirklich nur dafür gedacht,
 * eine bestimmte Service-Methode zu deaktivieren.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, ohne Änderungen!
 */
export function notImplemented(context: HookContext): HookContext {
  if (context.type === 'before') {
    throw new NotImplemented(
      `'${context.method}' method isn't implemented - service: ${context.path}`
    )
  }

  return context
}
