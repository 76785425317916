import { HookContext } from '@feathersjs/feathers'
import { OfflineEntry } from '../offline-entry.interface'
import { CustomPaginated } from '../../interfaces/CustomPaginated.interface'

/**
 * Dieser Feathers-Hook stellt eine Standardvariante für das Speichern und
 * Auslesen von Datensätzen aus dem Offline-Speicher dar. Die Datensätze werden
 * zusammen mit ihren Parametern gespeichert. Über diese Paramater werden diese
 * beim Auslesen auch wiedergefunden. Bei der Rückgabe werden nur die
 * Datensätze mit zurückgegeben. Hierbei wird nur das Property `id` mit hinzugefügt.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, mit angepassten Datensatz bzw. Ergebnis
 */
export async function defaultSaving(
  context: HookContext
): Promise<HookContext> {
  if (context.type === 'before') {
    const newQuery: { [key: string]: unknown } = {}
    const method = context.method
    const contextData = context.data
      ? JSON.parse(JSON.stringify(context.data))
      : {}
    let contextEntryID = context.id

    if (context.params && context.params.query) {
      const contextQuery = context.params.query

      for (const key in contextQuery) {
        newQuery[key.substring(0, 1) === '$' ? key.substring(1) : key] =
          contextQuery[key]
      }

      context.params.query = newQuery
    }

    if (method === 'update' || method === 'create') {
      if (!contextEntryID) {
        const entryID: number | null = await context.service
          .find({ query: newQuery })
          .then(
            (result: CustomPaginated<object> | OfflineEntry): number => {
              let entryID = 0

              if ('total' in result && 'data' in result) {
                entryID =
                  result.total !== 0
                    ? (result.data as OfflineEntry[])[0].id || 0
                    : 0
              } else if ('id' in result) {
                entryID = result ? result.id || 0 : 0
              }

              return entryID
            },
            (): number => 0
          )

        if (entryID > 0) {
          contextEntryID = entryID

          if (method === 'create') {
            context.result = await context.service.update(entryID, contextData)
          }
        } else if (contextData.id) {
          contextEntryID = contextData.id
        }
      }

      if (!context.result) {
        context.data = {
          id: contextEntryID || 0,
          ...newQuery,
          _data: contextData
        }
      }
    }
  } else if (context.type === 'after') {
    if (
      context.method !== 'find' ||
      (context.result && !Array.isArray(context.result.data))
    ) {
      if (context.result && context.result._data) {
        context.result = {
          id: context.result.id,
          ...context.result._data
        }
      }
    } else if (context.result.total !== 0) {
      const result = []
      for (const entry of context.result.data) {
        if (entry._data) {
          result.push({
            id: entry.id,
            ...entry._data
          })
        } else {
          result.push(entry)
        }
      }

      context.result.data = result
    }
  }

  return context
}
