import { ConnectivityConfiguration } from './connectivity-configuration.interface'

/**
 * Verwendete Konfiguration für die Standardinstanz des
 * [[ConnectivityDetector]].
 */
export const configuration: ConnectivityConfiguration = {
  enableHeartbeat: true,
  enableNavigatorOnline: true,
  interval: 5000,
  onlineThreshold: 11,
  offlineThreshold: 3,
  upperLimit: 19,
  lowerLimit: -15,
  navigatorOnlineModificator: 3,
  navigatorOfflineModificator: -5,
  heartbeatLowModificator: 10,
  heartbeatHighModificator: 5,
  heartbeatFailModificator: -17,
  heartbeatHighThreshold: 2000,
  historyLength: 20,
  writeToVuex: true
}
