import { MutationTree } from 'vuex'
import { UserState } from './user-state.interface'
import { User } from './user.interface'

export const mutations: MutationTree<UserState> = {
  /**
   * Setzt das Nutzerobjekt.
   *
   * @param state - State des UI-Moduls.
   * @param user - Benutzer-Objekt.
   */
  updateUser(state: UserState, user: User): void {
    state.id = user.id
    state.personnelNumber = user.personnelNumber
    state.systemLanguage = user.systemLanguage
    state.username = user.username
    state.permissions = user.permissions
    state.roles = user.roles
    state.adminFlag = user.adminFlag
  }
}
