
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { getTextColor } from './../../helpers/color-readability.helper'

const UserStore = namespace('user')

/**
 * Zeigt die Namenskürzel des angemeldeten Benutzers in einem `<v-avatar>` an.
 */
@Component({})
export default class UserAvatar extends Vue {
  /**
   * Benutzername des angemeldeten Benutzers.
   */
  @UserStore.Getter('username')
  public username!: string

  /**
   * Initialen des angemeldeten Benutzers.
   */
  @UserStore.Getter('initials')
  public initials!: string

  /**
   * Hintergrundfarbe für das Avatar. Wird aus dem Benutzernamen errechnet.
   *
   * @returns HEX-Farbcode.
   */
  public get backgroundColor(): string {
    let hash = 0

    for (let i = 0; i < this.username.length; ++i) {
      hash = this.username.charCodeAt(i) + ((hash << 5) - hash)
    }

    const c = (hash & 0x00ffffff).toString(16).toUpperCase()

    return '#' + '00000'.substring(0, 6 - c.length) + c
  }

  /**
   * Textfarbe für das Avatar. Wird aus der Hintergrundfarbe errechnet.
   *
   * @returns Vuetify-Text-Klasse.
   */
  public get textColor(): string {
    return getTextColor(this.backgroundColor)
  }
}
