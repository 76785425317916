import { HookContext } from '@feathersjs/feathers'
import store from '@/store'
import { MenuBadgeItem } from '@/store/ui/menu-badge.interface'

/**
 * Verwaltet das Login des Benutzers, damit dieser auch im Offline-Modus als
 * eingeloggt erkamnnt werden kann.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, mit angepassten Query-Parmater
 */
export function calculateOfflineAudit(context: HookContext): HookContext {
  if (context.path === 'audits/offline' && context.type === 'after') {
    const UI_MENU_BADGES = store.getters['ui/menuBadges']
    const itemKey = 'audit_list_offline'
    let currentValue = 0

    if (UI_MENU_BADGES && UI_MENU_BADGES.hasOwnProperty(itemKey)) {
      if (typeof UI_MENU_BADGES[itemKey] === 'number') {
        currentValue = UI_MENU_BADGES[itemKey]
      }
    }

    const oldValue = currentValue

    if (context.method === 'remove') {
      if (Array.isArray(context.result)) {
        currentValue -= context.result.length
      } else {
        --currentValue
      }
    } else if (context.method === 'create') {
      if (Array.isArray(context.result)) {
        currentValue += context.result.length
      } else {
        ++currentValue
      }
    } else if (context.method === 'find') {
      const result = context.result

      if (result && 'total' in result && typeof result.total === 'number') {
        currentValue = result.total
      }
    }

    if (currentValue <= 0) {
      currentValue = 0
    } else if (currentValue >= Number.MAX_SAFE_INTEGER) {
      currentValue = Number.MAX_SAFE_INTEGER
    }

    if (oldValue !== currentValue) {
      const badgeValue: MenuBadgeItem = { key: itemKey }

      if (currentValue > 0) {
        badgeValue.value = currentValue
      }

      store.commit('ui/setMenuBadge', badgeValue)
    }
  }

  return context
}
