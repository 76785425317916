import { getApp } from '@/helpers/feathers'

/**
 * Überprüft, ob der Benutzer eingeloggt ist
 *
 * @returns true oder false
 */
export async function isUserLoggedIn() {
  try {
    await (await getApp()).reAuthenticate()
    return true
  } catch (error) {
    return false
  }
}
