/**
 * Alle Methoden von Feathers für die das opportunistisches Aktualisieren
 * verwendet werden können.
 */
export enum Methods {
  /**
   * Opportunistisches Aktualisieren für die Find-Methode erlauben.
   */
  Find = 'find',

  /**
   * Opportunistisches Aktualisieren für die Get-Methode erlauben.
   */
  Get = 'get',

  /**
   * Opportunistisches Aktualisieren für die Create-Methode erlauben.
   */
  Create = 'create',

  /**
   * Opportunistisches Aktualisieren für die Update-Methode erlauben.
   */
  Update = 'update',

  /**
   * Opportunistisches Aktualisieren für die Patch-Methode erlauben.
   */
  Patch = 'patch',

  /**
   * Opportunistisches Aktualisieren für die Remove-Methode erlauben.
   */
  Remove = 'remove'
}
