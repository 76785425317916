import { HookContext } from '@feathersjs/feathers'

/**
 * Konvertiert den `$offset`-Query-Parmater des Online-Services in den passenden
 * `$skip`-Query-Parmater des Offline-Services, wenn dieser gesetzt wurde.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, mit angepassten Query-Parmater
 */
export function fixParams(context: HookContext): HookContext {
  if (
    context.type === 'before' &&
    context.method === 'find' &&
    context.params &&
    context.params.query &&
    typeof context.params.query.$offset !== 'undefined'
  ) {
    context.params.query.$skip = parseInt(context.params.query.$offset, 10)
    delete context.params.query.$offset

    if (!context.params.query.$skip || context.params.query.$skip <= 0) {
      context.params.query.$skip = 0
    }
  }

  return context
}
