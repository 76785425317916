import { Component, Vue } from 'vue-property-decorator'

/**
 * Mixin für Umgebungsvariablen wie NODE_ENV.
 */
@Component({})
export class EnvironmentMixin extends Vue {
  /**
   * Gibt an, ob sich die Umgebung im Entwicklungsmodus befindet.
   *
   * @returns Gibt true zurück, wenn sich die Umgebung im Entwicklungsmodus
   * befindet, sonst false.
   */
  public get isDevelopment(): boolean {
    return process.env.NODE_ENV === 'development'
  }
}
