
import { Component, Mixins } from 'vue-property-decorator'
import TabbedCard from '@/components/TabbedCard/TabbedCard.vue'
import HelpCenterManual from '@/components/HelpCenterManual/HelpCenterManual.vue'
import HelpCenterSupport from '@/components/HelpCenterSupport/HelpCenterSupport.vue'
import { EnvironmentMixin } from '@/mixins/environment.mixin'

/**
 * Hilfebereich der Anwendung mit Verlinkung zu den eplas-Downloads und
 * Kontaktaufnahme zum Support.
 */
@Component({
  components: {
    TabbedCard,
    HelpCenterManual,
    HelpCenterSupport
  }
})
export default class HelpCenter extends Mixins(EnvironmentMixin) {
  /**
   * Tabs für Produktivbetrieb.
   */
  public productionTabs: string[] = [
    'helpCenter.tabs.support',
    'helpCenter.tabs.manual'
  ]

  /**
   * Tabs für Entwicklungsbetrieb.
   */
  public developmentTabs: string[] = []

  /**
   * Berechnete Liste aus [[this.productionTabs]] und [[this.developmentTabs]],
   * abhängig von [[this.isDevelopment]].
   *
   * @returns Liste der Tabs.
   */
  public get tabs(): string[] {
    return [
      ...this.productionTabs,
      ...(this.isDevelopment ? this.developmentTabs : [])
    ]
  }
}
