import { HookContext } from '@feathersjs/feathers'
import { CellValue } from '../../../../services/obsolete-checklist/cell-values.schema'

/**
 * Wandelt die `result`-Eigenschaft von der Find-Methode des Services in das
 * erwartete Format 'Cell-ID => Cell-Data'. Sie kann nur im `after`-Hook und
 * `error`-Hook verwendet werden!
 *
 * @param context - Kompletter Context des Hooks
 * @returns Komplettter Kontext des Hooks
 */
export function convertResult(context: HookContext): HookContext {
  const result: { [k: string]: CellValue } = {}

  try {
    if (context && context.result && Array.isArray(context.result.data)) {
      result.total = context.result.total
      result.limit = context.result.limit

      context.result.data.forEach((entry: CellValue): void => {
        result[entry.checklist_cell_id] = entry
      })
    }
  } finally {
    context.result = result
  }

  return context
}
