
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import HelpCenter from '@/components/HelpCenter/HelpCenter.vue'
import InfoCenter from '@/components/InfoCenter/InfoCenter.vue'
import StatementSection from '@/components/StatementSection/StatementSection.vue'

/**
 * Bereich mit Versionsnummer und Buttons für Informationen und Hilfe.
 */
@Component({
  components: {
    HelpCenter,
    InfoCenter,
    StatementSection
  }
})
export default class AboutSection extends Vue {
  /**
   * Programmversion.
   */
  @State('version')
  public version!: string

  /**
   * Zeigt an, ob der Dialog geöffnet ist
   */
  public dialog = false

  /**
   * Lädt die Seite neu
   */
  public forceReload(): void {
    window.location.reload()
  }
}
