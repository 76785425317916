export const hu = {
  externalLogin: {
    rule: {
      required: 'Kérjük töltse ki az összes kötelező mezőt.',
      email: 'Érvénytelen e-mail-cím.',
      number: 'A mezőnek egy számot kell tartalmaznia.'
    },
    buttonLabel: {
      check: 'Ellenőrzés',
      proceed: 'Tovább',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Bejelentkezés',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'További információk'
    },
    field: {
      lastName: 'Vezetéknév',
      firstName: 'Utónév',
      company: 'Vállalkozás',
      country: 'Származási ország:',
      visitReason: 'Látogatás oka',
      email: 'E-mail',
      id: 'Igazolványszám',
      street: 'Utca / házszám',
      zip: 'Irányítószám',
      location: 'Település',
      phone: 'Telefon',
      birthdate: 'Születési dátum',
      training: 'Képzés',
      salutation: 'Megszólítás',
      gender: 'Nem',
      titel: 'Cím',
      prefixName: 'Előtag',
      suffixName: 'Előnév',
      city: 'Város',
      workingTime: 'Munkaidő',
      workingTasks: 'Foglalkozás',
      citizenship: 'Állampolgárság',
      healthInsurance: 'Egészségbiztosítási pénztár',
      healthInsuranceName: 'Egészségbiztosítási pénztár neve',
      healthInsuranceZip: 'Egészségbiztosítási pénztár: Postai irányítószám',
      healthInsuranceCity: 'Egészségbiztosítási pénztár: Város',
      healthInsuranceStreet: 'Egészségbiztosítási pénztár: Utca',
      birthplace: 'Születési hely',
      letterSalutation: 'Megszólítás levélben',
      adressAddition: 'Címkiegészítés',
      hireDate: 'Beállítási dátum',
      leavingDate: 'Kilépés dátuma',
      fax: 'Fax'
    },
    gender: {
      notSpecified: 'Nincs adat',
      femail: 'nő',
      male: 'férfi',
      divers: 'divers'
    },
    salutation: {
      notSpecified: '-',
      female: '-',
      male: '-'
    },
    error: {
      maxLength: 'Túllépte a maximális hosszúságot.',
      noVisitReason:
        'Ezen a nyelven nincsenek elérhető látogatási okok. Válasszon ki egy másik nyelvet.'
    }
  },
  error: {
    0x0000: {
      title: 'Hiba',
      text: 'Általános hiba: {message}'
    },
    0x0001: {
      title: 'Hiba a bevitel validálásakor',
      text: 'Írja be az adatokat a megfelelő mezőbe.'
    },
    0x0002: {
      title: 'Visszatérő külső munkatárs',
      text: 'A visszatérő külső munkatársak nem engedélyezettek.'
    },
    0x0003: {
      title: 'A kiszolgáló nem érhető el.',
      text: 'A kiszolgáló jelenleg nem érhető el. Később próbálja meg újból. Részletek: {details}'
    },
    0x0004: {
      title: 'Nem érhető el API titkos kód',
      text: 'A kiszolgálóval való kommunikációhoz szükséges API titkos kód nem áll rendelkezésre.'
    },
    0x0005: {
      title: 'Nem érhető el alap URL',
      text: 'Az eplas Frontend alap URL-je nem áll rendelkezésre.'
    },
    0x0006: {
      title: 'Hiba a bejelentkezés során',
      text: 'Az eplasba történő bejelentkezés sikertelen volt. Forduljon személyesen a vállalkozásnál lévő kapcsolattartójához.'
    },
    0x0007: {
      title: 'Érvénytelen token',
      text: 'A tokenje érvénytelen'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Jelszó',
      username: 'Felhasználónév'
    },
    welcomeMessage: {
      text: 'Az eplas üdvözli Önt!'
    },
    forwardMessage: {
      title: 'Sikeres bejelentkezés!',
      text: 'Egy pillanat, és továbbítjuk...'
    },
    buttonLabel: {
      login: 'Bejelentkezés'
    },
    loginMethod: {
      local: 'Bejelentkezés',
      saml: 'Vállalkozás bejelentkezése',
      ldap: 'Windows-bejelentkezés',
      external: 'Külső munkatárs',
      pin: 'PIN megadása'
    },
    forgotPassword: {
      button: 'Elfelejtette a jelszavát?'
    },
    error: {
      noOfflineLogin: 'Offline üzemmódban nem lehet bejelentkezni!',
      username:
        'A bejelentkezés sikertelen volt. Ellenőrizze a felhasználónevét és a jelszavát.',
      usernameValidation: 'A felhasználónév megadása kötelező.',
      passwordValidation: 'A jelszó megadása kötelező.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Jelszó újból',
      setPasswordConfirm: 'Jelszó beállítása',
      setPasswordTitle: 'Első bejelentkezés: változtassa meg a jelszavát',
      success: 'A jelszó módosítása sikeres volt'
    },
    passwordRules: {
      title: 'Jelszóval kapcsolatos követelmények',
      maximumLength: 'A jelszó túl hosszú.',
      minimumLength: 'A jelszó túl rövid.',
      minimumDigits: 'A jelszó nem tartalmaz elég számot.',
      minimumUpperCase: 'A jelszó nem tartalmaz elég nagybetűt.',
      minimumLowerCase: 'A jelszó nem tartalmaz elég kisbetűt.',
      minimumSpecialCharacters:
        'A jelszó nem tartalmaz különleges karaktereket.',
      allowedSpecialCharacters:
        'A jelszó nem engedélyezett különleges karaktereket tartalmaz.',
      general: 'A jelszó módosításakor hiba lépett fel.',
      passwordConfirmationValidation: 'A két jelszó nem egyezik meg.'
    },
    buttonLabel: {
      login: 'Ugrás a bejelentkezési oldalra'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Elfelejtette a jelszavát?',
      email: 'A felhasználó e-mail-címe',
      confirm: 'E-mail küldése',
      error: 'Érvénytelen e-mail-cím.',
      cancel: 'Megszakítás',
      hint: '',
      emailSent: 'Az e-mail elküldése sikeresen megtörtént.'
    }
  },
  passwordReset: {
    title: 'Változtassa meg a jelszavát'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Adatok frissítése'
    },
    text: {
      welcome: 'Ez az első bejelentkezése az eplas rendszerébe!',
      employeeInfos:
        'Töltse ki az alábbi adatokat, illetve ellenőrizze ezek helyességét.',
      password: 'Válasszon ki egy jelszót.'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'belső mobil',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Most online van.',
        offline: 'Most offline van.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Nyomtatás',
      search: 'Keresés',
      close: 'Bezárás',
      create: 'Létrehozás',
      edit: 'Szerkesztés',
      copy: 'Másolás',
      back: 'Vissza',
      remove: 'Eltávolítás',
      abort: 'Megszakítás',
      finished: 'Kész',
      ok: 'Ok',
      cancel: 'Megszakítás'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
