import { GetterTree } from 'vuex'
import { Permission } from '@nxt/permissions'
import { UserState } from './user-state.interface'
import { RootState } from '../root-state.interface'
import { Role } from './role.interface'

export const getters: GetterTree<UserState, RootState> = {
  /**
   * Benutzername des angemeldeten Benutzers wenn bekannt, sonst "?".
   *
   * @param state - [[UserState]].
   * @returns Benutzername oder "?".
   */
  username(state: UserState): string {
    return state.username || '?'
  },

  /**
   * Berechtigungen des angemeldeten Benutzers.
   *
   * @param state - [[UserState]].
   * @returns Berechtigungs-Array.
   */
  permissions(state: UserState): Permission[] {
    return state.permissions
  },

  /**
   * Rollen des angemeldeten Benutzers.
   *
   * @param state - [[UserState]].
   * @returns Rollen-Array.
   */
  roles(state: UserState): Role[] {
    return state.roles
  },

  /**
   * ID des Benutzers, sonst 0.
   *
   * @param state - [[UserState]].
   * @returns ID oder 0.
   */
  id(state: UserState): number {
    return state.id && state.id > 0 ? state.id : 0
  },

  /**
   * Generiert die Initialien eines Benutzers (Anfangsbuchstabe von Vor- und
   * Nachname).
   *
   * @param state - [[UserState]].
   * @returns Initialen (1 oder 2 Buchstaben).
   */
  initials(state: UserState): string {
    if (!state.username) {
      return '?'
    }

    return state.username
      .split(' ')
      .filter((value, index, arr) => {
        return index === 0 || index === arr.length - 1
      })
      .map(part => part[0])
      .join('')
      .toUpperCase()
  },

  /**
   * Gibt zurück, ob das eplas10L-Admin-Flag gesetzt ist.
   *
   * @param state - [[UserState]].
   * @returns boolischen Wert.
   */
  adminFlag(state: UserState): boolean {
    return state.adminFlag
  }
}
