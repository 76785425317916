
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Schnellzugriffseintrag.
 */
interface QuickAccessItem {
  /**
   * Titel/Bezeichnung der Aktion.
   */
  title: string

  /**
   * Icon, das angezeigt werden soll.
   */
  icon: string
}

/**
 * Schnellzugriffmenü.
 */
@Component({})
export default class QuickAccess extends Vue {
  /**
   * Gibt an, ob am Ende der Liste ein "Schließen"-Eintrag angezeigt werden
   * soll, der beim Klick ein "close"-Event auslöst.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public closeButton!: boolean

  /**
   * Schnellzugriff-Einträge.
   */
  public quickAccess: QuickAccessItem[] = [
    {
      title: 'quickAccess.itemTitle.email',
      icon: 'mdi-email'
    },
    {
      title: 'quickAccess.itemTitle.report',
      icon: 'mdi-file-document'
    },
    {
      title: 'quickAccess.itemTitle.accidentReport',
      icon: 'mdi-ambulance'
    },
    {
      title: 'system.general.print',
      icon: 'mdi-printer'
    }
  ]

  /**
   * Behandelt das Klick-Event vom Schließen-Eintrag.
   */
  public onCloseClick(): void {
    this.$emit('close')
  }
}
