import { Component, Vue } from 'vue-property-decorator'

/**
 * Mixin zum Abfragen und Setzen des Dark Mode.
 */
@Component({})
export default class Dark extends Vue {
  /**
   * Gibt an, ob das Dark Theme aktiviert ist.
   *
   * @returns Gibt true zurück, wenn das Dark Theme aktiviert ist.
   */
  public get dark(): boolean {
    return this.$vuetify.theme.dark
  }

  /**
   * Setzt den Dark Theme auf aktiv oder inaktiv.
   *
   * @param value - Aktiv (true) oder inaktiv (false).
   */
  public set dark(value: boolean) {
    this.$vuetify.theme.dark = value
  }
}
