import { UIState } from './ui-state.interface'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { UITheme } from '@/store/ui/theme.interface'
import { MenuBadgeSet } from './menu-badge.interface'

export default {
  searchables: (state: UIState): Column[] =>
    state.columns.filter(
      (column: Column): boolean =>
        column.searchable === true &&
        (!!column.description || !!column.label) &&
        ((!column.filterAs &&
          (column.formatAs === 'string' || column.formatAs === 'avatar')) ||
          column.filterAs === 'search')
    ),
  dates: (state: UIState): Column[] =>
    state.columns.filter(
      (column: Column): boolean =>
        column.searchable === true &&
        (!!column.description || !!column.label) &&
        ((!column.filterAs && column.formatAs === 'date') ||
          column.filterAs === 'range')
    ),
  booleans: (state: UIState): Column[] =>
    state.columns.filter(
      (column: Column): boolean =>
        column.searchable === true &&
        (!!column.description || !!column.label) &&
        ((!column.filterAs &&
          (column.formatAs === 'icon' || column.formatAs === 'check')) ||
          column.filterAs === 'switch')
    ),
  selects: (state: UIState): Column[] =>
    state.columns.filter(
      (column: Column): boolean =>
        column.searchable === true &&
        (!!column.description || !!column.label) &&
        column.filterAs === 'select' &&
        Array.isArray(column.filterOptions) &&
        column.filterOptions.length !== 0
    ),
  filters: (state: UIState): Filter[] => state.filters,
  hasFilters: (state: UIState): boolean => {
    const filter = state.filters
    return filter && Array.isArray(filter) && filter.length !== 0 ? true : false
  },
  menuBadges: (state: UIState): MenuBadgeSet => state.menuBadges,
  showGlobalFilter: (state: UIState): boolean => state.showGlobalFilter,
  theme: (state: UIState): UITheme => state.theme
}
