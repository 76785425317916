import 'reflect-metadata'
import Vue, { VNode } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import getVuetify, { TypeVuetify } from './plugins/vuetify'
import { i18n } from './plugins/i18n'
// Für die Kompatiblität der Offline-Wegwerf-Checkliste
import dompurify from 'vue-dompurify-html'
// Für die Kompatiblität der Offline-Wegwerf-Checkliste
import asyncComputed from 'vue-async-computed'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './mixins/globalMixins'
import './assets/global.css'
import './assets/transitions.css'
import '@/helpers/connectivity-helper'

Vue.use(asyncComputed)

// Globale Einstellungen für dompurify könnne hier vorgenommen werden.
// Siehe: https://www.npmjs.com/package/vue-dompurify-html
Vue.use(dompurify, {
  hooks: {
    afterSanitizeAttributes: (node: HTMLElement) => {
      const output: string[] = []

      const allowedProperties = [
        'color',
        'background-color',
        'backgroundColor',
        'border',
        'font-size',
        'fontSize',
        'font-style',
        'fontStyle',
        'font-family',
        'fontFamily',
        'font-weight',
        'fontWeight',
        'text-align',
        'textAlign',
        'text-decoration',
        'textDecoration'
      ]

      if (node.hasAttribute('style')) {
        const styles = node.style

        for (const prop in styles) {
          if (
            typeof styles[prop] === 'string' &&
            styles[prop] &&
            allowedProperties.indexOf(prop) > -1
          ) {
            output.push(
              prop.replace(/([A-Z])/, '-$1') + ':' + styles[prop] + ';'
            )
          }
        }

        if (output.length) {
          node.setAttribute('style', output.join(''))
        } else {
          node.removeAttribute('style')
        }
      }
    }
  },
  namedConfigurations: {
    dsgvo: {
      ALLOWED_TAGS: [
        'p',
        'strong',
        'em',
        'caption',
        'table',
        'tbody',
        'tr',
        'td',
        'span',
        'ol',
        'li',
        'figure',
        'a',
        'img',
        'br',
        'style'
      ],
      FORBID_ATTR: ['class']
    }
  }
})

Vue.config.productionTip = false

getVuetify().then((vuetify: TypeVuetify): void => {
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h): VNode => h(App)
  }).$mount('#app')
})
