import { getApp, getService } from './feathers'
import { config } from '@/config'

/**
 * Meldet den angemeldeten Benutzer ab.
 */
export async function logout(): Promise<void> {
  const app = await getApp()

  // Holt aktuellen JWT.
  const { accessToken } = await app.get('authentication')
  // Geparster Token.
  const payload = JSON.parse(atob(accessToken.split('.')[1]))

  // Abmelden.
  await app.logout()

  // Wenn es sich um einen SAML-Login handelt, muss auf den SAML-Server
  // umgeleitet werden. Dazu wird der SAML-Endpunkt "logout" im Backend
  // aufgerufen. Dieser leitet die Anfrage dann weiter
  if (
    payload.stg === 'saml' &&
    payload.nameid !== '' &&
    payload.sesidx !== ''
  ) {
    window.location.assign(
      `${(await config.client()).api.url}/auth/saml/logout?nameId=${
        payload.nameid
      }&sessionIndex=${payload.sesidx}`
    )
  }
}

/**
 * Meldet den angemeldeten Benutzer von allen Sessions/Geräten ab.
 */
export async function logoutAll(): Promise<void> {
  await (
    await getService('auth')
  ).remove(null, {
    query: { $allSessions: true }
  })

  await logout()
}
