import { ActionTree } from 'vuex'
import { Language } from '@/interfaces/Language.interface'
import { LanguageState } from './language-state.interface'
import { RootState } from '../root-state.interface'
import { getService } from '@/helpers/feathers'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export const actions: ActionTree<LanguageState, RootState> = {
  /**
   * Lädt die Spracheinstellungen vom Server und aktualisiert den State mit dem
   * Ergebnis.
   */
  async loadFromServer({ commit, state }): Promise<void> {
    const languageService = await getService<Language>('language')

    let result

    try {
      result = await languageService.find()
    } catch (ex) {
      return
    }

    // Resultset bekommen, nur das erste Element beachten.
    if (result instanceof Array) {
      result = result[0]
    }

    // Leeres Ergebnis, abbrechen.
    if (!result) {
      return
    }

    result = result as Language

    // State mutieren.
    commit('setActiveLanguages', result.active)
    commit('setDefaultLanguage', result.default)
    commit('setFallbackLanguage', result.fallback)

    if (!state.overriddenByUser) {
      i18n.locale = sessionStorage.getItem('language') || state.default
      i18n.fallbackLocale = state.fallback
    }

    // Auf Standardsprache setzen, wenn die aktuell gesetzte Sprache in i18n
    // keine aktive Sprache ist.
    if ((state.active as string[]).indexOf(i18n.locale) === -1) {
      i18n.locale = state.default

      if (state.overriddenByUser) {
        store.dispatch('notification/toggleToast', {
          text: i18n.t('system.notification.languageOverridden'),
          type: 'warning',
          icon: 'mdi-web',
          btnText: '',
          timeout: 5000
        })
      }
    }
  }
}
