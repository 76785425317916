import { MutationTree } from 'vuex'
import { LanguageState } from './language-state.interface'
import { CountryCode } from '@/constants/country-code.enum'
import { languageList } from '@/constants/language-list'

export const mutations: MutationTree<LanguageState> = {
  /**
   * Aktualisiert die Liste von aktiven Sprachen. Die aktiven Sprachen sind
   * immer ein Subset von [[languageList]].
   *
   * @param state - State im Store.
   * @param newActives - Sprachen, die aktiv sein sollen.
   */
  setActiveLanguages(state: LanguageState, newActives: CountryCode[]): void {
    state.active = languageList.filter(language =>
      newActives.includes(language)
    )
  },
  /**
   * Aktualisiert die Standardsprache.
   *
   * @param state - State im Store.
   * @param newDefault - Sprache, die als Standard verwendet werden soll.
   */
  setDefaultLanguage(state: LanguageState, newDefault: CountryCode): void {
    state.default = newDefault
  },
  /**
   * Aktualisiert die Fallback-Sprache.
   *
   * @param state - State im Store.
   * @param newFallback - Sprache, die als Fallback verwendet werden soll.
   */
  setFallbackLanguage(state: LanguageState, newFallback: CountryCode): void {
    state.fallback = newFallback
  },
  /**
   * Gibt an, dass die Sprache bereits vom Benutzer gewählt wurde.
   *
   * @param state - State im Store.
   */
  enableOverriddenByUser(state: LanguageState): void {
    state.overriddenByUser = true
  }
}
