export const fi = {
  externalLogin: {
    rule: {
      required: 'Täytä kaikki pakolliset kentät.',
      email: 'Sähköpostiosoite on virheellinen.',
      number: 'Tämän kentän on sisällettävä numero.'
    },
    buttonLabel: {
      check: 'Tarkistus',
      proceed: 'Jatka',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Sisäänkirjautuminen',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'lisätietoja'
    },
    field: {
      lastName: 'Sukunimi',
      firstName: 'Etunimi',
      company: 'Yritys',
      country: 'Alkuperämaa:',
      visitReason: 'Vierailun syy',
      email: 'Sähköposti',
      id: 'Henkilökortin nro',
      street: 'Katuosoite',
      zip: 'Postinumero',
      location: 'Paikkakunta',
      phone: 'Puhelin',
      birthdate: 'Syntymäaika',
      training: 'Koulutus',
      salutation: 'Puhuttelu',
      gender: 'Sukupuoli',
      titel: 'Titteli',
      prefixName: 'Etuliite',
      suffixName: 'Lisäsana',
      city: 'Kaupunki',
      workingTime: 'Työaika',
      workingTasks: 'Työskentelee',
      citizenship: 'Kansalaisuus',
      healthInsurance: 'Sairausvakuutus',
      healthInsuranceName: 'Sairausvakuutusyhtiön nimi',
      healthInsuranceZip: 'Sairausvakuutus: Postinumero',
      healthInsuranceCity: 'Sairausvakuutus: Kaupunki',
      healthInsuranceStreet: 'Sairausvakuutus: Katu',
      birthplace: 'Syntymäpaikka',
      letterSalutation: 'Puhuttelu',
      adressAddition: 'Osoitteen loppuosa',
      hireDate: 'Palkkauksen alkamispäivä',
      leavingDate: 'Palkkauksen lopetuspäivä',
      fax: 'Faksi'
    },
    gender: {
      notSpecified: 'Ei määritelty',
      femail: 'nainen',
      male: 'mies',
      divers: 'muu'
    },
    salutation: {
      notSpecified: '-',
      female: 'rouva',
      male: 'herra'
    },
    error: {
      maxLength: 'Maksimipituus ylitettiin.',
      noVisitReason:
        'Käynnille ei ole perusteita tällä kielellä. Valitse toinen kieli.'
    }
  },
  error: {
    0x0000: {
      title: 'Virhe',
      text: 'Yleinen virhe: {message}'
    },
    0x0001: {
      title: 'Virhe validoitaessa syötettä',
      text: 'Syötä tiedot oikein vastaavaan kenttään.'
    },
    0x0002: {
      title: 'Palaava ulkopuolinen työntekijä',
      text: 'Toistuvien ulkopuolisten työntekijöiden palkkaaminen ei ole sallittua.'
    },
    0x0003: {
      title: 'Palvelin ei ole tavoitettavissa',
      text: 'Palvelinta ei voida tavoittaa tällä hetkellä. Yritä myöhemmin uudelleen. Yksityiskohdat: {details}'
    },
    0x0004: {
      title: 'API-salaisuutta ei ole saatavilla',
      text: 'API-salaisuus palvelimen kanssa käytävää viestintää varten ei ole käytettävissä.'
    },
    0x0005: {
      title: 'Perus-URL ei ole käytettävissä',
      text: 'Eplas-frontendin perus-URL ei ole käytettävissä.'
    },
    0x0006: {
      title: 'Ongelma rekisteröitymisen kanssa',
      text: 'Rekisteröitymistäsi eplasiin ei voitu suorittaa loppuun. Ota yhteyttä yrityksen yhteyshenkilöön henkilökohtaisesti.'
    },
    0x0007: {
      title: 'Tunnuksesi on virheellinen',
      text: 'Tunnuksesi on virheellinen'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Salasana',
      username: 'Käyttäjänimi'
    },
    welcomeMessage: {
      text: 'Tervetuloa eplasiin!'
    },
    forwardMessage: {
      title: 'Sisäänkirjautuminen onnistui!',
      text: 'Sinut ohjataan eteenpäin…'
    },
    buttonLabel: {
      login: 'Sisäänkirjautuminen'
    },
    loginMethod: {
      local: 'Sisäänkirjautuminen',
      saml: 'Yrityksen sisäänkirjautuminen',
      ldap: 'Windows-kirjautuminen',
      external: 'Ulkopuolinen työntekijä',
      pin: 'PIN-koodin syöttö'
    },
    forgotPassword: {
      button: 'Unohditko salasanasi?'
    },
    error: {
      noOfflineLogin: 'Kirjautuminen ei ole mahdollista offline-tilassa.',
      username:
        'Kirjautuminen epäonnistui. Tarkista käyttäjätunnus ja salasana.',
      usernameValidation: 'Käyttäjätunnus vaaditaan.',
      passwordValidation: 'Salasana vaaditaan.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Toista salasana',
      setPasswordConfirm: 'Aseta salasana',
      setPasswordTitle:
        'Ensimmäinen sisäänkirjautuminen: Ole hyvä ja vaihda salasanasi',
      success: 'Salasana vaihdettu onnistuneesti'
    },
    passwordRules: {
      title: 'Salasanoja koskevat ehdot',
      maximumLength: 'Salasana on liian pitkä.',
      minimumLength: 'Salasana on liian lyhyt.',
      minimumDigits: 'Salasana ei sisällä tarpeeksi numeroita.',
      minimumUpperCase: 'Salasana ei sisällä tarpeeksi isoja kirjaimia.',
      minimumLowerCase: 'Salasana ei sisällä tarpeeksi pieniä kirjaimia.',
      minimumSpecialCharacters: 'Salasana ei sisällä erikoismerkkejä.',
      allowedSpecialCharacters:
        'Salasana sisältää erikoismerkkejä, jotka eivät ole sallittuja.',
      general: 'Salasanaa vaihdettaessa tapahtui virhe.',
      passwordConfirmationValidation: 'Salasanat eivät vastaa toisiaan.'
    },
    buttonLabel: {
      login: 'Kirjautumissivulle'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Unohditko salasanasi?',
      email: 'Käyttäjäsi sähköpostiosoite',
      confirm: 'Sähköpostin lähettäminen',
      error: 'Sähköpostiosoite on virheellinen.',
      cancel: 'Keskeytä',
      hint: '',
      emailSent: 'Sähköposti lähetettiin.'
    }
  },
  passwordReset: {
    title: 'Ole hyvä ja vaihda salasanasi'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Tietojen päivittäminen'
    },
    text: {
      welcome: 'On ilo nähdä, että kirjaudut eplasiin ensimmäistä kertaa.',
      employeeInfos:
        'Täytä alla olevat tiedot tai tarkista niiden ajantasaisuus.',
      password: 'Valitse salasana'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'sisäinen matkapuhelin',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Olet nyt online-tilassa.',
        offline: 'Olet nyt offline-tilassa.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Tulosta',
      search: 'Hae',
      close: 'Sulje',
      create: 'Luo',
      edit: 'Muokkaa',
      copy: 'Kopioi',
      back: 'Takaisin',
      remove: 'Poista',
      abort: 'Keskeytä',
      finished: 'Valmis',
      ok: 'OK',
      cancel: 'Keskeytä'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
