
import { Component, Vue } from 'vue-property-decorator'
import { getApp } from '@/helpers/feathers'

/**
 * Testet, ob der Benutzer noch eingeloggt ist
 * Falls nicht, leitet es diesen auf den Loginscreen um
 */
@Component({})
export default class ServerConnection extends Vue {
  /**
   * Die Verbindung wird geprüft
   *
   */
  private created(): void {
    this.tryConnection()
  }

  /**
   * Prüft die Verbindung und leitet bei Fehlschlag zum Login um
   */
  private async tryConnection(): Promise<void> {
    try {
      // Überprüft ob jwt-Token noch verfügbar ist
      const response = await (await getApp()).reAuthenticate()

      let language = response.user ? response.user.systemLanguage : 'English'

      if (language !== 'English' && language !== 'Deutsch') {
        language = 'English'
      }
      this.$i18n.locale = language === 'Deutsch' ? 'de' : 'en'
    } catch (error) {
      if (
        this.$router.currentRoute.name !== 'login' &&
        this.$router.currentRoute.path !== '/'
      ) {
        this.$router.push({ name: 'login' })
      }
    }
  }
}
