import { defaultConfig } from './default.config'
import { ConfigEntry } from './config-entry.interface'

/**
 * Gibt die Einstellung für das opportunistische Aktualisieren eines
 * Offline-Service zurück.
 *
 * @param name - Name vom Offline-Service von den die Einstellungen zurückgeben
 * werden sollen.
 * @returns Einstellungen für das opportunistische Aktualisieren, `null` wenn
 * für den Service das opportunistische Aktualisieren deaktiviert wurde.
 */
export function getConfig(name: string): Readonly<ConfigEntry | null> {
  let result: ConfigEntry | null = null

  if (defaultConfig[name] && name !== '_default') {
    if (defaultConfig[name].enable !== false) {
      if (defaultConfig[name].completed !== true) {
        defaultConfig[name] = Object.assign(
          {},
          defaultConfig._default,
          defaultConfig[name]
        )
      }

      if (defaultConfig[name].enable !== false) {
        result = defaultConfig[name] as ConfigEntry
      }
    }
  } else if (defaultConfig._default.enable !== false) {
    result = defaultConfig._default
  }

  return result
}
