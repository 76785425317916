
import { Component, Vue, Prop } from 'vue-property-decorator'
import { I18nReplacements } from '@/interfaces/I18nReplacements.interface'

/**
 * Komponente zur einheitlichen Anzeige von Fehlermeldungen. Sprachdaten zu
 * Fehlercodes werden aus `errors.CODE.title|text` gelesen.
 */
@Component({})
export default class GenericError extends Vue {
  /**
   * Fehlercode; wird in der Komponente angezeigt und dazu verwendet, die
   * passenden Sprachstrings aus den Sprachdaten zu holen. Wird kein Fehlercode
   * angegeben, wird ein generischer Fehlercode (0x0000) verwendet.
   */
  @Prop({
    type: Number,
    required: false,
    default: 0x0000
  })
  public code!: number

  /**
   * Textersetzungen für den Fehlertext. Wird an vue-i18n weitergegeben.
   */
  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public replacements!: I18nReplacements

  /**
   * Optionale Liste von Strings oder Replacements für vue-i18n, die als
   * formatierte Liste in der Fehlermeldung angezeigt wird.
   */
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  public list!: string[] | I18nReplacements[]

  /**
   * Auf 4 Stellen formatierter HEX-Code vom Fehlercode. Aus `255` wird
   * beispielsweise `"0x00FF"`.
   *
   * @returns Formatierter HEX-Code.
   */
  public get hexCode(): string {
    const hex = this.code.toString(16).toUpperCase()

    return '0x0000'.substr(0, 6 - hex.length) + hex
  }

  /**
   * Formatiert ein Listenelement abhängig davon, ob es ein String oder
   * Replacement für vue-i18n ist.
   *
   * @param element - Zu formatierendes Listenelement.
   * @returns Formatierten (Sprach-)String.
   */
  public formatListElement(element: string | I18nReplacements): string {
    if (typeof element === 'string') {
      return element
    }

    return this.$t(`error.${this.code}.listItem`, element).toString()
  }
}
