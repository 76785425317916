
import { Component, Vue } from 'vue-property-decorator'
import moment, { Moment } from 'moment'

/**
 * Informationen zu einer Programmversion.
 */
interface VersionInformation {
  /**
   * Name/Versionsstring.
   */
  name: string

  /**
   * Release-Datum.
   */
  date: Moment

  /**
   * Änderungen/Neuerungen/Bugfixes in der Version.
   */
  changes: string[]
}

/**
 * Changelogbereich des Infocenters.
 */
@Component({})
export default class InfoCenterChangelog extends Vue {
  /**
   * Aktives <v-extension-panel>.
   */
  public active = 0

  /**
   * Changelog aller Versionen.
   */
  public versions: VersionInformation[] = [
    {
      name: '0.2-dev',
      date: moment('2019-10-30'),
      changes: [
        'Grundlegende Überarbeitung der Oberfläche.',
        'Menüstruktur überarbeitet.',
        'Performanceverbesserungen.'
      ]
    },
    {
      name: '0.1-dev',
      date: moment('2019-09-14'),
      changes: ['Erste Version von eplas NXT.']
    }
  ]
}
