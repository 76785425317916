
import { Component, Vue } from 'vue-property-decorator'
import { State, Getter } from 'vuex-class'

/**
 * Versionsbereich des Infobereichs.
 */
@Component({})
export default class InfoCenterVersion extends Vue {
  /**
   * Programmversion.
   */
  @State('version')
  public version!: string

  /**
   * Release-Channel.
   */
  @State('channel')
  public channel!: string

  /**
   * Detaillierter Versionsstring.
   */
  @Getter('fullVersion')
  public fullVersion!: string

  /**
   * Farbiges eplas-Logo.
   */
  public logo: string = require('@/assets/logo.svg')
}
