export const ru = {
  externalLogin: {
    rule: {
      required: 'Пожалуйста, заполните все обязательные поля.',
      email: 'Адрес электронной почты недействителен.',
      number: 'Это поле должно содержать номер.'
    },
    buttonLabel: {
      check: 'Проверка',
      proceed: 'Далее',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Зарегистрироваться',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'дополнительная информация'
    },
    field: {
      lastName: 'Фамилия',
      firstName: 'Имя',
      company: 'Фирма',
      country: 'Страна происхождения:',
      visitReason: 'Цель визита',
      email: 'Электронный адрес',
      id: 'Номер удостоверения',
      street: 'Улица / № дома',
      zip: 'Почтовый индекс',
      location: 'Город',
      phone: 'Телефон',
      birthdate: 'Дата рождения',
      training: 'Образование',
      salutation: 'Форма обращения',
      gender: 'Пол',
      titel: 'Звание',
      prefixName: 'Префикс имени',
      suffixName: 'Суффикс имени',
      city: 'Город',
      workingTime: 'Время работы',
      workingTasks: 'Занимаемая должность',
      citizenship: 'Гражданство',
      healthInsurance: 'Больничная касса',
      healthInsuranceName: 'Наименование больничной кассы',
      healthInsuranceZip: 'Больничная касса: почтовый индекс',
      healthInsuranceCity: 'Больничная касса: город',
      healthInsuranceStreet: 'Больничная касса: улица',
      birthplace: 'Место рождения',
      letterSalutation: 'Форма обращения в письме',
      adressAddition: 'Дополнение к адресу',
      hireDate: 'Дата приема на работу',
      leavingDate: 'Дата увольнения',
      fax: 'Факс'
    },
    gender: {
      notSpecified: 'Данных нет',
      femail: 'женский',
      male: 'мужской',
      divers: 'другой'
    },
    salutation: {
      notSpecified: '-',
      female: 'Г-жа',
      male: 'Г-н'
    },
    error: {
      maxLength: 'Превышена максимальная длина.',
      noVisitReason:
        'Цели визита на этом языке неизвестны. Пожалуйста, выберите другой язык.'
    }
  },
  error: {
    0x0000: {
      title: 'Ошибка',
      text: 'Общая ошибка: {message}'
    },
    0x0001: {
      title: 'Ошибка проверки входных данных',
      text: 'Пожалуйста, правильно введите данные в соответствующее поле.'
    },
    0x0002: {
      title: 'Внештатный возвращающийся сотрудник',
      text: 'Возвращение внештатных сотрудников не допускается.'
    },
    0x0003: {
      title: 'Сервер недоступен',
      text: 'Сервер в настоящее время недоступен. Пожалуйста, повторите попытку позже. Подробности: {details}'
    },
    0x0004: {
      title: 'Секрет API недоступен',
      text: 'Секрет API для связи с сервером недоступен.'
    },
    0x0005: {
      title: 'Базовый URL-адрес недоступен',
      text: 'Базовый клиентский URL-адрес eplas недоступен.'
    },
    0x0006: {
      title: 'Проблема с входом в систему',
      text: 'Не удалось зарегистрироваться в eplas. Пожалуйста, обратитесь лично к контактному лицу в вашей компании.'
    },
    0x0007: {
      title: 'Ваш токен недействителен',
      text: 'Ваш токен недействителен'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Пароль',
      username: 'Имя пользователя'
    },
    welcomeMessage: {
      text: 'Добро пожаловать в eplas!'
    },
    forwardMessage: {
      title: 'Вы успешно вошли в систему!',
      text: 'Вы будете немедленно перенаправлены...'
    },
    buttonLabel: {
      login: 'Зарегистрироваться'
    },
    loginMethod: {
      local: 'Зарегистрироваться',
      saml: 'Вход в систему компании',
      ldap: 'Вход в систему Windows',
      external: 'Внештатный сотрудник',
      pin: 'Ввод PIN-кода'
    },
    forgotPassword: {
      button: 'Забыли пароль?'
    },
    error: {
      noOfflineLogin: 'Невозможно войти в систему в автономном режиме!',
      username: 'Ошибка входа. Проверьте свое имя пользователя и пароль.',
      usernameValidation: 'Имя пользователя отсутствует.',
      passwordValidation: 'Введите пароль.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Повторите пароль',
      setPasswordConfirm: 'Установите пароль',
      setPasswordTitle:
        'Первый вход в систему: пожалуйста, измените свой пароль',
      success: 'Пароль успешно изменен'
    },
    passwordRules: {
      title: 'Политика паролей',
      maximumLength: 'Пароль слишком длинный.',
      minimumLength: 'Пароль слишком короткий.',
      minimumDigits: 'Пароль не содержит достаточного количества цифр.',
      minimumUpperCase:
        'Пароль не содержит достаточного количества прописных букв.',
      minimumLowerCase:
        'Пароль не содержит достаточного количества строчных букв.',
      minimumSpecialCharacters: 'Пароль не содержит специальных символов.',
      allowedSpecialCharacters:
        'Пароль содержит недопустимые специальные символы.',
      general: 'При смене пароля произошла ошибка.',
      passwordConfirmationValidation: 'Пароли не совпадают.'
    },
    buttonLabel: {
      login: 'Перейти на страницу входа в систему'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Забыли пароль?',
      email: 'Адрес электронной почты вашего пользователя',
      confirm: 'Отправить электронное письмо',
      error: 'Адрес электронной почты недействителен.',
      cancel: 'Прервать',
      hint: '',
      emailSent: 'Письмо отправлено.'
    }
  },
  passwordReset: {
    title: 'Пожалуйста, измените свой пароль'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Обновить данные'
    },
    text: {
      welcome: 'Спасибо за вашу регистрацию в eplas!',
      employeeInfos:
        'Пожалуйста, заполните приведенные ниже поля и проверьте точность введенных данных.',
      password: 'Пожалуйста, выберите пароль'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'внутренний мобильный',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Теперь вы в сети.',
        offline: 'Теперь вы находитесь в автономном режиме.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Печать',
      search: 'Поиск',
      close: 'Закрыть',
      create: 'Создать',
      edit: 'Редактировать',
      copy: 'Копировать',
      back: 'Назад',
      remove: 'Удалить',
      abort: 'Прервать',
      finished: 'Готово',
      ok: 'Ок',
      cancel: 'Прервать'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
