export const en = {
  system: {
    notification: {
      connectivity: {
        online: 'You are now online.',
        offline: 'You are now offline.'
      },
      languageOverridden:
        'The language you previously selected is not available on this ' +
        'system. The language has been set to the system default.',
      licenseInvalid:
        'Your license expired on {expired}. Please contact eplas-Support.',
      licenseCheckFailed:
        'Your license could not be validated. Please contact eplas-Support.'
    },
    languages: {
      cz: 'Čeština',
      de: 'Deutsch',
      en: 'English',
      es: 'Español',
      fi: 'Suomi',
      fr: 'Français',
      hr: 'Kroatisch',
      hu: 'Magyar',
      it: 'Italiano',
      nl: 'Nederlands',
      pl: 'Polski',
      ro: 'Română',
      ru: 'Русский',
      sl: 'Slovenščina',
      tr: 'Türkçe',
      gr: 'Ελληνικά',
      bg: 'български',
      uk: 'український'
    },
    employee: {
      nameFormat: [
        '-',
        '{firstName}',
        '{lastName}',
        '{firstName} {lastName}',
        '({personnelNumber})',
        '{firstName} ({personnelNumber})',
        '{lastName} ({personnelNumber})',
        '{firstName} {lastName} ({personnelNumber})'
      ]
    },
    contentList: {
      footer: 'No entries found | {0} entry found | {0} entries found'
    },
    general: {
      print: 'Print',
      search: 'Search',
      close: 'Close',
      create: 'Create',
      edit: 'Edit',
      copy: 'Copy',
      back: 'Back',
      remove: 'Remove',
      abort: 'Abort',
      finished: 'Finished',
      ok: 'OK',
      cancel: 'Cancel',
      activate: 'Activate',
      deactivate: 'Deactivate',
      update: 'Update',
      yes: 'Yes',
      no: 'No'
    }
  },
  colorPicker: {
    color: {
      missing: 'no selected color'
    }
  },
  colorSettings: {
    header: {
      title: 'Color settings'
    },
    colorPicker: {
      colorSelection: 'Select color'
    },
    color: {
      primary: 'Primary color',
      secondary: 'Secondary color',
      accent: 'Accent color'
    },
    changeColorInfo: {
      darkMode: {
        active:
          'Deactivate the dark mode in the user settings to adjust the colors of the standard interface.',
        inactive:
          'Activate the dark mode in the user settings to adjust the colors of the dark interface.'
      }
    }
  },
  columnTree: {
    placeholder: {
      new: '<New entry>',
      rename: 'Enter name'
    },
    layer: {
      empty: 'No entries'
    },
    action: {
      addEntry: 'Add entry'
    }
  },
  dashboardViewer: {
    placeholder: {
      unnamedRow: 'unnamed row'
    },
    widgetTitle: {
      instructions: 'Instructions',
      documents: 'Documents',
      appointments: 'Appointments',
      tasks: 'Tasks',
      audits: 'Audits'
    },
    widgetDescription: {
      open: 'open',
      done: 'done',
      total: 'total',
      label: {
        first: 'Numerator',
        second: 'Denominator'
      },
      hint: {
        first: 'Indicates the progress as a number',
        second: 'Indicates the total count of the selected data source'
      },
      validationError: 'Field has to be set'
    },
    error: {
      noWidgetsFound:
        'There are no widgets in this dashboard yet, please contact your supervisor to get access to the widgets you need.'
    }
  },
  substanceImport: {
    importForm: {
      pdfImportLabel: 'PDF Import',
      excelImportLabel: 'Excel Import',
      fileUpload: 'File Upload',
      pdfUpload: 'Insert a PDF file including a datasheet',
      excelUpload: 'Insert an Excel file with substance data',
      noXML: 'One or more PDF files contain no XML file',
      wrongType: 'invalid filetype',
      import: 'import',
      excelUploadFailed: 'Uploading Excel file failed',
      xmlUploadFailed: 'Upload of one or multiple XML files failed',
      extractingAttachmentsFailed: 'Extracting the attachments failed',
      processPdfFailed: 'Error at processing the PDF file'
    },
    feedbackList: {
      result: 'Result',
      warning: 'WARNING',
      substance: 'Substance',
      substanceExisting: 'Substance already exists',
      invalidValues: 'Invalid values (see details) ',
      error: 'ERROR: Import not possible',
      updated: 'updated',
      created: 'created',
      and: 'and',
      save: 'Save',
      successfulImports: 'Successful imports',
      criticalImports: 'Critical imports',
      failedImports: 'Failed imports',
      importAnyway: 'Import anyway',
      all: 'All',
      logAll: 'Save all logs',
      unknownSubstance: 'Unknown Substance'
    },
    excelLogs: {
      row: 'Row',
      nameNotFound: 'Substance name not found',
      invalidHStatement: 'is no valid H-Statement',
      invalidPStatement: 'is no valid P-Statement',
      notExistingHStatement: 'is not an existing H-Statement',
      notExistingPStatement: 'is not an existing P-Statement',
      invalidCompositionState:
        'is no valid information about the composition state',
      invalidGHSSymbol: 'is no valid GHS symbol',
      invalidSignalword: 'is no valid signalword',
      invalidStorageClass: 'is no valid storageclass',
      invalidUnNr: 'is no valid UN number',
      invalidWaterHazardClass: 'is no valid water hazard class',
      invalidBoilingPoint: 'is no valid boiling point',
      invalidVaporPressure: 'is no valid vapor pressure',
      invalidVaporPressureUnit: 'is no valid vapor pressure unit',
      invalidPhysicalState: 'is no valid physical state'
    }
  },
  mle: {
    title: 'Edit translations of {field}',
    fields: {
      title: 'Widget-Title',
      description: 'Widget description',
      buttonLabel: 'Button description',
      dashboard: 'dashboard'
    },
    validationErrorLocale: 'Title for current selected language has to be set',
    validationErrorFallback: 'Title for fallback language has to be set'
  },
  widgetEditor: {
    title: {
      new: 'Add new widget',
      update: 'Update widget'
    },
    buttons: {
      create: '@:system.general.create',
      update: '@:system.general.update',
      cancel: '@:system.general.cancel'
    },
    types: {
      ProgressCircle: {
        name: 'Progress Circle',
        description:
          'Shows the progress of a certain range of data. Good for `complete/incomplete` data.'
      },
      ActionButton: {
        name: 'Action Button',
        description:
          'This button takes you to a specified location within eplas or triggers a certain function.'
      },
      ProgressCircleFromTo: {
        name: 'Progress Circle (from / to)',
        description:
          'Shows the progress of a certain range of data. Good for percentage representations.'
      },
      KeyNumber: {
        name: 'Key Number',
        description: 'Shows a number or a metric.'
      }
    },
    notification: {
      insert: {
        success: 'New widget created.',
        error: 'An error occured while creating a new widget.'
      },
      update: {
        success: 'Widget was updated successfully.',
        error: 'An error occured while updating this widget.'
      }
    },
    fields: {
      titleColor: {
        label: 'Titlebar color'
      },
      titleIcon: {
        label: 'Title icon',
        hint: 'Icon, which represents the widget itself.'
      },
      title: {
        label: 'Widget title',
        hint: 'Name of the widget. Appears in the header.',
        validationError: 'This widget needs a title.'
      },
      widgetType: {
        label: 'Widget type'
      },
      size: {
        label: 'Widget size'
      },
      description: {
        label: 'Widget description',
        hint: 'Description of what the widget is capable of. Appears at the bottom of the widget.'
      },
      query: {
        label: 'Data Source',
        validationError: 'The selected widget type requires a data source.'
      },
      action: {
        label: 'Action',
        hint: 'Anchor point within eplas',
        validationError: 'You need to specify an action for this widget.'
      },
      actionIcon: {
        label: 'Action Button Icon',
        hint: 'Icon, which represents the widget button.',
        validationError: 'An icon is required.'
      },
      actionLabel: {
        label: 'Action Button Label',
        hint: 'Description of the Icon.'
      },
      progressCircleColor: {
        label: 'Color of progress circle'
      },
      doneOrOpenAppointments: {
        label: 'Go to open or done',
        hint: 'Go to open or done appointments, when clicking on the widget'
      },
      appointmentDate: {
        label: 'Startdate for the time filter',
        hint: 'Show appointments from the selected date to today + 2 months'
      }
    },
    queries: {
      2: {
        title: 'active and inactive employees',
        description:
          'Returns the amount of active and inactive employees in eplas.'
      },
      5: {
        title: 'appointments of the employee',
        description:
          'Returns the amount of open and completed appointments of the current user.'
      },
      6: {
        title: 'documents of the employee',
        description:
          'Returns the amount of open and completed documents of the current user.'
      },
      7: {
        title: 'instructions of the employee',
        description:
          'Returns the amount of open and completed instructions of the current user.'
      },
      9: {
        title: 'audits of the employee',
        description:
          'Returns the amount of open and completed audits of the current user.'
      },
      10: {
        title: 'tasks of the employee',
        description:
          'Returns the amount of open and completed tasks of the current user.'
      },
      11: {
        title: 'sum of a checklist variable',
        description: 'Returns a sum of a specified checklist variable.'
      },
      12: {
        title: 'indicator',
        description: 'Returns an indicator.'
      },
      13: {
        title: '2 indicators',
        description: 'Returns 2 indicators (like 4/9).'
      },
      14: {
        title: 'overdue tasks of the employee',
        description: 'Returns the amount of overdue tasks of the current user.'
      },
      15: {
        title: 'accidents: 1000 man quota',
        description:
          'Returns the quote of accidents per 1000 employees according to a specified year and your responsibilities.'
      },
      16: {
        title: 'accidents: lost days in total',
        description:
          'Returns the sum of lost days within a year that ocurred in reportable accidents. The responsibilities of the current user will be considered.'
      },
      17: {
        title: 'accidents: open tasks',
        description:
          'Returns the sum of open tasks that relates to accidents. The responsibilities of the current user will be considered.'
      },
      18: {
        title: 'accidents: completed tasks',
        description:
          'Returns the sum of completed tasks that relates to accidents. The responsibilities of the current user will be considered.'
      },
      19: {
        title: 'accidents: lost days per employee',
        description:
          'Returns the sum of lost days per employee within a year that ocurred in reportable accidents. The responsibilities of the current user will be considered.'
      },
      20: {
        title: 'accidents: lost days per accident',
        description:
          'Returns the sum of lost days per accident within a year that ocurred in reportable accidents. The responsibilities of the current user will be considered.'
      },
      21: {
        title: 'accidents: total costs',
        description:
          'Returns the total costs issued by accidents during a year. The responsibilities of the current user will be considered.'
      },
      22: {
        title: 'accidents: average costs per employee',
        description:
          'Returns the costs per employee issued by accidents during a year. The responsibilities of the current user will be considered.'
      },
      23: {
        title: 'accidents: average costs per accident',
        description:
          'Returns the costs per accident issued by accidents during a year. The responsibilities of the current user will be considered.'
      },
      24: {
        title: 'accidents: sum of accidents of directly subordinated employees',
        description:
          'Returns the sum of accidents of employees directly subordinated to the current user.'
      },
      25: {
        title: 'accidents: sum of accidents by responsibilities',
        description:
          'Returns the sum of accidents. The responsibilities of the current user will be considered.'
      },
      26: {
        title: 'accidents: total sum of accidents',
        description:
          'Returns the sum of all accidents. The responsibilities of the current user will not be considered.'
      }
    }
  },
  queryFilters: {
    general: {
      noFilter: 'no filter set',
      noNumber: 'this field must contain a number or be empty',
      values: {
        true: '@:system.general.yes',
        false: '@:system.general.no'
      }
    },
    filter: {
      uwtyp: {
        label: 'instruction type',
        hint: 'Restricts the result to one instruction type',
        values: {
          0: 'Instruction',
          1: 'Appointment',
          2: 'Document',
          3: 'SCORM',
          4: 'Medical checkup'
        }
      },
      taskUserRole: {
        label: 'Task userrole',
        hint: 'Restricts the result to tasks of the selected user role.',
        values: {
          0: 'Responsible, executor, observer or participant',
          1: 'Responsible',
          2: 'Executor, observer or participant',
          4: 'Effectiveness checker'
        }
      },
      reportable: {
        label: 'Reporting requirement',
        hint: 'Filters the accidents according to whether they are reportable or not reportable',
        values: {
          0: 'Only reportable accidents',
          1: 'Only non-reportable accidents',
          2: 'All accidents'
        }
      },
      tovalue: {
        label: 'Denominator',
        hint: 'Changes the denominator of the calculation.'
      },
      checklistvariableid: {
        label: 'Checklistvariable ID',
        hint: 'reference ID'
      },
      year: {
        label: 'year number',
        hint: 'years reference'
      },
      showappointments: {
        label: 'Include appointments',
        hint: 'Includes appoitments to the calculation.'
      },
      showhealthcare: {
        label: 'Include medical checkups',
        hint: 'Includes medical checkups to the calculation.'
      },
      category: {
        label: 'Audit category ID',
        hint: 'Reference to audit category ID'
      },
      thisyear: {
        label: 'current year',
        hint: 'if set only the current year will be concidered.'
      },
      total: {
        label: 'Denominator',
        hint: 'Changes the denominator of the calculation.'
      },
      responsibilityDisciplinaric: {
        label: 'disciplinary responsibility',
        hint: 'Refers to employees depending on your disciplinary responsibility.'
      },
      responsibilityOrganisatoric: {
        label: 'organizational responsibility',
        hint: 'Refers to employees depending on your disciplinary responsibility.'
      }
    }
  },
  widgetAction: {
    fields: {
      placeholderChoose: 'Please choose an action',
      placeholderSet: 'Please set an action'
    },
    notification: {
      requestId: {
        label: 'ID required',
        text: 'Your selected action requires an ID to work properly. Please insert an ID.'
      }
    },
    actions: {
      name: {
        dashboardOverview: 'Dashboard: overview',
        dashboardInstructions: 'Dashboard: instructions & SCORM',
        dashboardDocuments: 'Dashboard: documents',
        dashboardAppointments: 'Dashboard: appointments & medical checkups',
        dashboardTasks: 'Dashboard: tasks & actions',
        dashboardTasksResponsible:
          'Dashboard: tasks & actions as a responsible',
        dashboardAudits: 'Dashboard: audits',
        dashboardEmployeeSearch: 'Dashboard: employee lookup',
        dashboardSubstances: 'Dashboard: hazardous substances',
        dashboardDirectives: 'Dashboard: operating instructions',
        dashboardMySettings: 'Dashboard: my settings',
        dashboardVoluntaryTopics: 'Dashboard: voluntary topics',
        dashboardAuditOverview: 'Dashboard: audit overview',
        dashboardAccidentOverview: 'Dashboard: digital accidentbook overview',
        viewAccidentreportWizard: 'Accident report wizard',
        viewAssignments: 'Assignments',
        viewCallback: 'Callbacks',
        viewEmployee: 'Employees',
        viewFeedback: 'Feedback',
        viewGroupfeedback: 'Groupfeedback',
        viewInstructions: 'Instructions',
        viewAppointments: 'Appointments',
        viewDocuments: 'Documents',
        viewScorm: 'SCORM',
        viewVoluntarytopics: 'Voluntary topics',
        viewHazardanalysis: 'Hazard analysis',
        viewSubstances: 'hazardous substances',
        viewProviders: 'Provider',
        viewDirectiveMashine: 'Directives: Mashine',
        viewDirectiveBio: 'Directives: organic substances',
        viewDirectiveSubstance: 'Directives: hazardous substances',
        viewDirectiveOrga: 'Directives: organisation',
        viewDirectiveUser: 'Directives: user',
        viewAppointment: 'Appointment Management',
        viewAccidentReports: 'List of accident reports',
        viewMedAppointments: 'List of medical check-ups',
        viewHealthPrepResolver: 'Consent to offer examinations',
        openReport: 'print report',
        createOfflineAudit: 'create offline audit'
      }
    }
  },
  widgetBasic: {
    action: {
      title: 'Widget action'
    },
    content: {
      missing: 'Content missing',
      deactivated: 'Widget deactivated'
    },
    remove: {
      titel: 'Remove widget',
      text: 'Are you sure you want to remove this widget? Employees assigned to the dashboard will no longer have access to the widget.'
    },
    deactivate: {
      title: 'Deactivate widget',
      text: 'Are you sure you want to deactivate this widget? Employees assigned to the dashboard will no longer have access to the widget.'
    },
    notification: {
      remove: {
        success: 'Successfully removed.',
        error: 'An error occured while removing the widget.'
      },
      update: {
        error: 'An error occured while updating the widget.'
      }
    }
  },
  actionButton: {
    title: {
      auditCategory: 'overview',
      accidentAssistant: 'Report Accident',
      activity: 'capture activity'
    }
  },
  helpCenter: {
    tabs: {
      support: 'Support',
      manual: 'Manual'
    }
  },
  helpCenterManual: {
    manual: {
      title: 'Dashboard manual',
      subtitle: 'Click here to read the dashboard manual'
    }
  },
  helpCenterSupport: {
    title: 'Contact support',
    staff: {
      text: 'Our support ({email}) is staffed from {days} from {time}.',
      email: 'support@eplas.net',
      days: 'Monday to Friday'
    },
    reachability: {
      text: 'You can reach a support employee by phone from Monday to Friday {from} to {to} on the phone number: {telephoneNumber}.',
      telephoneNumber: '+49 (0)2771 - 87 97 - 400'
    }
  },
  infoCenter: {
    tabs: {
      version: 'Version',
      changelog: 'Changelog',
      thirdParty: 'Licensing'
    }
  },
  infoCenterVersion: {
    header: {
      title: 'eplas 10'
    },
    description: {
      version: 'Version {versionNumber}',
      channel: 'This installation is on the {channelName}-Channel.'
    },
    website: {
      link: 'https://www.eplas.net'
    }
  },
  infoCenterThirdParty: {
    title: 'Licensing',
    subtitle: 'Click {link} for a list of the used third-party software.',
    link: 'here'
  },
  aboutSection: {
    text: {
      copyright: '(c) {year} Jochen Blöcher GmbH. All rights reserved.'
    },
    statements: {
      imprintLabel: 'Imprint',
      privacyLabel: 'Privacy Statement'
    }
  },
  sideNavigation: {
    subheader: {
      start: 'Start',
      administration: 'Administration',
      develop: 'Development',
      classic: 'Classic eplas',
      activity: 'Activities',
      overview: 'Audits'
    },
    header: {
      structure: 'Structure',
      system: 'System',
      activity: 'Activities',
      dashboard: 'Dashboard'
    },
    listItem: {
      dashboard: 'My dashboard',
      classicDashboard: 'Dashboard',
      classicAdministration: 'Administration',
      auditsOnline: 'Audits Online',
      auditsOffline: 'Audits Offline',
      checklistTest: 'Test Checklisten',
      settings: 'Settings',
      playground: 'Playground',
      playgroundWidgets: 'Playground: Widgets',
      playgroundTree: 'Playground: Tree',
      dashboardAdministration: 'Dashboard-Administration',
      substanceImport: 'Substanceimport'
    }
  },
  quickAccess: {
    subheader: {
      title: 'Quick access'
    },
    itemTitle: {
      email: 'E-Mail',
      report: 'Report',
      accidentReport: 'Accident report'
    }
  },
  filterList: {
    title: {
      search: 'Only search in the columns...',
      filter: 'Filter'
    },
    date: {
      from: 'From',
      to: 'To'
    },
    button: {
      apply: 'Apply'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Password',
      username: 'Username'
    },
    welcomeMessage: {
      text: 'Welcome to eplas!'
    },
    forwardMessage: {
      title: 'Login successful',
      text: 'You will be forwarded immediately...'
    },
    buttonLabel: {
      login: 'Login'
    },
    loginMethod: {
      local: 'Login',
      saml: 'Company Login',
      ldap: 'Windows Login',
      external: 'External Employee',
      pin: 'Login via PIN'
    },
    forgotPassword: {
      button: 'Forgot password?'
    },
    error: {
      noOfflineLogin: 'No login available in offline mode!',
      username: 'Login failed. Check your username and password.',
      usernameValidation: 'Username missing.',
      passwordValidation: 'The password field must not be empty.',
      serverNotAvailable: {
        title: 'Session expired',
        text: 'Your session has expired. Please reload page to log in again',
        button: 'reload page'
      }
    }
  },
  setPasswordContent: {
    password: {
      newPassword: 'New Password',
      passwordConfirmation: 'Repeat password',
      newPasswordConfirmation: 'Repeat new password',
      setPasswordConfirm: 'Set password',
      setPasswordTitle: 'First login: Please change your password',
      success: 'Password changed successfully'
    },
    passwordRules: {
      title: 'Password rules',
      maximumLength: 'Maximum length',
      minimumLength: 'Minimum length',
      minimumDigits: 'Min. amount digits',
      minimumUpperCase: 'Min. amount uppercase letters',
      minimumLowerCase: 'Min. amount lowercase letters',
      minimumSpecialCharacters: 'Min. amount special characters',
      allowedSpecialCharacters: 'Allowed special characters'
    },
    error: {
      general: 'An error occured while changing the password.',
      passwordConfirmationValidation: 'The passwords differ.',
      maximumLength: 'The password is too long.',
      minimumLength: 'The password is too short.',
      minimumDigits: 'The password does not contain enough digits.',
      minimumUpperCase:
        'The password does not contain enough uppercase letters.',
      minimumLowerCase:
        'The password does not contain enough lowercase letters.',
      minimumSpecialCharacters:
        'The password does not contain enough special characters.',
      allowedSpecialCharacters:
        'The password contains forbidden special characters.'
    },
    buttonLabel: {
      login: 'Go to login page'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Forgot password',
      email: 'Email address of your user',
      confirm: 'Send email',
      error: 'The email address is not valid.',
      cancel: 'Cancel',
      hint:
        'Please enter the email address of your user. An email with a link ' +
        'will be sent. With that link you can set a new password. If ' +
        'you do not know your email address or if your user has no email ' +
        'address, contact you system administrator.',
      emailSent: 'An email was sent to the given email address.'
    }
  },
  passwordReset: {
    title: 'Please change your password'
  },
  passwordChange: {
    title: 'Change password',
    hint: 'Please choose a new password',
    currentPassword: 'Current password',
    error: {
      requiredField:
        'The "@:passwordChange.currentPassword" field must not be empty.'
    }
  },
  auditList: {
    column: {
      due: 'Due date',
      name: 'Name',
      category: 'Category',
      responsible: 'Responsible',
      executor: 'Executor',
      affected: 'Affected',
      creator: 'Creator',
      actions: 'Actions',
      progress: 'Progress'
    },
    filter: {
      actionTrue: 'Open Actions',
      doneTrue: 'Done',
      doneFalse: 'Undone'
    },
    convert: {
      title: 'Convert all offline audits',
      start:
        'Do you really want to convert all offline audits to online audits? Converted audits are no longer available offline.',
      working: '{count} of {total} offline audits are converted…',
      success: 'All offline audits have been successfully converted.',
      warning: 'Not all offline audits could be converted.',
      failed: 'Conversion of all offline audits failed.',
      convertButton: 'Convert'
    }
  },
  auditView: {
    toolbarTitle: {
      title: '{prefix} {label} {suffix}'
    },
    convert: {
      title: 'Convert offline audit to an online audit',
      start:
        'Do you really want to convert offline audit to online audit? This will delete the offline audit.',
      working: 'Convert offline audit…',
      completed:
        'The offline audit was successfully converted to an online audit.',
      failed: 'The offline audit could not be converted to an online audit.',
      showAuditListOffline: 'show offline audit list',
      convertAuditButton: 'convert offline audit',
      showAuditOnline: 'show online audit'
    },
    remove: {
      title: 'Delete offline audit',
      text: "Do you really want to delete this offline audit? It can't be recovered.",
      continue: 'Continue'
    },
    error: {
      notFound: 'Audit not found.',
      missingCollectionID: 'Checklist not found.',
      createdFailed: 'Failed to create new audit.'
    },
    notification: {
      error: {
        convertOfflineAudit:
          "Offline audit can't be transferred in offline mode.",
        offlineAuditDeleteFailed: "Offline audit couldn't be deleted."
      }
    }
  },
  employeePicker: {
    title: {
      overview: 'Select employee',
      filter: '@:employeePicker.title.overview - Filter',
      tree: '@:employeePicker.title.filter'
    },
    buttonLabel: {
      select: 'select employee | select employee | select employees ({count})',
      selected: {
        single:
          "no employee selected | employee '{entry}' selected | employee '{entry}' selected",
        multiple:
          "no employees selected | employee '{entry}' selected | {count} employees selected"
      }
    },
    buttonTooltip: {
      remove: 'Cancel the selection of the employees.'
    }
  },
  itemPicker: {
    buttonLabel: {
      default:
        'no element selected | one element selected | {count} elements selected',
      locations:
        'no location selected | one location selected | {count} locations selected',
      properties:
        'no property selected | one property selected | {count} properties selected',
      departments:
        'no department selected | one department selected | {count} departments selected'
    },
    footer: {
      counter:
        'no element selected | one element selected | {count} elements selected'
    }
  },
  dashboardRoles: {
    remove: {
      title: 'Remove dashboard permanently',
      text: "Confirm removal - Are you sure that you want to delete the '{role}' dashboard? All widgets of the dashboard will be removed and the assigned employees will lose their access.",
      confirm: '@:system.general.remove',
      cancel: '@:system.general.cancel'
    },
    create: {
      title: 'Pick a role',
      text: 'Please pick a role property for the new dashboard.',
      confirm: '@:system.general.close'
    },
    buttonTooltip: {
      add: 'Add dashboard',
      edit: 'Edit dashboard',
      remove: 'Delete dashboard'
    },
    notification: {
      add: {
        success: 'New dashboard created.',
        error: 'Failed to create a new dashboard.'
      },
      remove: {
        error: 'An error occured while removing the dashboard.',
        success: 'Successfully removed.'
      }
    }
  },
  router: {
    tab: {
      title: ' - eplas NXT'
    },
    title: {
      login: 'Login',
      audit: 'Audit',
      audits: 'Audits',
      playgroundTree: 'Playground: Tree',
      playgroundWidgets: 'Playground: Widgets',
      playground: 'Playground',
      debugConnectivity: 'Debug: Connectivity',
      settings: 'Settings',
      dashboard: 'Dashboard',
      dashboardRoles: 'Dashboard-Administration',
      editDashboard: 'Edit Dashboard',
      passwordReset: 'Change password',
      substanceImport: 'Substanceimport'
    }
  },
  addDashboardElement: {
    buttonLabel: {
      addWidget: 'Add widget',
      addRow: 'Add row',
      removeRow: 'Remove row'
    },
    notification: {
      addRow: {
        success: 'New row added.',
        error: 'Failed to add a new row.'
      },
      removeRow: {
        success: 'Row deleted successfully.',
        error: 'An error occured while deleting this row.'
      }
    }
  },
  autocompleteSearchField: {
    noData: {
      text: 'No results found'
    }
  },
  externalLogin: {
    rule: {
      required: 'Please fill in the required fields.',
      email: 'The email address is invalid.',
      number: 'This field has to contain a number.'
    },
    buttonLabel: {
      check: 'Check',
      proceed: 'Proceed',
      cancel: '@:system.general.cancel',
      ok: '@:system.general.ok',
      login: 'Login',
      back: '@:system.general.back'
    },
    title: {
      additionalInfo: 'Additional Info'
    },
    field: {
      lastName: 'Last Name',
      firstName: 'First Name',
      company: 'Company',
      country: 'Country of origin',
      visitReason: 'Visit Reason',
      email: 'Email',
      id: 'Identification Number',
      street: 'Street / Number',
      zip: 'Zip Code',
      location: 'Location',
      phone: 'Phone',
      birthdate: 'Birth Date',
      training: 'Educational Training',
      salutation: 'Salutation',
      gender: 'Gender',
      titel: 'Title',
      prefixName: 'Name prefix',
      suffixName: 'Name suffix',
      city: 'City',
      workingTime: 'Working time',
      workingTasks: 'Working tasks',
      citizenship: 'Citizenship',
      healthInsurance: 'Insurance',
      healthInsuranceName: '@:externalLogin.field.healthInsurance: Name',
      healthInsuranceZip:
        '@:externalLogin.field.healthInsurance: @:externalLogin.field.zip',
      healthInsuranceCity:
        '@:externalLogin.field.healthInsurance: @:externalLogin.field.city',
      healthInsuranceStreet:
        '@:externalLogin.field.healthInsurance: @:externalLogin.field.street',
      birthplace: 'Birthplace',
      letterSalutation: 'Letter salutation',
      adressAddition: 'Adress additional',
      hireDate: 'Hire date',
      leavingDate: 'Leaving date',
      fax: 'Fax',
      factory: 'Factory'
    },
    gender: {
      notSpecified: 'not specified',
      female: 'female',
      male: 'male',
      divers: 'diverse'
    },
    salutation: {
      notSpecified: '-',
      female: 'Ms.',
      male: 'Mr.'
    },
    factory: {
      notSpecified: 'no specific factory'
    },
    error: {
      maxLength: 'Maximum length has been exceeded.',
      noVisitReason:
        'No visit reasons available. Please choose another language.'
    }
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Update data',
      gotoLogin: '@:setPasswordContent.buttonLabel.login'
    },
    text: {
      welcome: 'Welcome to eplas!',
      employeeInfos:
        'Please fill in the informations below and check it for accuracy.',
      password: 'Please choose a password',
      newUsername: 'Your new login name is',
      pleaseLogin: 'Please login with your new credentials'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'mobile internal',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    },
    error: {
      general: '@:setPasswordContent.error.general',
      passwordConfirmationValidation:
        '@:setPasswordContent.error.passwordConfirmationValidation',
      maximumLength: '@:setPasswordContent.error.maximumLength',
      minimumLength: '@:setPasswordContent.error.minimumLength',
      minimumDigits: '@:setPasswordContent.error.minimumDigits',
      minimumUpperCase: '@:setPasswordContent.error.minimumUpperCase',
      minimumLowerCase: '@:setPasswordContent.error.minimumLowerCase',
      minimumSpecialCharacters:
        '@:setPasswordContent.error.minimumSpecialCharacters',
      allowedSpecialCharacters:
        '@:setPasswordContent.error.allowedSpecialCharacters'
    }
  },
  error: {
    0x0000: {
      title: 'Error',
      text: 'Generic error: {message}',
      listItem: 'Item {item}'
    },
    0x0001: {
      title: 'Error validating input',
      text: 'Please enter the data correctly in the corresponding field.'
    },
    0x0002: {
      title: 'Returning external employee',
      text: 'Returning external employees are not allowed.'
    },
    0x0003: {
      title: 'Server cannot be reached',
      text: 'Server is currently unavailable. Please try again later. Details: {details}'
    },
    0x0004: {
      title: 'No API secret available',
      text: 'API secret for communication with server unavailable.'
    },
    0x0005: {
      title: 'No base url available',
      text: 'Frontend base URL unavailable.'
    },
    0x0006: {
      title: 'Login problem',
      text: 'Your eplas registration failed. Please get in touch with your contact person.'
    },
    0x0007: {
      title: 'Your token is invalid',
      text: 'Your Token is invalid or expired. Please request a new Link via the "Forgot Password" function and change your password immediately.'
    },
    0x000a: {
      title: 'No details given.',
      text: 'Cannot update dataset with empty details.'
    },
    0x000b: {
      title: 'Updating information failed.',
      text: 'Update failed with the given information.'
    },
    0x000f: {
      title: 'Statement not found',
      text: 'Requested statement "{statement}" could not be found.'
    }
  },
  statementCenter: {
    tabs: {
      imprint: '@:aboutSection.statements.imprintLabel',
      privacy: '@:aboutSection.statements.privacyLabel'
    }
  },
  languagePicker: {
    list: {
      title: 'Change language'
    }
  },
  userMenu: {
    info: {
      loggedInAs: 'Logged in as'
    },
    darkMode: {
      title: 'Dark Mode',
      subtitle: 'Switches between light and dark mode'
    },
    changePassword: {
      title: 'Change password',
      subtitle: 'Change your password'
    },
    logout: {
      title: 'Logout',
      subtitle: 'Ends the current session'
    },
    logoutAll: {
      title: 'Logout from all devices',
      subtitle: 'Ends all your active sessions'
    }
  },
  iconPicker: {
    dialog: {
      title: 'Select icon',
      reset: 'Reset icon selection',
      close: '@:system.general.close'
    },
    button: {
      selectText: 'Select icon'
    },
    search: {
      label: '@:system.general.search'
    },
    error: {
      noIconsFound: 'No icons found.'
    }
  }
}
