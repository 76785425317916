
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const UIStore = namespace('ui')

/**
 * Suchfeld für die globale Suche.
 */
@Component({
  components: {}
})
export default class GlobalSearchField extends Vue {
  /**
   * Globaler Suchstring.
   */
  @UIStore.State('globalSearch')
  public globalSearch!: string

  /**
   * Setzt den globalen Suchstring.
   */
  @UIStore.Action('setGlobalSearchTerm')
  public setGlobalSearchTerm!: Promise<void>

  /**
   * Öffnet oder schließt die globalen Filter.
   */
  @UIStore.Action('toggleGlobalFilter')
  public toggleGlobalFilter!: Promise<void>

  /**
   * Ob globale Filter vom Benutzer verwendet wird.
   */
  @UIStore.Getter('hasFilters')
  public hasFilters!: boolean

  /**
   * Gibt die CSS-Klassen für das Textfeld zurück. Diese werden anhand der
   * aktuell verwendeten Breakpoints bestimmt.
   *
   * @returns CSS-Klassen
   */
  public get cssClasses(): string {
    return (
      'global-search-field__v-text-field ' +
      (this.$vuetify.breakpoint.smAndDown ? 'ml-3 mr-2' : 'ml-1 mr-5')
    )
  }
}
