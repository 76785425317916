import { languageList } from '@/constants/language-list'
import { LanguageState } from './language-state.interface'
import { CountryCode } from '@/constants/country-code.enum'

export const state: LanguageState = {
  active: [...languageList],
  default: CountryCode.DE,
  fallback: CountryCode.EN,
  overriddenByUser: false
}
