import { config } from '@/config'
import { ConnectivityConfiguration } from './connectivity-configuration.interface'
import { AnalyzerFunction } from './analyzer-function.type'
import { Analyzer } from './analyzer.interface'
import store from '@/store'

/**
 * Stellt anhand verschiedener Kriterien fest, ob der Client eine Verbindung zum
 * Server herstellen kann bzw. online ist. Die Prüfung wird anhand eines
 * festgelegten Intervalls ständig wiederholt. Konfigurierbare Schwellenwerte
 * sorgen dafür, dass der Übergang zwischen online und offline nicht "hart" ist,
 * sondern bei fluktuierender Verbindungsqualität eine konsistente trotzdem
 * Benutzererfahrung gewährleistet werden kann.
 */
export class ConnectivityDetector {
  /**
   * Aktueller Tick/Anzahl der bereits ausgeführten Prüfungen seit dem Start der
   * Anwendung.
   */
  private _tick = 0

  /**
   * Über den Constructor übergebene Konfigurationswerte für die
   * Verbindungserkennung.
   */
  private _configuration: ConnectivityConfiguration

  /**
   * Verbindungswert. Achtung Rohwert, stattdessen Getter und Setter [[_value]]
   * verwenden!
   */
  private _valueRaw = 0

  /**
   * Historie der Verbindungssumme der letzten Ticks. Die maximale Länge kann
   * über [[ConnectivityConfiguration.historyLength]] festgelegt werden.
   */
  private _sumHistory: number[] = []

  /**
   * Gibt an, ob der Client aktuell als online gewertet wird oder nicht.
   */
  private _online = false

  /**
   * Hochzählende, eindeutige ID für registrierte [[Analyzer]].
   */
  private _nextAnalyzerID = 0

  /**
   * Enthält alle zurzeit registrierten [[Analyzer]].
   */
  private _analyzers: Analyzer[] = []

  /**
   * Verbindungswert, der sich aus den unterschiedlichen Bewertungskriterien
   * über die letzten Ticks ergibt.
   *
   * @returns Verbindungswert.
   */
  private get _value(): number {
    return this._valueRaw
  }

  /**
   * Setzt den Verbindungswert unter Beachtung von
   * [[ConnectivityConfiguration.upperLimit]] und
   * [[ConnectivityConfiguration.lowerLimit]].
   *
   * @param value - Neuer Verbindungswert.
   */
  private set _value(value: number) {
    if (value > this._configuration.upperLimit) {
      this._valueRaw = this._configuration.upperLimit
    } else if (value < this._configuration.lowerLimit) {
      this._valueRaw = this._configuration.lowerLimit
    } else {
      this._valueRaw = value
    }
  }

  /**
   * ID des aktuellen Timeouts für den nächsten Heartbeat. Wird unter anderem
   * dazu verwendet, den Timeout bei Webpack HMR (Hot Module Replacement)
   * abzubrechen.
   */
  private _timeout?: number

  /**
   * Gibt an, ob die Instanz zerstört bzw. disposed wurde.
   */
  private _destroyed = false

  /**
   * Summe des Verbindungswerts mit zusätzlichen Kriterien, wie bspw.
   * `navigator.onLine`. Finaler Wert um zu bestimmen, ob der Client als online
   * oder offline gewertet wird. Ignoriert
   * [[ConnectivityConfiguration.upperLimit]] und
   * [[ConnectivityConfiguration.lowerLimit]].
   *
   * @returns Berechneter Verbindungswert mit allen aktivierten Kriterien.
   */
  private get _sum(): number {
    if (!this._configuration.enableNavigatorOnline) {
      return this._value
    }

    return (
      this._value +
      (navigator.onLine
        ? this._configuration.navigatorOnlineModificator
        : this._configuration.navigatorOfflineModificator)
    )
  }

  /**
   * Sendet einen AJAX-Aufruf an den Heartbeat-Endpunkt des konfigurierten API-
   * Servers. Fehler werden abgefangen und entsprechend mit `false` als
   * Rückgabewert quittiert.
   *
   * @returns Bei Erfolg `true`, ansonsten `false`.
   */
  private async _heartbeat(): Promise<boolean> {
    const { url } = (await config.client()).api

    try {
      const response = await fetch(`${url}/heartbeat`)
      const result = await response.json()

      return result.alive
    } catch (ex) {
      return false
    }
  }

  /**
   * Legt anhand der übergebenen Argumente fest, wie der Heartbeat gewertet
   * wird. Dabei wird zwischen erfolgreichem Heartbeat mit je niedriger und
   * hoher Latenz sowie fehlgeschlagenem Heartbeat unterschieden. Die Werte
   * können werden mit [[ConnectivityConfiguration.heartbeatFailModificator]],
   * [[ConnectivityConfiguration.heartbeatHighModificator]] und
   * [[ConnectivityConfiguration.heartbeatLowModificator]] festgelegt werden.
   *
   * @param success - Angabe, ob die Anfrage erfolgreich ausgeführt wurde.
   * @param time - Dauer der Anfrage.
   * @returns Modifikationswert.
   */
  private _getHeartbeatModificatorValue(
    success: boolean,
    time: number
  ): number {
    if (!success) {
      return this._configuration.heartbeatFailModificator
    }

    if (time > this._configuration.heartbeatHighThreshold) {
      return this._configuration.heartbeatHighModificator
    }

    return this._configuration.heartbeatLowModificator
  }

  /**
   * Schaltet zwischen "online" und "offline" um, wenn die entsprechenden
   * Schwellenwerte [[ConnectivityConfiguration.onlineThreshold]] bzw.
   * [[ConnectivityConfiguration.offlineThreshold]] erreicht sind.
   */
  private _thresholdOnlineToggle(): void {
    if (this._sum >= this._configuration.onlineThreshold) {
      this._online = true
    } else if (this._sum <= this._configuration.offlineThreshold) {
      this._online = false
    }
  }

  /**
   * Schreibt den Online-Status in den Vuex-Store.
   */
  private _dispatchToVuex(): void {
    if (this._online) {
      store.dispatch('connectivity/setOnline')
    } else {
      store.dispatch('connectivity/setOffline')
    }
  }

  /**
   * Loop-Funktion, die wiederholt die Verbindung überprüft und die ermittelten
   * Werte entsprechend aktualisiert. Ruft sich selbst anhand des festgelegten
   * Intervalls in [[ConnectivityConfiguration.interval]] erneut auf.
   */
  private async _loop(): Promise<void> {
    ++this._tick

    if (this._configuration.enableHeartbeat) {
      const start = performance.now()
      const success = await this._heartbeat()
      const end = performance.now() - start

      const modificator = this._getHeartbeatModificatorValue(success, end)

      this._value += modificator
    }

    this._sumHistory.push(this._sum)

    while (this._sumHistory.length > this._configuration.historyLength) {
      this._sumHistory.shift()
    }

    this._thresholdOnlineToggle()

    for (const analyzer of this._analyzers) {
      analyzer.fn({
        configuration: this._configuration,
        value: this._value,
        sum: this._sum,
        sumHistory: this._sumHistory,
        tick: this._tick,
        online: this._online
      })
    }

    if (this._configuration.writeToVuex) {
      this._dispatchToVuex()
    }

    if (!this._destroyed) {
      this._timeout = window.setTimeout((): void => {
        this._loop()
      }, this._configuration.interval)
    }
  }

  /**
   * Stellt anhand verschiedener Kriterien fest, ob der Client eine Verbindung
   * zum Server herstellen kann bzw. online ist. Die Prüfung wird anhand eines
   * festgelegten Intervalls ständig wiederholt. Konfigurierbare Schwellenwerte
   * sorgen dafür, dass der Übergang zwischen online und offline nicht "hart"
   * ist, sondern bei fluktuierender Verbindungsqualität eine konsistente
   * trotzdem Benutzererfahrung gewährleistet werden kann.
   *
   * @param configuration - Zu verwendende Konfiguration.
   */
  public constructor(configuration: ConnectivityConfiguration) {
    this._configuration = configuration

    if (module.hot) {
      module.hot.dispose((): void => {
        this.dispose()
      })
    }

    this._loop()
  }

  /**
   * Muss aufgerufen werden, wenn diese Instanz der Klasse nicht mehr verwendet
   * werden soll. Stoppt den Loop.
   */
  public dispose(): void {
    window.clearTimeout(this._timeout)
    this._destroyed = true
  }

  /**
   * Registriert eine [[AnalyzerFunction]]. Alle registrierten Funktionen werden
   * bei jedem Tick aufgerufen.
   *
   * @param fn - Zu registrierende [[AnalyzerFunction]].
   * @returns ID der registrierten Funktion. Die ID kann verwendet werden, die
   * [[AnalyzerFunction]] aus den registrierten Funktionen zu entfernen (siehe
   * [[unregisterAnalyzer]]).
   */
  public registerAnalyzer(fn: AnalyzerFunction): number {
    const id = ++this._nextAnalyzerID

    this._analyzers.push({ id, fn })

    return id
  }

  /**
   * Entfernt eine registrierte [[AnalyzerFunction]].
   *
   * @param id - ID der zu entfernenden [[AnalyzerFunction]].
   */
  public unregisterAnalyzer(id: number): void {
    const analyzer = this._analyzers.find((x): boolean => x.id === id)

    if (!analyzer) {
      return
    }

    this._analyzers.slice(this._analyzers.indexOf(analyzer), 1)
  }
}
