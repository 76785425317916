export const fr = {
  externalLogin: {
    rule: {
      required: 'Veuillez renseigner tous les champs obligatoires.',
      email: `L'adresse e-mail n'est pas valide.`,
      number: 'Ce champ doit contenir un numéro.'
    },
    buttonLabel: {
      check: 'Vérification',
      proceed: 'Suivant',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Connexion',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Informations complémentaires'
    },
    field: {
      lastName: 'Nom de famille',
      firstName: 'Prénom',
      company: 'Société',
      country: 'Pays d’origine :',
      visitReason: 'Motif de la visite',
      email: 'E-mail',
      id: 'N° de document',
      street: 'Rue/ n°',
      zip: 'Code postal',
      location: 'Lieu',
      phone: 'Téléphone',
      birthdate: 'Date de naissance',
      training: 'Formation',
      salutation: 'Civilité',
      gender: 'Sexe',
      titel: 'Titre',
      prefixName: 'Préfixe',
      suffixName: 'Mot supplémentaire',
      city: 'Ville',
      workingTime: 'Temps de travail',
      workingTasks: 'Employé comme',
      citizenship: 'Nationalité',
      healthInsurance: 'Caisse d’assurance maladie',
      healthInsuranceName: 'Nom de la caisse d’assurance maladie',
      healthInsuranceZip: 'Caisse d’assurance maladie : code postal',
      healthInsuranceCity: 'Caisse d’assurance maladie : ville',
      healthInsuranceStreet: 'Caisse d’assurance maladie : rue',
      birthplace: 'Lieu de naissance',
      letterSalutation: 'Civilité',
      adressAddition: 'Complément d’adresse',
      hireDate: `Date d'embauche`,
      leavingDate: 'Date de sortie',
      fax: 'Fax'
    },
    gender: {
      notSpecified: 'Aucune indication',
      femail: 'féminin',
      male: 'masculin',
      divers: 'divers'
    },
    salutation: {
      notSpecified: '-',
      female: 'Madame',
      male: 'Monsieur'
    },
    error: {
      maxLength: 'La longueur maximale a été dépassée.',
      noVisitReason: `Il n'y a pas de motifs de visite disponibles dans cette langue. Veuillez choisir une autre langue.`
    }
  },
  error: {
    0x0000: {
      title: 'Erreur',
      text: 'Erreur générale: {message}'
    },
    0x0001: {
      title: 'Erreur lors de la validation de la saisie.',
      text: 'Veuillez saisir correctement les données dans le champ correspondant.'
    },
    0x0002: {
      title: 'Collaborateur externe récurrent',
      text: 'Les collaborateurs externes récurrents ne sont pas autorisés.'
    },
    0x0003: {
      title: 'Serveur non accessible',
      text: `Le serveur n'est pas accessible pour le moment. Veuillez réessayer plus tard. Détails : {details}`
    },
    0x0004: {
      title: 'Pas de secret API disponible',
      text: `Le secret API pour communiquer avec le serveur n'est pas disponible.`
    },
    0x0005: {
      title: 'Aucune URL de base disponible',
      text: `L'URL de base du front-end eplas n'est pas disponible.`
    },
    0x0006: {
      title: 'Problème lors de la connexion',
      text: `Votre enregistrement dans eplas n'a pas pu être effectué. Veuillez vous adresser personnellement à votre interlocuteur dans l'entreprise.`
    },
    0x0007: {
      title: `Votre jeton n'est pas valide`,
      text: `Votre jeton n'est pas valide ou il est expiré.`
    }
  },
  loginMask: {
    loginInput: {
      password: 'Mot de passe',
      username: 'Nom d’utilisateur'
    },
    welcomeMessage: {
      text: 'Bienvenue chez eplas!'
    },
    forwardMessage: {
      title: 'Connexion réussie!',
      text: 'La redirection a bientôt lieu…'
    },
    buttonLabel: {
      login: 'Connexion'
    },
    loginMethod: {
      local: 'Connexion',
      saml: 'Connexion entreprise',
      ldap: 'Connexion Windows',
      external: 'Collaborateur externe',
      pin: 'Saisie du PIN'
    },
    forgotPassword: {
      button: 'Mot de passe oublié?'
    },
    error: {
      noOfflineLogin: 'En mode offline, il est impossible de se connecter !',
      username: `La connexion a échoué. Contrôlez votre nom d'utilisateur et votre mot de passe.`,
      usernameValidation: 'Nom d’utilisateur requis.',
      passwordValidation: 'Mot de passe requis.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Répéter le mot de passe',
      setPasswordConfirm: 'Créer un mot de passe',
      setPasswordTitle:
        'Première connexion : veuillez modifier votre mot de passe',
      success: 'Mot de passe modifié avec succès'
    },
    passwordRules: {
      title: 'Directives relatives au mot de passe',
      maximumLength: 'Le mot de passe est trop long.',
      minimumLength: 'Le mot de passe est trop court.',
      minimumDigits: 'Le mot de passe ne contient pas assez de chiffres.',
      minimumUpperCase:
        'Le mot de passe ne contient pas assez de lettres majuscules.',
      minimumLowerCase:
        'Le mot de passe ne contient pas assez de lettres minuscules.',
      minimumSpecialCharacters:
        'Le mot de passe ne contient pas de caractères spéciaux.',
      allowedSpecialCharacters:
        'Le mot de passe contient des caractères spéciaux non autorisés.',
      general:
        'Une erreur est survenue lors de la modification du mot de passe.',
      passwordConfirmationValidation:
        'Les deux mots de passe ne correspondent pas.'
    },
    buttonLabel: {
      login: 'Vers la page de connexion'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Mot de passe oublié?',
      email: 'Adresse e-mail de votre utilisateur',
      confirm: 'Envoyer un e-mail',
      error: `L'adresse e-mail n'est pas valide.`,
      cancel: 'Annuler',
      hint: `Veuillez saisir l'adresse e-mail de votre utilisateur. Un e-mail contenant un lien vous sera alors envoyé. Ce lien vous permettra de choisir un nouveau mot de passe. Si vous ne connaissez pas votre adresse e-mail ou si votre utilisateur n'a pas enregistré d'adresse e-mail, veuillez vous adresser à l'administrateur compétent.`,
      emailSent: 'L’e-mail a été envoyé.'
    }
  },
  passwordReset: {
    title: 'Veuillez modifier votre mot de passe'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Actualiser les données'
    },
    text: {
      welcome: 'Bienvenue dans eplas.',
      employeeInfos:
        'Veuillez renseigner les données indiquées ci-dessous et vérifier leur exactitude.',
      password: 'Veuillez choisir un mot de passe'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'mobile interne',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Vous êtes en ligne maintenant.',
        offline: 'Vous êtes hors ligne maintenant.'
      },
      languageOverridden: `La langue que vous avez sélectionnée précédemment n'est pas disponible sur ce système. La langue a été définie par défaut.`
    },
    general: {
      print: 'Imprimer',
      search: 'Rechercher',
      close: 'Fermer',
      create: 'Créer',
      edit: 'Modifier',
      copy: 'Copier',
      back: 'Retour',
      remove: 'Éliminer',
      abort: 'Ignorer',
      finished: 'Terminé',
      ok: 'Ok',
      cancel: 'Annuler'
    }
  },
  router: {
    title: {
      login: 'Connexion'
    }
  }
}
