import { IsUrl } from 'class-validator'

/**
 * Einstellungen zur API-/Backend-Verbindung
 */
export class API {
  /**
   * Basis-URL der API.
   */
  @IsUrl({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    require_tld: false,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    require_host: true,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    require_protocol: true
  })
  public url!: string
}
