import { HooksObject } from '@feathersjs/feathers'
import { convertValue } from '../../hooks/obsolete-checklists/cells/values/convert-value.hook'
import { convertResult } from '../../hooks/obsolete-checklists/cells/values/convert-result.hook'
import { notImplemented } from '../../hooks/not-implemented.hook'

/**
 * Hooks für die Checklist-Zelle
 */
export const CellValuesHooks: HooksObject = {
  before: {
    all: [],
    find: [],
    get: [],
    create: [convertValue],
    update: [convertValue],
    patch: [notImplemented],
    remove: []
  },

  after: {
    all: [],
    find: [convertResult],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [],
    find: [convertResult],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
}
