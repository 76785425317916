import { HookContext, Params, Paginated } from '@feathersjs/feathers'
import { notImplemented } from './not-implemented.hook'

/**
 * Dieser Hook wandelt die Abfrage des Mitarbeiter-Pickers in eine Abfrage für
 * den Offline-Service 'offline-data/employees' um und gibt dessen Antwort
 * entsprechend zurück.
 *
 * Der Hook unterstützt nur die Methoden FIND und GET vom Service
 * 'picker-employees'. Ansonsten wird wird eine `NotImplemented`-Exception
 * getriggert.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers.
 * @returns Hook-Kontext, mit gesetzten Ergebnis.
 */
export async function employeePicker(
  context: HookContext
): Promise<HookContext> {
  if (context.path === 'picker-employees' && context.type === 'before') {
    if (context.method !== 'find') {
      if (context.method === 'get') {
        context.result = await context.app
          .service('offline-data/employees')
          .get(context.id, context.params)
      } else {
        notImplemented(context)
      }
    } else if (context.params) {
      const orig = context.params.query || {}

      const search = typeof orig.$search === 'string' ? orig.$search.trim() : ''
      const query: Params = {
        $skip: orig.$skip || orig.$offset || 0,
        $limit: orig.$limit || 25,
        $sort: {
          [orig.$orderBy || 'username']:
            orig.$orderDirection !== 'desc' ? 1 : -1
        }
      }

      if (search !== '') {
        /**
         * Bildet die Online-Suche vom Mitarbeiter-Picker nach.
         *
         * @returns `true` wenn der Suchstring gefunden wurde, sonst `false`.
         */
        query.$where = function (): boolean {
          return (
            (typeof this.personnelNumber === 'string' &&
              this.personnelNumber.indexOf(search) !== -1) ||
            (typeof this.username === 'string' &&
              this.username.indexOf(search) !== -1) ||
            (typeof this.lastName === 'string' &&
              this.lastName.indexOf(search) !== -1) ||
            (typeof this.firstName === 'string' &&
              this.firstName.indexOf(search) !== -1) ||
            (typeof this.email === 'string' &&
              this.email.indexOf(search) !== -1)
          )
        }
      }

      context.result = await context.app
        .service('offline-data/employees')
        .find({ query: query })
    } else {
      const obj: Paginated<unknown> = { skip: 0, limit: 25, data: [], total: 0 }
      context.result = obj
    }
  }

  return context
}
