
import { Mixins, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Dark from '@/mixins/dark'
import { UITheme } from '@/store/ui/theme.interface'

/**
 * Bereich mit Logo (Standardmäßig eplas-logo, später evtl. custom Logo)
 */
@Component({})
export default class LogoCard extends Mixins(Dark) {
  /**
   * eplas-Logo.
   */
  public defaultLogo: string = require('@/assets/logo_monochrome.svg')

  /**
   * Einstellungen vom Theme, im UI Store
   */
  @Getter('ui/theme')
  public uiStoreTheme!: UITheme

  /**
   * Gibt das Logo zurück
   *
   * @returns Pfad zum Logo
   */
  public get logo(): string {
    const config = this.dark ? this.uiStoreTheme.dark : this.uiStoreTheme.light

    if (config.customLogo) {
      const logo = config.customLogo.replace(/[\\/]/g, '_')
      return `${process.env.BASE_URL || '/'}custom/${logo}`
    }

    return this.defaultLogo
  }
}
