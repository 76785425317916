import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { loadThemeData } from '@/helpers/load-theme-data'

Vue.use(Vuetify)

/**
 * Type von der Vuetify-Instance.
 */
export type TypeVuetify = Vuetify

/**
 * Erstellte Vuetify-Instance mit den Theme-Farben vom Server.
 */
let vuetify: Vuetify

/**
 * Erzeugt, wenn es noch keine Instance gibt, eine neue Vuetify-Instance. Dafür
 * werden vom Server die Farben für das Light- und Dark-Theme geladen. Danach
 * wird eine neue Vuetify-Instance mit diesen Farben für die Themes erstellt.
 *
 * @returns Vuetify-Instance
 */
export default async function getVuetify(): Promise<Vuetify> {
  if (vuetify) {
    return vuetify
  }

  const themesData = await loadThemeData()

  vuetify = new Vuetify({
    theme: {
      themes: themesData
    },
    icons: {
      iconfont: 'mdi'
    }
  })

  return vuetify
}
