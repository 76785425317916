import { Module } from 'vuex'
import { ConnectivityState } from './connectivity-state.interface'
import { RootState } from '../root-state.interface'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export const connectivity: Module<ConnectivityState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
