import { MutationTree } from 'vuex'
import { NotificationState } from './notification-state.interface'
import { NotificationPayload } from './notification-payload.interface'

export const mutations: MutationTree<NotificationState> = {
  /**
   * Aktiviert einen Toaster.
   *
   * @param state - State des Notifications-Moduls.
   * @param payload - Optionen für die Toaster - PayloadInterface
   */
  enableToast(state: NotificationState, payload: NotificationPayload): void {
    state.lock = true
    state.showNotification = true
    state.btnReturn = false
    state.type = payload.type
    if (payload.text) {
      state.text = payload.text
    } else {
      state.text = ' '
    }
    if (payload.btnText || payload.btnText === '') {
      state.btnText = payload.btnText
    } else {
      state.btnText = 'Schließen'
    }
    if (payload.timeout) {
      state.timeout = payload.timeout
    } else {
      state.timeout = 2000
    }
    if (payload.data) {
      state.data = payload.data
    } else {
      state.data = {}
    }
    if (payload.callback) {
      state.callback = payload.callback
    } else {
      state.callback = (): void => {}
    }

    state.icon = payload.icon || ''

    setTimeout((): void => {
      state.showNotification = false
      state.lock = false
    }, payload.timeout)
  },

  /**
   * Deaktiviert den Toaster.
   *
   * @param state - State des Notifications-Moduls.
   */
  disableToast(state: NotificationState): void {
    state.showNotification = false
    state.btnReturn = true
  },

  /**
   * Fügt die Payload für einen Toaster zur Warteschlange hinzu.
   *
   * @param state - State des Notifications-Moduls.
   * @param payload -Payload, die zur Warteschlange hinzugefügt werden soll
   */
  enqueue(state: NotificationState, payload: NotificationPayload): void {
    const queue = state.queue
    queue.push(payload)
    state.queue = queue
  },
  /**
   * Entfernt das erste Element aus der Warteschlange
   *
   * @param state - State des Notifications-Moduls.
   */
  dequeue(state): void {
    state.queue.shift()
  }
}
