import { Id } from '@feathersjs/feathers'
import { Methods } from './methods.enum'
import { ConfigContainer } from './config-container.interface'

/**
 * Alle Einstellungen für das opportunistische Aktualisieren. Nicht enthaltene
 * und enthaltene Offline-Services erben  automatisch die Standardeinstellungen.
 * Entsprechend müssen nur Sonderfälle hier eingetragen werden.
 */
export const defaultConfig: ConfigContainer = {
  _default: {
    completed: true,
    enable: true,
    methods: [
      Methods.Find,
      Methods.Get,
      Methods.Create,
      Methods.Update,
      Methods.Patch,
      Methods.Remove
    ],
    getPrimaryKey: (e): Id => ((e || {}).id || 0) as Id
  },
  auth: {
    completed: true,
    enable: true,
    methods: [Methods.Get, Methods.Create],
    getPrimaryKey: (e): Id => ((e || {}).accessToken || 0) as Id
  },
  'offline-data/employees': {
    enable: false
  },
  'offline-data/checklist-categories': {
    enable: false
  },
  'picker-employees': {
    enable: false
  }
}
