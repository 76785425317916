import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import { Legacy, ALL } from '@nxt/permissions'
import { PermissionHelper } from '@/helpers/permission-helper'
import { onReady } from '@/helpers/feathers'
import { i18n } from './plugins/i18n'
import { getDashboardID } from '@/helpers/dashboard'
import { isUserLoggedIn } from '@/helpers/isUserLoggedIn.helper'

Vue.use(Router)

const productionRoutes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      title: 'router.title.login'
    }
  },
  {
    path: '/login/:locale?/:tab?',
    name: 'login',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/Login/Login.vue'),
    meta: {
      title: 'router.title.login',
      hideOverlay: true
    }
  },
  {
    path: '/passwordreset/:locale?/:token',
    name: 'passwordreset',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/PasswordReset/PasswordReset.vue'),
    meta: {
      title: 'router.title.passwordReset',
      hideOverlay: true
    }
  },
  {
    path: '/audit/:content/:id(\\d+|new)/:cid?',
    name: 'audit_view',
    meta: {
      title: 'router.title.audit'
    },
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/AuditView/AuditView.vue'),
    props: (route): object => ({
      usedContent: route.params.content,
      entryID: route.params.id !== 'new' ? parseInt(route.params.id, 10) : 0,
      collectionID: route.params.cid ? parseInt(route.params.cid, 10) : 0
    })
  },
  {
    path: '/audit/:content/',
    name: 'audit_list',
    meta: {
      title: 'router.title.audits'
    },
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/AuditList/AuditList.vue'),
    props: (route): object => ({
      usedContent: route.params.content
    })
  },
  {
    path: '/dashboard/:id',
    name: 'router.title.dashboard',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/Dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard'
    },
    beforeEnter: async (to, from, next) => {
      if (!(await isUserLoggedIn()) && to.name !== 'login') {
        next('/login')
      } else if ((await getDashboardID()) === null) {
        window.location.href = window.location.origin + '/index.php/0/eplas'
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard',
    name: 'router.title.dashboard',
    /**
     * Weiterleitung auf das erste mit dem User verknüpfte Dashboard
     *
     * @param to - Objekt mit Daten der Zielseite
     */
    async beforeEnter(to): Promise<void> {
      if (!(await isUserLoggedIn()) && to.name !== 'login') {
        window.location.href = window.location.origin + `/nxt/login`
      } else {
        const id = await getDashboardID()
        window.location.href = window.location.origin + `/nxt/dashboard/${id}`
      }
    }
  },
  {
    path: '/classicAdmin',
    name: 'classicAdmin',
    /**
     * Route zu der klassischen Administration
     */
    beforeEnter(): void {
      window.location.href =
        window.location.origin + '/index.php/0/eplas/classicAdmin'
    }
  },
  {
    path: '/classicDashboard',
    name: 'classicDashboard',
    /**
     * Route zum klassischen Dashboard
     */
    beforeEnter(): void {
      window.location.href = window.location.origin + '/index.php/0/eplas'
    }
  },
  {
    path: '/reroute/audit/create/:id',
    name: 'rerouteAuditToClassic',
    /**
     * Rerouting zum klassischen eplas um ein Audit anzulegen
     *
     * @param from - Objekt mit Daten der ausgehenden Seite
     */
    beforeEnter(from): void {
      window.location.href =
        window.location.origin + `/index.php/audit/create/${from.params.id}`
    }
  },
  {
    path: '/dashboardRoles',
    name: 'dashboardRoles',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/DashboardRoleList/DashboardRoleList.vue'),
    meta: {
      title: 'router.title.dashboardRoles',
      permission: {
        permission: Legacy.Permission298,
        mask: ALL
      }
    }
  },
  {
    path: '/dashboardRoles/edit/:id(\\d+)',
    name: 'editDashboard',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/DashboardEdit/DashboardEdit.vue'),
    meta: {
      title: 'router.title.editDashboard',
      permission: {
        permission: Legacy.Permission298,
        mask: ALL
      }
    }
  },
  {
    path: '/passwordChange',
    name: 'passwordChange',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/PasswordChange/PasswordChange.vue'),
    meta: {
      title: 'passwordChange.title'
    }
  }
]

const developmentRoutes: RouteConfig[] = [
  {
    path: '/spielwiese',
    name: 'spielwiese',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/Spielwiese/Spielwiese.vue'),
    meta: {
      title: 'router.title.playground'
    }
  },
  {
    path: '/spielwiese_widgets',
    name: 'spielwiese_widgets',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/SpielwieseWidgets/SpielwieseWidgets.vue'),
    meta: {
      title: 'router.title.playgroundWidgets'
    }
  },
  {
    path: '/spielwiese_baum',
    name: 'spielwiese_baum',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/SpielwieseBaum/SpielwieseBaum.vue'),
    meta: {
      title: 'router.title.playgroundTree'
    }
  },
  {
    path: '/spielwiese_list',
    name: 'spielwiese_list',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/SpielwieseList/SpielwieseList.vue'),
    meta: {
      title: 'Spielwiese: Liste'
    }
  },
  {
    path: '/debug/connectivity',
    name: 'debug_connectivity',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/DebugConnectivity/DebugConnectivity.vue'),
    meta: {
      title: 'router.title.debugConnectivity'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/ColorSettings/ColorSettings.vue'),
    meta: {
      title: 'router.title.settings',
      permission: {
        permission: Legacy.Permission1,
        mask: ALL
      }
    }
  },
  {
    path: '/test_checklists',
    name: 'test_checklists',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/TestChecklists/TestChecklists.vue'),
    meta: {
      title: 'Where all the magic happens... or not'
    }
  },
  {
    path: '/substanceimport',
    name: 'substanceimport',
    component: (): Promise<typeof import('*.vue')> =>
      import('./views/Substanceimport/Substanceimport.vue'),
    meta: {
      title: 'router.title.substanceImport'
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...productionRoutes,
    ...(process.env.NODE_ENV === 'development' ? developmentRoutes : [])
  ]
})

// Prüft, ob der Benutzer eingeloggt ist
router.beforeEach(async (to, from, next): Promise<void> => {
  if (to.name !== 'passwordreset') {
    if (!(await isUserLoggedIn()) && to.name !== 'login') {
      router.replace('/login')
    }
  }
  return next()
})

// Aktualisiert den Seitentitel beim Wechsel der Route.
router.beforeEach((to, from, next): void => {
  if (to.meta !== undefined) {
    if (i18n.te(to.meta.title)) {
      document.title = `${i18n.t(to.meta.title)}${i18n.t('router.tab.title')}`
    } else {
      document.title = to.meta.title + i18n.t('router.tab.title')
    }
  }

  return next()
})

// Prüft die Berechtigungen einer Route.
router.beforeEach((to, from, next): void => {
  if (to.meta !== undefined && !to.meta.permission) {
    return next()
  }

  onReady((): void => {
    if (to.meta !== undefined && !to.meta.permission) {
      return next()
    }

    if (
      to.meta !== undefined &&
      !PermissionHelper.hasPermission(to.meta.permission)
    ) {
      router.replace('/')
    }

    return next()
  })
})

router.beforeEach((to, from, next): void => {
  if (localStorage.getItem('changePwFirst') && to.name !== 'login') {
    router.replace('/login')
  }

  return next()
})

export default router
