import { Module } from 'vuex'
import { LanguageState } from './language-state.interface'
import { RootState } from '../root-state.interface'
import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'

export const language: Module<LanguageState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
}
