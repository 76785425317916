
import { Component, Mixins } from 'vue-property-decorator'
import TabbedCard from '@/components/TabbedCard/TabbedCard.vue'
import InfoCenterVersion from '@/components/InfoCenterVersion/InfoCenterVersion.vue'
import InfoCenterChangelog from '@/components/InfoCenterChangelog/InfoCenterChangelog.vue'
import InfoCenterThirdParty from '@/components/InfoCenterThirdParty/InfoCenterThirdParty.vue'
import { EnvironmentMixin } from '@/mixins/environment.mixin'

/**
 * Infobereich der Anwendung mit Versionsinformationen, Changelog und Hinweisen
 * zu verwendeter Drittanbietersoftware.
 */
@Component({
  components: {
    TabbedCard,
    InfoCenterVersion,
    InfoCenterChangelog,
    InfoCenterThirdParty
  }
})
export default class InfoCenter extends Mixins(EnvironmentMixin) {
  /**
   * Tabs für Produktivbetrieb.
   */
  public productionTabs: string[] = [
    'infoCenter.tabs.version',
    'infoCenter.tabs.thirdParty'
  ]

  /**
   * Tabs für Entwicklungsbetrieb.
   */
  public developmentTabs: string[] = ['infoCenter.tabs.changelog']

  /**
   * Berechnete Liste aus [[this.productionTabs]] und [[this.developmentTabs]],
   * abhängig von [[this.isDevelopment]].
   *
   * @returns Liste der Tabs.
   */
  public get tabs(): string[] {
    return [
      ...this.productionTabs,
      ...(this.isDevelopment ? this.developmentTabs : [])
    ]
  }
}
