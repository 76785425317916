import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    /**
     * Loggt, dass die App aus dem Cache eines Service Workers
     * bereitgestellt wird
     */
    ready(): void {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    /**
     * Loggt, dass ein Service Worker registriert wurde
     */
    registered(): void {
      console.log('Service worker has been registered.')
    },
    /**
     * Loggt, dass Content für die offline Benutzung gecached wurde
     */
    cached(): void {
      console.log('Content has been cached for offline use.')
    },
    /**
     * Loggt, dass neuer Content heruntergeladen wird
     */
    updatefound(): void {
      console.log('New content is downloading.')
    },
    /**
     * Loggt, dass neuer Content gefunden wurde
     */
    updated(): void {
      console.log('New content is available; Page refresh!')
      setTimeout((): void => {
        window.location.reload()
      }, 1000)
    },
    /**
     * Loggt, dass keine Internetverbindung besteht
     */
    offline(): void {
      console.log(
        'No internet connection found.App is running in offline mode.'
      )
    },
    /**
     * Loggt, bei der Registrierung eines Serviceworkers
     * ein Fehler entstand
     *
     * @param error - Beschreibung des Fehlers
     */
    error(error): void {
      console.error('Error during service worker registration:', error)
    }
  })
}
