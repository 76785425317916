
import { Component, Vue, Watch } from 'vue-property-decorator'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import { namespace } from 'vuex-class'

const NotificationStore = namespace('notification')

/**
 * Rendert global einen Toaster, der über den Notification-Store angesteuert
 * werden kann
 */
@Component({})
export default class ToasterOutlet extends Vue {
  /**
   * Es gibt einen FAB der beachtet werden muss
   */
  public fabAvailable = false

  /**
   * Zeigt den Toaster bei Bedarf an
   */
  @NotificationStore.State('showNotification')
  public showNotification!: boolean

  /**
   * Text, der im Toaster angezeigt wird
   */
  @NotificationStore.State('text')
  public text!: string

  /**
   * Styleklasse, um das Aussehen zu beeinflussen
   */
  @NotificationStore.State('type')
  public type!: string

  /**
   * Styleklasse, um das Aussehen zu beeinflussen
   */
  @NotificationStore.State('btnText')
  public btnText!: string

  /**
   * Optionales Icon, welches links im Toast angezeigt wird.
   */
  @NotificationStore.State('icon')
  public icon!: string

  /**
   * Blendet den Toast über den Button aus
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Blendet den Toast über den Button aus
   *
   * @param value - value
   */
  @Watch('showNotification')
  public onChange(value: boolean): void {
    if (value) {
      const elements = document.getElementsByClassName(
        'v-btn--fixed v-btn--right v-btn--round'
      )
      if (elements.length > 0) {
        this.fabAvailable = true
      } else {
        this.fabAvailable = false
      }
    }
  }
}
