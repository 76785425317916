
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NotificationPayload } from './store/notifications/notification-payload.interface'
import Dark from './mixins/dark'
import { EnvironmentMixin } from './mixins/environment.mixin'
import AboutSection from './components/AboutSection/AboutSection.vue'
import QuickAccess from './components/QuickAccess/QuickAccess.vue'
import PageTitle from './components/PageTitle/PageTitle.vue'
import GlobalSearchField from './components/GlobalSearchField/GlobalSearchField.vue'
import GlobalFilter from './components/GlobalFilter/GlobalFilter.vue'
import SideNavigation from './components/SideNavigation/SideNavigation.vue'
import ToasterOutlet from './components/ToasterOutlet/ToasterOutlet.vue'
import ServerConnection from './components/ServerConnection/ServerConnection.vue'
import LogoCard from './components/LogoCard/LogoCard.vue'
import UserAvatar from './components/UserAvatar/UserAvatar.vue'
import UserMenu from './components/UserMenu/UserMenu.vue'

const UIStore = namespace('ui')
const ConnectivityStore = namespace('connectivity')

/**
 * Navigationseintrag.
 */
interface NavigationItem {
  /**
   * Ziel-Route.
   */
  to: string

  /**
   * Titel/Bezeichnung.
   */
  title: string

  /**
   * Icon, das angezeigt werden soll.
   */
  icon: string
}

/**
 * Link.
 */
interface LinkItem {
  /**
   * Title/Bezeichnung.
   */
  title: string

  /**
   * Ziel-URL.
   */
  href: string
}

/**
 * Root-Komponente der Anwendung.
 */
@Component({
  components: {
    AboutSection,
    QuickAccess,
    PageTitle,
    GlobalFilter,
    GlobalSearchField,
    SideNavigation,
    ToasterOutlet,
    ServerConnection,
    LogoCard,
    UserAvatar,
    UserMenu
  }
})
export default class App extends Mixins(Dark, EnvironmentMixin) {
  /**
   * Gibt an, ob die mobile Suche aktiv ist.
   */
  @UIStore.State('mobileSearch')
  public mobileSearch!: boolean

  /**
   * Aktiviert die mobile Suche.
   */
  @UIStore.Action('enableMobileSearch')
  public enableMobileSearch!: Promise<void>

  /**
   * Deaktiviert die mobile Suche.
   */
  @UIStore.Action('disableMobileSearch')
  public disableMobileSearch!: Promise<void>

  /**
   * Schaltet die mobile Suche um.
   */
  @UIStore.Action('toggleMobileSearch')
  public toggleMobileSearch!: Promise<void>

  /**
   * `true`, wenn der Client online ist, ansonsten `false`.
   */
  @ConnectivityStore.Getter('online')
  public online!: boolean

  /**
   * Gibt an, ob das `<user-menu>` offen oder geschlossen ist.
   */
  public userMenuOpen = false

  /**
   * Reagiert auf Änderungen von [[online]] und zeigt dem Benutzer eine Toast-
   * Benachrichtigung über die Änderung des Verbindungsstatus an.
   *
   * @param newValue - Neuer Verbindungsstatus ([[online]]).
   * @param oldValue - Alter Verbindungsstatus ([[online]]).
   */
  @Watch('online')
  public watchOnline(newValue: boolean, oldValue: boolean): void {
    if (newValue === oldValue) {
      return
    }

    const payload: NotificationPayload = {
      type: newValue ? 'success' : 'warning',
      text: this.$t(
        `system.notification.connectivity.${newValue ? 'online' : 'offline'}`
      ) as string,
      timeout: 3000,
      btnText: '',
      icon: newValue ? 'mdi-wifi' : 'mdi-wifi-off'
    }

    this.$store.dispatch('notification/toggleToast', payload)
  }

  /**
   * Gibt an, ob der Navigations-Drawer aktiv ist.
   */
  public drawer: boolean | null = null

  /**
   * Gibt an, ob am Menu-Icon ein Badge angezeigt werden soll.
   */
  public displayBadge = false

  /**
   * Navigationseinträge.
   */
  public navigation: NavigationItem[] = [
    {
      to: '/',
      title: 'Home',
      icon: 'mdi-home'
    },
    {
      to: '/dashboard',
      title: 'Dashboard',
      icon: 'mdi-view-dashboard'
    },
    {
      to: '/employees',
      title: 'Mitarbeiter',
      icon: 'mdi-account-tie'
    }
  ]

  /**
   * Link-Einträge.
   */
  public links: LinkItem[] = [
    {
      href: 'https://www.google.com',
      title: 'Google'
    },
    {
      href: 'https://www.monstercat.com',
      title: 'Monstercat'
    },
    {
      href: 'https://www.minecraft.net',
      title: 'Minecraft'
    }
  ]

  /**
   * Gibt an, ob der Schnellzugriff gerade geöffnet ist.
   */
  public quickAccessOpen = false

  /**
   * Gibt an, ob man sich auf der Loginseite befindet
   *
   * @returns - Ob man auf der Login Seite ist
   */
  public get isLogin(): boolean {
    if (
      (this.$route.meta || { hideOverlay: false }).hideOverlay === true ||
      this.$route.path === '/'
    ) {
      return true
    }
    return false
  }

  /**
   * Aktiviert einen Listener, der den Zustand des Dark Mode vom System
   * überwacht und den Dark Mode in der App entsprechend (de)aktiviert.
   */
  public created(): void {
    // Dark Mode vom System (Android 10, iOS 13) übernehmen.
    const mq = window.matchMedia('(prefers-color-scheme: dark)')

    this.dark = mq.matches

    mq.addListener((e): void => {
      this.dark = e.matches
    })

    this.$store.dispatch('language/loadFromServer')
  }

  /**
   * Prüft, ob der Entwicklungsmodus oder die Route 'audit_list' aktiv ist.
   *
   * @returns Ergebnis der Prüfung.
   */
  public get devOrAudit(): boolean {
    return this.isDevelopment || this.$route.name === 'audit_list'
  }
}
