
import { Component, Vue } from 'vue-property-decorator'

/**
 * Informationen zu einem Handbuch.
 */
interface ManualInformation {
  /**
   * Titel des Handbuchs.
   */
  title: string

  /**
   * Untertitel des Handbuchs.
   */
  subtitle: string

  /**
   * Link zum Handbuch.
   */
  href: string
}

/**
 * Handbuchbereich des Hilfebereichs.
 */
@Component({})
export default class HelpCenterManual extends Vue {
  /**
   * Liste der Handbücher.
   */
  public manuals: ManualInformation[] = [
    {
      title: 'helpCenterManual.manual.title',
      subtitle: 'helpCenterManual.manual.subtitle',
      href:
        window.location.origin +
        '/files/system/eplas%20Handbuch%20-%20Modul%20Dashboard.pdf'
    }
  ]
}
