import { DashboardTranslationsWithID } from '@/interfaces/DashboardTranslationsWithID.interface'
import { RootState } from '../root-state.interface'
import { Module } from 'vuex'
import { state } from './state'
import Getters from './getters'
import { mutations } from './mutations'

export const dashboardTranslations: Module<
  DashboardTranslationsWithID[],
  RootState
> = {
  namespaced: true,
  state,
  getters: Getters,
  mutations
}
