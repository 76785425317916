import { UserState } from './user-state.interface'

export const state: UserState = {
  id: null,
  personnelNumber: null,
  systemLanguage: null,
  username: null,
  permissions: [],
  roles: [],
  adminFlag: false
}
