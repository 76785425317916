/**
 * Erstellt den Feathers-Instanz für den Offline-Modus und bindet die benötigten
 * Service an diesen.
 */
import feathers from '@feathersjs/feathers'
import { BaseService } from './base-service'
import { AllowMultiEvents } from './allow-multi-events.enum'
// START: Importe für die Hooks von den Services
import { AuditOfflineHooks } from '@/offline/services/audit/audit-offline-hooks'
import { CellValuesHooks } from './services/obsolete-checklist/cell-values.hooks'
import { CollectionsDeepHooks } from './services/obsolete-checklist/collections-deep-hooks'
import { VariablesDeepHooks } from './services/obsolete-checklist/variables-deep-hooks'
import { PickerEmployees } from './services/picker-employees.hooks'
// END: Importe für die Hooks von den Services
import { authentication } from '@feathersjs/client'
import { cookieStorage } from '../helpers/cookie-storage'
import store from '@/store'

/**
 * Offline-Variante von Feathers
 */
export const app = feathers()

app.configure(
  authentication({
    storage: cookieStorage,
    path: '/auth'
  })
)

app.on('authenticated', (): void => {
  const lastUserJSON = localStorage.getItem('lastUser')

  if (!lastUserJSON) {
    return
  }

  const lastUser = JSON.parse(lastUserJSON)

  store.dispatch('user/updateUser', lastUser)
})

/* Nachfolgen die Services erzeugen und an die Feathers-Instanz für den Offline-
 * Modus binden. Diese steht in der Variable `app` zur verfügung, diese wird
 * auch nur an den passenden Stellen Importiert!
 *
 * Wenn möglich immer die `BasicService`-Klasse verwenden. Ist dieses nicht
 * möglich, muss die erstellte Klasse für den eigenen Service von der Klasse
 * `BasicService` abgeleitet sein!
 *
 * Beispiel:
 * new BaseService('ServiceNamee', 'DatenbankName').register(app)
 */
new BaseService('auth', 'BasicData', null, 'accessToken').register(app)
new BaseService('theme', 'BasicData').register(app)
new BaseService('checklists/permissions', 'obsolete-checklist').register(app)
new BaseService('checklists/status', 'obsolete-checklist').register(app)
new BaseService('checklist/procedure', 'BasicData').register(app)
new BaseService(
  'checklists-collections/deep',
  'obsolete-checklist',
  CollectionsDeepHooks
).register(app, true)
new BaseService(
  'checklists-collections/deep/mapping',
  'obsolete-checklist'
).register(app)
new BaseService(
  'checklists-collections/deep/collections',
  'obsolete-checklist'
).register(app)
new BaseService(
  'checklists/variables/deep',
  'obsolete-checklist',
  VariablesDeepHooks
).register(app)
new BaseService('picker-employees', 'picker', PickerEmployees).register(app)
new BaseService('locations', 'BasicData').register(app)
new BaseService('offline-data/employees', 'offline-cache', null, 'id', [
  AllowMultiEvents.Removed,
  AllowMultiEvents.Created
]).register(app)
const serviceChecklistCategories = new BaseService(
  'offline-data/checklist-categories',
  'offline-cache',
  null,
  'id',
  [AllowMultiEvents.Removed, AllowMultiEvents.Created]
)
serviceChecklistCategories.paginationMax = 100
serviceChecklistCategories.paginationDefault = 100
serviceChecklistCategories.register(app)
new BaseService('role', 'BasicData').register(app)
const serviceAuditsOffline = new BaseService(
  'audits/offline',
  'audit',
  AuditOfflineHooks
)
serviceAuditsOffline.paginationMax = 100
serviceAuditsOffline.paginationDefault = 100
serviceAuditsOffline.register(app)

new BaseService('checklists/location', 'obsolete-checklist').register(app)
new BaseService(
  'checklists/cells/values',
  'obsolete-checklist',
  CellValuesHooks,
  'id',
  [AllowMultiEvents.Removed]
).register(app)
new BaseService('checklists/variables', 'obsolete-checklist', null, 'id', [
  AllowMultiEvents.Removed
]).register(app)
new BaseService('checklists/properties', 'obsolete-checklist').register(app)
