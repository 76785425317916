import { HooksObject } from '@feathersjs/feathers'
import { calculateOfflineAudit } from '@/offline/hooks/audit/calculate-offline-audits.hook'

/**
 * Hooks für die Checklist-Zelle
 */
export const AuditOfflineHooks: HooksObject = {
  before: [],
  after: [calculateOfflineAudit],
  error: []
}
