import { ActionTree } from 'vuex'
import { UIState } from './ui-state.interface'
import { RootState } from '../root-state.interface'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { MenuBadgeSet, MenuBadgeItem } from './menu-badge.interface'

export const actions: ActionTree<UIState, RootState> = {
  /**
   * Führt die Mutation 'enableMobileSearch' aus.
   */
  enableMobileSearch({ commit }): void {
    commit('enableMobileSearch')
  },
  /**
   * Führt die Mutation 'disableMobileSearch' aus.
   */
  disableMobileSearch({ commit }): void {
    commit('disableMobileSearch')
  },
  /**
   * Führt die Mutation 'enableMobileSearch' aus, wenn die mobile Suche
   * deaktiviert ist oder 'disableMobileSearch', wenn diese aktiviert ist.
   */
  toggleMobileSearch({ state, commit }): void {
    if (state.mobileSearch) {
      commit('disableMobileSearch')
      return
    }

    commit('enableMobileSearch')
  },
  /**
   * Führt die Mutation 'showGlobalFilter' aus.
   */
  showGlobalFilter({ commit }): void {
    commit('showGlobalFilter')
  },
  /**
   * Führt die Mutation 'hideGlobalFilter' aus.
   */
  hideGlobalFilter({ commit }): void {
    commit('hideGlobalFilter')
  },
  /**
   * Führt die Mutation 'showGlobalFilter' aus, wenn die globalen Filtern
   * ausgeblendet sind oder 'hideGlobalFilter', wenn diese eingeblendet sind.
   */
  toggleGlobalFilter({ state, commit }): void {
    if (state.showGlobalFilter) {
      commit('hideGlobalFilter')
    } else {
      commit('showGlobalFilter')
    }
  },
  /**
   * Führt die Mutation 'disableMobileSearchIfEmpty' aus.
   *
   * @returns Commit-Ergebnis.
   */
  disableMobileSearchIfEmpty({ commit }): void {
    return commit('disableMobileSearchIfEmpty')
  },

  /**
   * Führt die Mutation 'setGlobalSearchTerm' aus.
   *
   * @param term - Suchstring.
   */
  setGlobalSearchTerm({ commit }, term: string): void {
    commit('setGlobalSearchTerm', term)
  },

  /**
   * Führt die Mutation 'setGlobalSearchColumns' aus.
   *
   * @param searchColumns - Name der Spalten.
   */
  setGlobalSearchColumns({ commit }, searchColumns: string | string[]): void {
    commit('setGlobalSearchColumns', searchColumns)
  },

  /**
   * Führt die Mutation 'setColumns' aus.
   *
   * @param columns - Vorhandene Tabellenspalten, die gefiltert werden können.
   */
  setColumns({ commit }, columns: Column[]): void {
    commit('setColumns', columns)
  },
  /**
   * Führt die Mutation 'setFilters' aus.
   *
   * @param filters - Filter, nach denen eine Liste durchsucht werden soll
   */
  setFilters({ commit }, filters: Filter[]): void {
    commit('setFilters', filters)
  },
  /**
   * Führt die Mutation 'setMenuBadge' aus.
   *
   * @param state - State des UI-Moduls.
   * @param item - Menüeintrag und der Wert für dessen Badges.
   */
  setMenuBadge({ commit }, item: MenuBadgeItem): void {
    commit('setMenuBadge', item)
  },
  /**
   * Führt die Mutation 'setMenuBadges' aus.
   *
   * @param state - State des UI-Moduls.
   * @param menuBadge - Alle neue Werte für die Bages des Menüs.
   */
  setMenuBadges({ commit }, menuBadge: MenuBadgeSet): void {
    commit('setMenuBadges', menuBadge)
  }
}
