export const it = {
  externalLogin: {
    rule: {
      required: 'Si prega di compilare i campi obbligatori.',
      email: `L'e-mail non è valida.`,
      number: 'Questo campo deve contenere un numero.'
    },
    buttonLabel: {
      check: 'Verifica',
      proceed: 'Avanti',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Login',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'ulteriori informazioni'
    },
    field: {
      lastName: 'Cognome',
      firstName: 'Nome',
      company: 'Azienda',
      country: 'Paese di provenienza:',
      visitReason: 'Motivo visita',
      email: 'E-mail',
      id: 'Numero documento',
      street: 'Via / N.',
      zip: 'CAP',
      location: 'Luogo',
      phone: 'Telefono',
      birthdate: 'Data di nascita',
      training: 'Livello di istruzione',
      salutation: 'Appellativo',
      gender: 'Sesso',
      titel: 'Titolo',
      prefixName: 'Prefisso',
      suffixName: 'Suffisso',
      city: 'Città',
      workingTime: 'Orario di lavoro',
      workingTasks: 'Occupato come',
      citizenship: 'Nazionalità',
      healthInsurance: 'Assicurazione sanitaria',
      healthInsuranceName: `Nome dell'assicurazione sanitaria`,
      healthInsuranceZip: 'Assicurazione sanitaria: CAP',
      healthInsuranceCity: 'Assicurazione sanitaria: città',
      healthInsuranceStreet: 'Assicurazione sanitaria: indirizzo',
      birthplace: 'Luogo di nascita',
      letterSalutation: 'Appellativo epistolare',
      adressAddition: `Suffisso dell'indirizzo`,
      hireDate: 'Data di assunzione',
      leavingDate: 'Data di uscita',
      fax: 'Fax'
    },
    gender: {
      notSpecified: 'Non specificato',
      femail: 'donna',
      male: 'uomo',
      divers: 'diverso'
    },
    salutation: {
      notSpecified: '-',
      female: 'Signora',
      male: 'Signor'
    },
    error: {
      maxLength: 'La lunghezza massima è stata superata.',
      noVisitReason: `Non ci sono motivi di visita in questa lingua. Selezionare un'altra lingua.`
    }
  },
  error: {
    0x0000: {
      title: 'Errore',
      text: 'Errore generale: {message}'
    },
    0x0001: {
      title: `Errore nella convalida dell'immissione`,
      text: 'Si prega di inserire i dati correttamente nel campo corrispondente.'
    },
    0x0002: {
      title: 'Collaboratore esterno ricorrente',
      text: 'Non sono ammessi collaboratori esterni ricorrenti.'
    },
    0x0003: {
      title: 'Il server non è disponibile',
      text: 'Il server non è al momento disponibile. Riprovare più tardi. Dettagli: {details}'
    },
    0x0004: {
      title: 'Nessun API-Secret disponibile',
      text: `Non è disponibile l'API-Secret per la comunicazione con il server.`
    },
    0x0005: {
      title: 'Nessun URL di base disponibile',
      text: `L'URL di base dell'eplas frontend non è disponibile.`
    },
    0x0006: {
      title: 'Problema con il login',
      text: `Non è stato possibile completare il login su eplas. Si prega di contattare personalmente la persona di riferimento dell'azienda.`
    },
    0x0007: {
      title: 'Il token non è valido',
      text: 'Il token è scaduto'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Password',
      username: 'Nome utente'
    },
    welcomeMessage: {
      text: 'Benvenuto su eplas!'
    },
    forwardMessage: {
      title: 'Accesso riuscito!',
      text: `Sarai reindirizzato da un momento all'altro...`
    },
    buttonLabel: {
      login: 'Login'
    },
    loginMethod: {
      local: 'Login',
      saml: `Accesso all'azienda`,
      ldap: 'Accesso a Windows',
      external: 'Collaboratore esterno',
      pin: 'Inserimento pin'
    },
    forgotPassword: {
      button: 'Password dimenticata?'
    },
    error: {
      noOfflineLogin: `L'accesso non può essere abilitato in modalità offline!`,
      username:
        'Accesso non riuscito. Controllare il nome utente e la password.',
      usernameValidation: 'Nome utente richiesto.',
      passwordValidation: 'Password richiesta.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Ripetere la password',
      setPasswordConfirm: 'Impostare la password',
      setPasswordTitle:
        'Primo accesso: si prega di cambiare la password per questo motivo',
      success: 'La password è stata modificata con successo'
    },
    passwordRules: {
      title: 'Politica sulle password',
      maximumLength: 'La password è troppo lunga.',
      minimumLength: 'La password è troppo corta.',
      minimumDigits:
        'La password non contiene un numero sufficiente di numeri.',
      minimumUpperCase:
        'La password non contiene un numero sufficiente di lettere maiuscole.',
      minimumLowerCase:
        'La password non contiene un numero sufficiente di lettere minuscole.',
      minimumSpecialCharacters: 'La password non contiene caratteri speciali.',
      allowedSpecialCharacters:
        'La password contiene caratteri speciali non consentiti.',
      general: 'Si è verificato un errore durante la modifica della password.',
      passwordConfirmationValidation: 'Le due password non combaciano.'
    },
    buttonLabel: {
      login: 'Vai alla pagina di accesso'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Password dimenticata?',
      email: `Indirizzo e-mail dell'utente`,
      confirm: 'Invia e-mail',
      error: `L'indirizzo e-mail non è valido.`,
      cancel: 'Ana',
      hint: '',
      emailSent: 'L’e-mail è stata inviata.'
    }
  },
  passwordReset: {
    title: 'Modificare la password'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Aggiornamento dei dati'
    },
    text: {
      welcome: 'Grazie per aver effettuato il primo accesso su eplas.',
      employeeInfos:
        'Compilare i dati riportati di seguito o verificarne la correttezza.',
      password: 'Scegliere una password'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'mobile interno',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Sei ora online.',
        offline: 'Sei ora offline.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Stampa',
      search: 'Cerca',
      close: 'Chiudi',
      create: 'Redigere',
      edit: 'Modifica',
      copy: 'Copia',
      back: 'Indietro',
      remove: 'Rimuovi',
      abort: 'Saltare',
      finished: 'Fatto',
      ok: 'OK',
      cancel: 'Ana'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
