export const pl = {
  externalLogin: {
    rule: {
      required: 'Wypełnij wszystkie pola obowiązkowe.',
      email: 'Adres e-mail jest nieprawidłowy.',
      number: 'To pole musi zawierać liczbę.'
    },
    buttonLabel: {
      check: 'Sprawdzanie',
      proceed: 'Dalej',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Zaloguj',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Dalsze informacje'
    },
    field: {
      lastName: 'Nazwisko',
      firstName: 'Imię',
      company: 'Firma',
      country: 'kraj pochodzenia',
      visitReason: 'powód wizyty',
      email: 'e-mail',
      id: 'nr dokumentu tożsamości',
      street: 'ulica / nr',
      zip: 'kod pocztowy',
      location: 'miejscowość',
      phone: 'telefon',
      birthdate: 'data urodzenia',
      training: 'wykształcenie',
      salutation: 'tytuł',
      gender: 'płeć',
      titel: 'tytuł',
      prefixName: 'przedimek',
      suffixName: 'pseudonim',
      city: 'miasto',
      workingTime: 'czas pracy',
      workingTasks: 'zawód',
      citizenship: 'obywatelstwo',
      healthInsurance: 'Kasa Chorych',
      healthInsuranceName: 'nazwa Kasy Chorych',
      healthInsuranceZip: 'Kasa Chorych: kod',
      healthInsuranceCity: 'Kasa Chorych: miasto',
      healthInsuranceStreet: 'Kasa Chorych: ulica',
      birthplace: 'miejsce urodzenia',
      letterSalutation: 'zwrot w korespondencji',
      adressAddition: 'dodatek do adresu',
      hireDate: 'data zatrudnienia',
      leavingDate: 'data odejścia',
      fax: 'fax'
    },
    gender: {
      notSpecified: 'nie określono',
      femail: 'kobieta',
      male: 'mężczyzna',
      divers: 'inna'
    },
    salutation: {
      notSpecified: '-',
      female: 'Pani',
      male: 'Pan'
    },
    error: {
      maxLength: 'Maksymalna długość została przekroczona.',
      noVisitReason: 'Nie ma dostępnych powodów wizyty. Wybierz inny język.'
    }
  },
  error: {
    0x0000: {
      title: 'Błąd',
      text: 'Błąd ogólny: {message}'
    },
    0x0001: {
      title: 'Błąd walidacji wejścia',
      text: 'Wpisz poprawnie dane w odpowiednie pole.'
    },
    0x0002: {
      title: 'Powtarzający się pracownik zewnętrzny',
      text: 'Powtarzający się pracownicy zewnętrzni nie są dozwoleni.'
    },
    0x0003: {
      title: 'Serwer nie jest dostępny',
      text: 'Serwer nie jest w tej chwili dostępny. Prosimy spróbować później. Szczegóły: {details}'
    },
    0x0004: {
      title: 'Brak dostępnego API-Secret',
      text: 'API-Secret do komunikacji z serwerem nie jest dostępny.'
    },
    0x0005: {
      title: 'Nie ma dostępnego adresu URL bazy',
      text: 'Bazowy adres URL frontendu eplas nie jest dostępny.'
    },
    0x0006: {
      title: 'Problem z logowaniem',
      text: 'Twoje logowanie do eplas nie mogło zostać ukończone. Skontaktuj się osobiście z osobą kontaktową w firmie.'
    },
    0x0007: {
      title: 'Twój token jest nieważny',
      text: 'Twój token jest nieprawidłowy'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Hasło',
      username: 'Nazwa użytkownika'
    },
    welcomeMessage: {
      text: 'Witamy w eplas!'
    },
    forwardMessage: {
      title: 'Logowanie powiodło się!',
      text: 'Za chwilę zostaniesz przekierowany…'
    },
    buttonLabel: {
      login: 'Zaloguj'
    },
    loginMethod: {
      local: 'Zaloguj',
      saml: 'Login firmy',
      ldap: 'Login windows',
      external: 'Pracownik zewnętrzny',
      pin: 'Wprowadzenie kodu PIN'
    },
    forgotPassword: {
      button: 'Zapomniałeś hasła?'
    },
    error: {
      noOfflineLogin: 'Logowanie nie może być włączone w trybie offline!',
      username:
        'Logowanie nie powiodło się. Sprawdź swoją nazwę użytkownika i hasło.',
      usernameValidation: 'Wymagana nazwa użytkownika.',
      passwordValidation: 'Wymagane hasło.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Powtórz hasło',
      setPasswordConfirm: 'Ustaw hasło',
      setPasswordTitle: 'Pierwsze logowanie:  zmień swoje hasło',
      success: 'Hasło zostało zmienione pomyślnie'
    },
    passwordRules: {
      title: 'Polityka dotycząca haseł',
      maximumLength: 'Hasło jest zbyt długie.',
      minimumLength: 'Hasło jest za krótkie.',
      minimumDigits: 'Hasło nie zawiera wystarczającej liczby cyfr.',
      minimumUpperCase: 'Hasło nie zawiera wystarczającej liczby dużych liter.',
      minimumLowerCase: 'Hasło nie zawiera wystarczającej liczby małych liter.',
      minimumSpecialCharacters: 'Hasło nie zawiera znaków specjalnych.',
      allowedSpecialCharacters:
        'Hasło zawiera znaki specjalne, które są niedozwolone.',
      general: 'Podczas zmiany hasła wystąpił błąd.',
      passwordConfirmationValidation: 'Te dwa hasła nie są identyczne'
    },
    buttonLabel: {
      login: 'Na stronę logowania'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Zapomniałeś hasła?',
      email: 'Adres e-mail użytkownika',
      confirm: 'Wyślij e-mail',
      error: 'Adres e-mail jest nieprawidłowy.',
      cancel: 'anuluj',
      hint: '',
      emailSent: 'E-mail został wysłany.'
    }
  },
  passwordReset: {
    title: 'Zmień swoje hasło'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Aktualizacja danych'
    },
    text: {
      welcome: 'Dziękujemy za zalogowanie się po raz pierwszy na eplas.',
      employeeInfos: 'Wypełnij poniższe dane lub sprawdź ich poprawność.',
      password: 'Wybierz hasło'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'mobile internal',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Jesteś teraz online.',
        offline: 'Jesteś teraz offline.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Drukuj',
      search: 'Szukaj',
      close: 'Zamknij',
      create: 'Utwórz stronę',
      edit: 'Edytuj',
      copy: 'Kopia',
      back: 'Powrót',
      remove: 'Usuń',
      abort: 'Anuluj',
      finished: 'Gotowe',
      ok: 'Ok',
      cancel: 'Anuluj'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
