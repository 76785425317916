/**
 * Ländercodes.
 */
export enum CountryCode {
  /**
   * Code (Sprachkeys, wird für interne Entwicklungszwecke genutzt).
   */
  Code = 'code',

  /**
   * Tschechische Republik
   */
  CZ = 'cz',

  /**
   * Deutschland
   */
  DE = 'de',

  /**
   * Englisch (GB/US)
   */
  EN = 'en',

  /**
   * Finnland
   */
  FI = 'fi',

  /**
   * Frankreich
   */
  FR = 'fr',

  /**
   * Kroatien
   */
  HR = 'hr',

  /**
   * Ungarn
   */
  HU = 'hu',

  /**
   * Italien
   */
  IT = 'it',

  /**
   * Niederlande
   */
  NL = 'nl',

  /**
   * Polen
   */
  PL = 'pl',

  /**
   * Rumänien
   */
  RO = 'ro',

  /**
   * Russland
   */
  RU = 'ru',

  /**
   * Türkei
   */
  TR = 'tr',

  /**
   * Griechenland
   */
  GR = 'gr',

  /**
   * Bulgarien
   */
  BG = 'bg',

  /**
   * Ukraine (Sprachcode)
   */
  UK = 'uk',
  /**
   * Spanien
   */
  ES = 'es',
  /**
   * Slowenien
   */
  SL = 'sl'
}
