
import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { Filter } from '@/interfaces/Filter.interface'
import FilterList from '@/components/FilterList/FilterList.vue'

const UIStore = namespace('ui')

/**
 * Die globalen Filter, werden auch für die globale Suche verwendet, bzw.
 * schränken diese ein.
 */
@Component({
  components: {
    FilterList
  }
})
export default class GlobalFilter extends Vue {
  /**
   * Zähler der ermöglicht das die Komponente durch Vue neu gerendert wird.
   * Dadurch können und werden Anzeigefehler behoben.
   */
  public rebuild = 0

  /**
   * Enthält die Sichtbarkeitseinstellung für den globalen Filter (aus dem
   * UI-Store). Änderungen an dieser Variable sollten unterlassen werden.
   * Änderungen immer direkt über den UI-Store vornehmen!
   */
  @Getter('showGlobalFilter', { namespace: 'ui' })
  public showGlobalFilter!: boolean

  /**
   * Aktuell gesetzte Filter im Store.
   */
  @Getter('filters', { namespace: 'ui' })
  public filters!: Filter[]

  /**
   * Namen der Spalten, die von der globalen Suche durchsucht werden.
   */
  @UIStore.State('globalSearchColumns')
  public searchColumns!: string[]

  /**
   * Enthält die Spalten einer Tabelle aus dem Store, in denen gesucht werden
   * kann (searchable === true) und die Strings, nach denen gesucht werden darf,
   * z.B. Name, Vorname, etc.
   */
  @Getter('searchables', { namespace: 'ui' })
  public searchableColumns!: Column[]

  /**
   * Enthält die Spalten einer Tabelle aus dem Store, in denen gesucht werden
   * kann (searchable === true) und die Datumswerte enthalten, die gefiltert
   * werden sollen
   */
  @Getter('dates', { namespace: 'ui' })
  public dateColumns!: Column[]

  /**
   * Enthält die Spalten einer Tabelle aus dem Store, in denen gesucht werden
   * kann (searchable === true) und die einen Boolean-Zustand enthalten, der
   * gefiltert werden soll
   */
  @Getter('booleans', { namespace: 'ui' })
  public booleanColumns!: Column[]

  /**
   * Enthält die Spalten einer Tabelle aus dem Store, die in den Filtern als
   * Dropdown ausgegeben werden sollen.
   */
  @Getter('selects', { namespace: 'ui' })
  public selectColumns!: Column[]

  /**
   * Gibt die berechneten Style-Werte für den Header-Abschnitt zurück.
   *
   * @returns Die CSSStyleDeclaration für den Header.
   */
  public get styleHeader(): Partial<CSSStyleDeclaration> {
    return {
      height: `${this.$vuetify.application.top}px`
    }
  }

  /**
   * Gibt die Breite für den Container zurück. Dieser ist Standardmäßig `290px`
   * groß. Außer beim Breakpoint `xs`, hier füllt die Komponente den ganzen
   * Bildschirm aus.
   *
   * @returns Breite vom Container.
   */
  public get containerWidth(): string {
    if (this.showGlobalFilter) {
      if (this.rebuild === Number.MAX_SAFE_INTEGER) {
        this.rebuild = Number.MIN_SAFE_INTEGER
      } else {
        ++this.rebuild
      }
    }

    return this.$vuetify.breakpoint.xsOnly ? '100%' : '290px'
  }

  /**
   * Setzt im Store die ausgwählten globalen Filter, als ein [[`FilterSet`]].
   * Diese werden für die Filterung der angezeigten Einträge verwendet.
   */
  @UIStore.Action('setFilters')
  public setFilters!: (filters: {}) => Promise<void>

  /**
   * Setzt die Einschränkung, welche Spalten bei der Suche durchsucht werden
   * dürfen.
   */
  @UIStore.Action('setGlobalSearchColumns')
  public setGlobalSearchColumns!: (string: {}) => Promise<void>

  /**
   * Toggelt die Sichtbarkeitseinstellung der globalen Filtern. Als aktuelle
   * Sichtbarkeitseinstellung, wird die Eigenschaft [[this.showGlobalFilter]]
   * verwendet.
   */
  public onToggleShowGlobalFilter(): void {
    if (this.showGlobalFilter) {
      this.$store.commit('ui/hideGlobalFilter')
    } else {
      this.$store.commit('ui/showGlobalFilter')
    }
  }

  /**
   * Speichert die geänderten Filtereinstellungen im UI-Store, wenn die
   * Eigenschaft [[`filters`]] geändert wird.
   *
   * @param filter - Das neue [[`FilterSet`]].
   */
  public onFilterChange(filter: Filter[]): void {
    this.setFilters(filter)
  }

  /**
   * Speichert die Namen der Spalten, die von der Suche berücksichtigt werden.
   *
   * @param names - Namen der Spalten
   */
  public onGlobalSearchColumnsChange(names: string[]): void {
    this.setGlobalSearchColumns(names)
  }
}
