import { HookContext, Service, Id } from '@feathersjs/feathers'
import { getService, existsAsOfflineService } from '../../../helpers/feathers'
import { Connection } from '../../connection.enum'
import { OfflineEntry } from '../../offline-entry.interface'
import { CustomPaginated } from '../../../interfaces/CustomPaginated.interface'
import { getConfig } from './get-config'
import { Methods } from './methods.enum'

/**
 * Dies ist die opportunistisches aktualisierungs Funktion um die Offline-Daten
 * direkt bei Abfragen des Online-Modus zu aktualisieren. Sie kann nur im
 * 'after'-Hook von Feathers verwendet werden, da hier die
 * [[HookContext.result |  'result'-Property]] des [[HookContext]] verwendet
 * wird. In allen anderen Hooks wird nichts passieren!
 *
 * @param context - Der [[HookContext]] der ausgeführten Abfrage (von Feathers)
 * @returns Rückgabe des HookContext
 */
export async function saveEntriesHook(
  context: HookContext<unknown, Service<unknown>>
): Promise<HookContext<unknown, Service<unknown>>> {
  if (
    context.result &&
    context.type === 'after' &&
    existsAsOfflineService(context.path)
  ) {
    const config = getConfig(context.path)
    const method: Methods = context.method as Methods

    if (config !== null && config.methods.indexOf(method) !== -1) {
      const offlineService = await getService(context.path, Connection.Offline)
      let entries: OfflineEntry[] | null = null

      if (Array.isArray(context.result)) {
        entries = context.result as OfflineEntry[]
      } else if (
        Array.isArray((context.result as CustomPaginated<unknown>).data)
      ) {
        entries = (context.result as CustomPaginated<unknown>)
          .data as OfflineEntry[]
      } else if (typeof context.result === 'object') {
        entries = [context.result as OfflineEntry]
      }

      if (entries !== null) {
        const params = {
          ...context.params,
          _entryID: context.id
        }

        if (method !== Methods.Remove) {
          for (const entry of entries) {
            ;(async (entry: OfflineEntry, entryID: Id): Promise<void> => {
              const entryFound = entryID
                ? await offlineService.get(entryID, params).then(
                    (result): boolean => !!result,
                    (): boolean => false
                  )
                : false

              if (entryFound) {
                await offlineService.update(entryID, entry, params)
              } else {
                await offlineService.create(entry, params)
              }
            })(entry, config.getPrimaryKey(entry))
          }
        } else {
          for (const entry of entries) {
            offlineService.remove(config.getPrimaryKey(entry), params)
          }
        }
      }
    }
  }

  return context
}
