export const gr = {
  externalLogin: {
    rule: {
      required: 'Συμπληρώστε όλα τα υποχρεωτικά πεδία.',
      email: 'Η διεύθυνση e-mail δεν είναι έγκυρη.',
      number: 'Το πεδίο αυτό πρέπει να περιλαμβάνει αριθμητικό στοιχείο.'
    },
    buttonLabel: {
      check: 'Επαλήθευση',
      proceed: 'Συνέχιση',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Σύνδεση',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'περισσότερες πληροφορίες'
    },
    field: {
      lastName: 'Επώνυμο',
      firstName: 'Όνομα',
      company: 'Εταιρεία',
      country: 'Χώρα προέλευσης',
      visitReason: 'Αιτία της επίσκεψης',
      email: 'Email',
      id: 'Αριθμός ταυτότητας',
      street: 'Οδός /αριθμός',
      zip: 'ΤΚ',
      location: 'Τόπος',
      phone: 'Τηλέφωνο',
      birthdate: 'Ημερομηνία γέννησης',
      training: 'Εκπαίδευση',
      salutation: 'Προσφώνηση',
      gender: 'Φύλο',
      titel: 'Τίτλος',
      prefixName: 'Πρόθημα',
      suffixName: 'Λοιπά',
      city: 'Πόλη',
      workingTime: 'Ωράριο εργασίας',
      workingTasks: 'Απασχολείται ως',
      citizenship: 'Υπηκοότητα',
      healthInsurance: 'Ασφαλιστικό ταμείο υγείας',
      healthInsuranceName: 'Ονομασία του ασφαλιστικού ταμείου υγείας',
      healthInsuranceZip: 'Ασφαλιστικό ταμείο υγείας: Ταχυδρομικός Κώδικας',
      healthInsuranceCity: 'Ασφαλιστικό Ταμείο Υγείας: Πόλη',
      healthInsuranceStreet: 'Ασφαλιστικό Ταμείο Υγείας: Οδός',
      birthplace: 'Ασφαλιστικό Ταμείο Υγείας: Τόπος γέννησης',
      letterSalutation: 'Προσφώνηση γράμματος',
      adressAddition: 'Διεύθυνση (γραμμή 2)',
      hireDate: 'Ημερομηνία πρόσληψης',
      leavingDate: 'Ημερομηνία αναχώρησης',
      fax: 'Φαξ'
    },
    gender: {
      notSpecified: 'Καμία πληροφορία',
      femail: 'Θηλυκό',
      male: 'Αρσενικό',
      divers: 'Μη δυαδικό'
    },
    salutation: {
      notSpecified: '-',
      female: 'Κυρία',
      male: 'Κύριος'
    },
    error: {
      maxLength: 'Έχετε υπερβεί το μέγιστο όριο.',
      noVisitReason:
        'Δεν υπάρχουν αιτίες επίσκεψης σε αυτή τη γλώσσα. Παρακαλώ επιλέξτε άλλη γλώσσα.'
    }
  },
  error: {
    0x0000: {
      title: 'Σφάλμα',
      text: 'Γενικό σφάλμα: {message}'
    },
    0x0001: {
      title: 'Σφάλμα κατά την επαλήθευση των στοιχείων.',
      text: 'Παρακαλώ εισάγετε τα στοιχεία στα αντίστοιχα πεδία.'
    },
    0x0002: {
      title: 'Επιστρέφοντες εξωτερικοί συνεργάτες',
      text: 'Απαγορεύονται οι επιστρέφοντες εξωτερικοί συνεργάτες.'
    },
    0x0003: {
      title: 'Η σύνδεση με το διακομιστή δεν είναι δυνατή.',
      text: 'Ο διακομιστής δεν είναι διαθέσιμος αυτή τη στιγμή. Παρακαλώ δοκιμάστε αργότερα. Λεπτομέρειες: {details}'
    },
    0x0004: {
      title: 'Δεν υπάρχει κλειδί διεπαφής προγραμματισμού εφαρμογών (ΑΡΙ).',
      text: 'Το κλειδί ΑΡΙ για την επικοινωνία με το διακομιστή δεν είναι διαθέσιμο.'
    },
    0x0005: {
      title: 'Το βασικό URL δεν είναι διαθέσιμο.',
      text: 'Το βασικό URL της διεπαφής χρήστη του eplas δεν είναι διαθέσιμο.'
    },
    0x0006: {
      title: 'Πρόβλημα κατά την εγγραφή.',
      text: 'Η εγγραφή σας στο eplas δεν είναι δυνατή. Παρακαλούμε απευθυνθείτε στον αρμόδιο υπάλληλο.'
    },
    0x0007: {
      title: 'Το διακριτικό σας δεν είναι έγκυρο.',
      text: 'Το διακριτικό σας δεν είναι έγκυρο.'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Κωδικός πρόσβασης',
      username: 'Όνομα χρήστη'
    },
    welcomeMessage: {
      text: 'Καλώς ήλθατε στο eplas!'
    },
    forwardMessage: {
      title: 'Η εγγραφή σας ήταν επιτυχής.',
      text: 'Θα μεταφερθείτε από στιγμή σε στιγμή στον/στην/στο'
    },
    buttonLabel: {
      login: 'Σύνδεση'
    },
    loginMethod: {
      local: 'Σύνδεση',
      saml: 'Εγγραφή εταιρίας',
      ldap: 'Εγγραφή μέσω Windows',
      external: 'Εξωτερικός υπάλληλος',
      pin: 'Εισαγωγή PIN'
    },
    forgotPassword: {
      button: 'Ξέχασα τον κωδικό μου'
    },
    error: {
      noOfflineLogin: 'Η σύνδεση δεν είναι δυνατή εκτός σύνδεσης.',
      username:
        'Η σύνδεση ήταν ανεπιτυχής. Επιβεβαιώστε το όνομα χρήστη και τον κωδικό σας.',
      usernameValidation: 'Εισάγετε όνομα χρήστη.',
      passwordValidation: 'Εισάγετε κωδικό πρόσβασης.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Εισάγετε πάλι τον κωδικό πρόσβασης.',
      setPasswordConfirm: 'Ορισμός κωδικού πρόσβασης.',
      setPasswordTitle:
        'Πρώτη σύνδεση: Παρακαλώ αλλάξτε τον κωδικό πρόσβασής σας.',
      success: 'Επιτυχής αλλαγή κωδικού πρόσβασης.'
    },
    passwordRules: {
      title: 'Οδηγίες για τον κωδικό πρόσβασης.',
      maximumLength: 'Ο κωδικός πρόσβασης είναι πολύ μεγάλος.',
      minimumLength: 'Ο κωδικός πρόβασης είναι πολύ μικρός.',
      minimumDigits:
        'Ο κωδικός πρόβασης δεν περιέχει αρκετά αριθμητικά στοιχεία.',
      minimumUpperCase:
        'Ο κωδικός πρόσβασης δεν περιέχει αρκετά κεφαλαία γράμματα.',
      minimumLowerCase:
        'Ο κωδικός πρόσβασης δεν περιέχει αρκετά πεζά γράμματα.',
      minimumSpecialCharacters:
        'Ο κωδικός πρόσβασης δεν περιέχει αρκετούς ειδικούς χαρακτήρες.',
      allowedSpecialCharacters:
        'Ο κωδικός πρόσβασης περιέχει μη επιτρεπόμενους ειδικούς χαρακτήρες.',
      general: 'Σφάλμα κατά την αλλαγή του κωδικού πρόσβασης.',
      passwordConfirmationValidation: 'Οι δύο κωδικοί πρόσβασης δεν ταιριάζουν.'
    },
    buttonLabel: {
      login: 'Επιστροφή στη σελίδα σύνδεσης'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Ξέχασα τον κωδικό μου',
      email: 'Διεύθυνση e-mail χρήστη',
      confirm: 'Αποστολή e-mail',
      error: 'Η διεύθυνση e-mail δεν είναι έγκυρη.',
      cancel: 'Ακύρωση',
      hint: 'Παρακαλώ εισάγετε τη διεύθυνση e-mail του χρήστη. Θα λάβετε ένα e-mail με ένα σύνδεσμο. Πατώντας πάνω στο link, μπορείτε να επιλέξετε ένα νέο κωδικό πρόσβασης. Εάν δε γνωρίζετε τη διεύθυνση e-mail σας ή ο χρήστης δεν έχει καταχωρήσει κάποια διεύθυνση e-mail, απευθυνθείτε στον αρμόδιο διαχειριστή.',
      emailSent: 'Το email έχει αποσταλεί.'
    }
  },
  passwordReset: {
    title: 'Παρακαλώ αλλάξτε τον κωδικό πρόσβασης.'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Επικαιροποίηση στοιχείων'
    },
    text: {
      welcome: 'Καλώς ήρθατε στο eplas!',
      employeeInfos:
        'Παρακαλώ εισάγετε τα παρακάτω στοιχεία και επιβεβαιώστε την ορθότητά τους.',
      password: 'Παρακαλώ επιλέξτε ένα κωδικό πρόσβασης.'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'εσωτερικό κινητό',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Βρίσκεστε εντός σύνδεσης.',
        offline: 'Βρίσκστε εκτός σύνδεσης.'
      },
      languageOverridden:
        'Η γλώσσα που έχετε επιλέξει δεν είναι διαθέσιμη στο σύστημα. Έχει οριστεί η προεπιλεγμένη γλώσσα.'
    },
    general: {
      print: 'Εκτύπωση',
      search: 'Αναζήτηση',
      close: 'Κλείσιμο',
      create: 'Δημιουργία',
      edit: 'Επεξεργασία',
      copy: 'Αντιγραφή',
      back: 'Πίσω',
      remove: 'Κατάργηση',
      abort: 'Παράλειψη',
      finished: 'Ολοκληρώθηκε',
      ok: 'Ok',
      cancel: 'Ακύρωση'
    }
  },
  router: {
    title: {
      login: 'Εγγραφή'
    }
  }
}
