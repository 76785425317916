
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

/**
 * Supportbereich des Hilfebereichs.
 */
@Component({})
export default class HelpCenterSupport extends Vue {
  /**
   * Farbiges eplas-Logo.
   */
  public logo: string = require('@/assets/logo.svg')

  /**
   * Support Öffnungszeit von
   */
  public supportTimeFrom: string = moment.utc('08:00', 'HH:mm').format('HH:mm')

  /**
   * Support Öffnungszeit bis
   */
  public supportTimeTo: string = moment.utc('17:00', 'HH:mm').format('HH:mm')

  /**
   * Support Erreichbarkeit von
   */
  public supportReachabilityFrom: string = moment
    .utc('14:00', 'HH:mm')
    .format('HH:mm')

  /**
   * Support Erreichbarkeit bis
   */
  public supportReachabilityTo: string = moment
    .utc('15:00', 'HH:mm')
    .format('HH:mm')
}
