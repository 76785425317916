import { HooksObject } from '@feathersjs/feathers'
import { deepMapping } from '../../hooks/obsolete-checklists/collections/deep-mapping.hook'

/**
 * Hooks für die Checklist-Zelle
 */
export const CollectionsDeepHooks: HooksObject = {
  before: [deepMapping],
  after: [],
  error: []
}
