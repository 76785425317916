export const ro = {
  externalLogin: {
    rule: {
      required: 'Vă rugăm să completați toate câmpurile obligatorii.',
      email: 'Adresa de email este invalidă.',
      number: 'Acest câmp trebuie să conțină un număr.'
    },
    buttonLabel: {
      check: 'Verificare',
      proceed: 'Mai departe',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Autentificare',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Informații suplimentare'
    },
    field: {
      lastName: 'Numele de familie',
      firstName: 'Prenumele',
      company: 'Compania',
      country: 'Țara de origine',
      visitReason: 'Motivul vizitei',
      email: 'E-mail',
      id: 'Nr. act de identitate',
      street: 'Strada / nr.',
      zip: 'Codul poștal',
      location: 'Localitatea',
      phone: 'Telefon',
      birthdate: 'Data nașterii',
      training: 'Studii',
      salutation: 'Formula de adresare',
      gender: 'Sexul',
      titel: 'Titlul',
      prefixName: 'Prefix nume',
      suffixName: 'Sufix nume',
      city: 'Orașul',
      workingTime: 'Timpul de lucru',
      workingTasks: 'Angajat ca',
      citizenship: 'Cetățenia',
      healthInsurance: 'Casa de asigurări de sănătate',
      healthInsuranceName: 'Casa de asigurări de sănătate: denumirea',
      healthInsuranceZip: 'Casa de asigurări de sănătate: codul poștal',
      healthInsuranceCity: 'Casa de asigurări de sănătate: orașul',
      healthInsuranceStreet: 'Casa de asigurări de sănătate: strada',
      birthplace: 'Locul nașterii',
      letterSalutation: 'Adresare în corespondență',
      adressAddition: 'Adresă adițional',
      hireDate: 'Data angajării',
      leavingDate: 'Data plecării',
      fax: 'Fax'
    },
    gender: {
      notSpecified: 'nicio informație',
      femail: 'Femeiesc',
      male: 'Bărbătesc',
      divers: 'Divers'
    },
    salutation: {
      notSpecified: '-',
      female: 'Doamna',
      male: 'Domnul'
    },
    error: {
      maxLength: 'Lungimea maximă a fost depășită.',
      noVisitReason:
        'Nu există motive de vizită în această limbă. Vă rugăm să selectați o altă limbă.'
    }
  },
  error: {
    0x0000: {
      title: 'Eroare',
      text: 'Eroare generică: {message}'
    },
    0x0001: {
      title: 'Eroare la validarea datelor introduse',
      text: 'Vă rugăm să introduceți datele corect în câmpul corespunzător.'
    },
    0x0002: {
      title: 'Lucrător extern care revine',
      text: 'Lucrtătorii externi care revin nu sunt permiși.'
    },
    0x0003: {
      title: 'Nu este posibilă conexiunea la server',
      text: 'Serverul nu este disponibil momentan. Vă rugăm să încercați din nou mai târziu. Detalii: {details}'
    },
    0x0004: {
      title: 'Niciun secret API disponibil',
      text: 'Secretul API pentru a comunica cu serverul nu este disponibil.'
    },
    0x0005: {
      title: 'Nu este disponibilă nicio adresă URL de bază',
      text: 'Adresa URL de bază a interfeței eplas nu este disponibilă.'
    },
    0x0006: {
      title: 'Problemă la autentificare',
      text: 'Autentificarea dumneavoastră în eplas nu a putut fi efectuată. Vă rugăm să luați legătura cu persoana dumneavoastră de contact din companie.'
    },
    0x0007: {
      title: 'Din păcate, token-ul dumneavoastră este invalid sau a expirat.',
      text: 'Token-ul dumneavoastră este invalid'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Parola',
      username: 'Nume de utilizator'
    },
    welcomeMessage: {
      text: 'Bine ați venit la eplas!'
    },
    forwardMessage: {
      title: 'Autentificare cu succes',
      text: 'Veți fi redirecționat în orice moment…'
    },
    buttonLabel: {
      login: 'Autentificare'
    },
    loginMethod: {
      local: 'Login',
      saml: 'Login companie',
      ldap: 'Login Windows',
      external: 'Lucrători externi',
      pin: 'Login cu PIN'
    },
    forgotPassword: {
      button: 'Ați uitat parola?'
    },
    error: {
      noOfflineLogin: 'Autentificarea nu este posibilă în modul offline!',
      username:
        'Autentificare eșuată. Verificați numele dumneavoastră de utilizator și parola dumneavoastră.',
      usernameValidation: 'Trebuie menționat un nume de utilizator.',
      passwordValidation: 'Câmpul pentru parolă nu poate fi gol.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Reintroduceți parola',
      setPasswordConfirm: 'Stabiliți parola',
      setPasswordTitle:
        'Prima autentificare: Vă rugăm să schimbați parola dumneavoastră pentru acest lucru',
      success: 'Parolă schimbată cu succes'
    },
    passwordRules: {
      title: 'Reguli privind parolele',
      maximumLength: 'Parola este prea lungă.',
      minimumLength: 'Parola este prea scurtă.',
      minimumDigits: 'Parola nu conține suficiente cifre.',
      minimumUpperCase: 'Parola nu conține suficiente majuscule.',
      minimumLowerCase: 'Parola nu conține suficiente litere mici.',
      minimumSpecialCharacters: 'Parola nu conține caractere speciale.',
      allowedSpecialCharacters: 'Parola conține caractere speciale nepermise.',
      general: 'A apărut o eroare la schimbarea parolei.',
      passwordConfirmationValidation: 'Parolele nu se coincid.'
    },
    buttonLabel: {
      login: 'Către pagina de autentificare'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Am uitat parola',
      email: 'Adresa de e-mail a utilizatorului dumneavoastră',
      confirm: 'Transmitere e-mail',
      error: 'Adresa de e-mail este nevalidă.',
      cancel: 'Anulare',
      hint: 'Vă rugăm să introduceți adresa de e-mail a utilizatorului dumneavoastră. Apoi vi se va transmite un e-mail cu un link. Puteți folosi acest link pentru a alege o nouă parolă. Dacă nu cunoașteți adresa dumneavoastră de e-mail, sau utilizatorul dumneavoastră nu a introdus o adresă de e-mail, vă rugăm să contactați administratorul cu competență pentru dumneavoastră.',
      emailSent: 'A fost trimis un e-mail la adresa de e-mail.'
    }
  },
  passwordReset: {
    title: 'Vă rugăm să modificați parola dumneavoastră'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Actualizare date'
    },
    text: {
      welcome: 'Ne bucurăm că v-ați autentificat la eplas pentru prima dată.',
      employeeInfos:
        'Vă rugăm să completați datele prezentate mai jos, respectiv să verificați dacă acestea sunt corecte.',
      password: 'Vă rugăm să alegeți o parolă'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'intern mobil',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Sunteți online acum.',
        offline: 'Sunteți offline acum.'
      },
      languageOverridden:
        'Limba pe care ați selectat-o anterior nu este disponibilă pe acest sistem. Limba setată este limba implicită.'
    },
    general: {
      print: 'Imprimare',
      search: 'Căutare',
      close: 'Închidere',
      create: 'Creare',
      edit: 'Prelucrare',
      copy: 'Copiere',
      back: 'Înapoi',
      remove: 'Ștergere',
      abort: 'Anulare',
      finished: 'Gata',
      ok: 'OK',
      cancel: 'Anulare'
    }
  },
  router: {
    title: {
      login: 'Autentificare'
    }
  }
}
