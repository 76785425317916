/**
 * Die verfügbaren und möglichen Einstellungen für die Verbindungen von eplas
 */
export enum Connection {
  /**
   * Client festlegen lassen ob dieser Online oder Offline ist
   */
  Auto = 0,
  /**
   * Client als Offline behandeln
   */
  Offline = -1,
  /**
   * Client als Online behandeln
   */
  Online = 1
}
