import { NotificationState } from './notification-state.interface'

export const state: NotificationState = {
  showNotification: false,
  lock: false,
  text: '',
  type: '',
  queue: [],
  timeout: 2000,
  btnText: 'Schließen',
  btnReturn: false,
  data: {},
  callback: (): void => {},
  icon: ''
}
