/**
 * Gibt eine Farbe zurück, die aus dem Benutzernamen generiert wird
 *
 * @param backgroundColor - HEX-Wert der Hintergrundfarbe (bspw. #123456).
 * @returns Generierte Farbe auf Grund des Namens
 */
export function getTextColor(backgroundColor: string): string {
  const hexcolor = backgroundColor.replace('#', '')
  const r = parseInt(hexcolor.substr(0, 2), 16)
  const g = parseInt(hexcolor.substr(2, 2), 16)
  const b = parseInt(hexcolor.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black--text' : 'white--text'
}
