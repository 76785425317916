import { Column } from '@/interfaces/ContentLoader/column.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { DatabaseComparator } from '@/constants/database-comparator.enum'
import { Conversion } from '@/constants/conversion.enum'

/**
 * Prüft ob das angegebene Object ein [[Column]]-Interface ist.
 *
 * @param obj - Das Objekt bzw. Interfaces welches geprüft werden soll.
 * @returns `true` bei [[Column]]-Interfaces, sonst `false`
 */
export function isColumn(obj?: object): obj is Column {
  return (
    typeof obj === 'object' &&
    'formatAs' in obj &&
    'property' in obj &&
    typeof (obj as Column).formatAs === 'string' &&
    typeof (obj as Column).property === 'string'
  )
}

/**
 * Prüft ob das angegebene Array nur [[Column]]-Interface enthält.
 *
 * @param arr - Das Array welches geprüft werden soll.
 * @returns Enthält das Array nur [[Column]]-Interfaces `true`, sonst `false`
 */
export function isColumnArray(arr?: object[]): arr is Column[] {
  const value = arr
  return Array.isArray(value) && (value.length === 0 || value.every(isColumn))
}

/**
 * Prüft ob das angegebene Object ein [[Filter]]-Interface ist.
 *
 * @param obj - Das Objekt bzw. Interfaces welches geprüft werden soll.
 * @returns `true` bei [[Filter]]-Interfaces, sonst `false`
 */
export function isFilter(obj?: object): obj is Filter {
  const item: Filter = obj as Filter
  return (
    typeof obj === 'object' &&
    'column' in item &&
    typeof item.column === 'string' &&
    'comparator' in item &&
    Object.values(DatabaseComparator).indexOf(item.comparator) !== -1 &&
    'values' in item &&
    Array.isArray(item.values) &&
    (item.values.length === 0 ||
      item.values.every((x): boolean => typeof x === 'string' || !x)) &&
    (!item.conversion ||
      Object.values(Conversion).indexOf(item.conversion) !== -1)
  )
}

/**
 * Prüft ob das angegebene Array nur Einträge von [[Filter]]-Interface enthält.
 *
 * @param arr - Array welches gepüft werden soll
 * @returns Ob eine Lise nur [[Filter]]-Interfaces enthält.
 */
export function isFilterList(arr?: object[]): arr is Filter[] {
  return Array.isArray(arr) && (arr.length === 0 || arr.every(isFilter))
}

/**
 * Prüft ob das angegebene Array nur Einträge von Typ `string` enthält.
 *
 * @param arr - Array welches gepüft werden soll
 * @returns Ob eine Lise die nur den Typ `string` enthält.
 */
export function isStringList(arr?: unknown[]): arr is string[] {
  return (
    Array.isArray(arr) &&
    (arr.length === 0 || arr.every((x): boolean => typeof x === 'string'))
  )
}
