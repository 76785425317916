export const bg = {
  externalLogin: {
    rule: {
      required: 'Моля, попълнете всички задължителни полета.',
      email: 'Имейл адресът е невалиден.',
      number: 'Това поле трябва да съдържа само един номер.'
    },
    buttonLabel: {
      check: 'Проверка',
      proceed: 'Напред',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Вписване',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'повече информация'
    },
    field: {
      lastName: 'Фамилно име',
      firstName: 'Име',
      company: 'Фирма',
      country: 'Страна на произход:',
      visitReason: 'Причина за посещението',
      email: 'Имейл',
      id: 'Документ за самоличност №',
      street: 'Улица №',
      zip: 'Пощенски код',
      location: 'Град',
      phone: 'Телефон',
      birthdate: 'Дата на раждане',
      training: 'Образование',
      salutation: 'Обръщение',
      gender: 'Пол',
      titel: 'Титла',
      prefixName: 'Префикс на името',
      suffixName: 'Суфикс на името',
      city: 'Град',
      workingTime: 'Работно време',
      workingTasks: 'Работи като',
      citizenship: 'Националност',
      healthInsurance: 'Здравноосигурителна каса',
      healthInsuranceName: 'Име на здравноосигурителната каса',
      healthInsuranceZip: 'Здравноосигурителна каса: пощенски код',
      healthInsuranceCity: 'Здравноосигурителна каса: град',
      healthInsuranceStreet: 'Здравноосигурителна каса: улица',
      birthplace: 'Място на раждане',
      letterSalutation: 'Обръщение на писмото',
      adressAddition: 'Доп. Адрес',
      hireDate: 'Дата на създаване',
      leavingDate: 'Дата на излизане',
      fax: 'Факс'
    },
    gender: {
      notSpecified: 'Няма информация',
      femail: 'женски',
      male: 'мъжки',
      divers: 'различен'
    },
    salutation: {
      notSpecified: '-',
      female: 'Госпожа',
      male: 'Господин'
    },
    error: {
      maxLength: 'Максималната дължина беше превишена.',
      noVisitReason:
        'На този език няма причини за посещение. Моля, изберете друг език.'
    }
  },
  error: {
    0x0000: {
      title: 'Грешка',
      text: 'Обща грешка: {message}'
    },
    0x0001: {
      title: 'Грешка при валидиране на въведеното',
      text: 'Моля, въведете данните правилно в съответното поле.'
    },
    0x0002: {
      title: 'Връщащ се външен служител',
      text: 'Връщащи се външни служители не са разрешени.'
    },
    0x0003: {
      title: 'Няма достъп до сървъра',
      text: 'В момента няма достъп до сървъра. Моля, опитайте по-късно още веднъж. Детайли: {details}'
    },
    0x0004: {
      title: 'Не е наличен API-Secret',
      text: 'API-Secret за комуникация със сървъра не е наличен.'
    },
    0x0005: {
      title: 'Не е наличен базов URL',
      text: 'Базовият URL адрес на frontend-а на eplas не е наличен.'
    },
    0x0006: {
      title: 'Проблем при вписването',
      text: 'Не можете да впишете в eplas. Моля, обърнете се лично към съответното лице за контакт в компанията.'
    },
    0x0007: {
      title: 'Вашият токен е невалиден',
      text: 'Your token is invalid'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Парола',
      username: 'Потребителско име'
    },
    welcomeMessage: {
      text: 'Добре дошли в eplas!'
    },
    forwardMessage: {
      title: 'Вписването е успешно!',
      text: 'Ще бъдете препратени всеки момент...'
    },
    buttonLabel: {
      login: 'Вписване'
    },
    loginMethod: {
      local: 'Вписване',
      saml: 'Вход в компанията',
      ldap: 'Вход в Windows',
      external: 'Външен служител',
      pin: 'Въвеждане на ПИН'
    },
    forgotPassword: {
      button: 'Забравили сте паролата си?'
    },
    error: {
      noOfflineLogin: 'В офлайн режим не може да се осъществи вписване!',
      username:
        'Вписването е неуспешно. Проверете вашето потребителско име и парола.',
      usernameValidation: 'Изисква се потребителско име.',
      passwordValidation: 'Изисква се парола.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Повторете паролата',
      setPasswordConfirm: 'Задайте парола',
      setPasswordTitle: 'Първо вписване: Моля, сменете паролата си',
      success: 'Паролата е променена успешно'
    },
    passwordRules: {
      title: 'Политика на паролите',
      maximumLength: 'Паролата е твърде дълга.',
      minimumLength: 'Паролата е твърде кратка.',
      minimumDigits: 'Паролата не съдържа достатъчно цифри.',
      minimumUpperCase: 'Паролата не съдържа достатъчно главни букви.',
      minimumLowerCase: 'Паролата не съдържа достатъчно малки букви.',
      minimumSpecialCharacters:
        'Паролата не съдържа достатъчно специални знаци.',
      allowedSpecialCharacters: 'Паролата съдържа неразрешени специални знаци.',
      general: 'При промяната на паролата възникна грешка.',
      passwordConfirmationValidation: 'Двете пароли не съвпадат.'
    },
    buttonLabel: {
      login: 'Към страницата за вписване'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Забравили сте паролата си?',
      email: 'Имейл адрес на вашия потребител',
      confirm: 'изпращане на имейл',
      error: 'Имейл адресът е невалиден.',
      cancel: 'Отказ',
      hint: 'Моля, въведете имейл адреса на вашия потребител. След това ще ви бъде изпратен имейл с линк. Можете да изберете нова парола чрез този линк. Ако не знаете адреса си на електронна поща или потребителят не е въвел адрес на електронна поща, моля, свържете се с администратора си.',
      emailSent: 'Имейлът беше изпратен'
    }
  },
  passwordReset: {
    title: 'Моля, променете вашата парола'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Актуализиране на данните'
    },
    text: {
      welcome: 'Хубаво е, че за първи път влизате в eplas.',
      employeeInfos:
        'Моля, попълнете показаните по-долу данни респ. Проверете, дали са верни.',
      password: 'Моля, изберете парола'
    },
    field: {
      pin: 'ПИН',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'вътрешен мобилен',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Сега сте онлайн.',
        offline: 'Сега сте офлайн.'
      },
      languageOverridden:
        'Езикът, който сте избрали преди това, не е наличен на тази система. Езикът е зададен като език по подразбиране.'
    },
    general: {
      print: 'Печат',
      search: 'Търсене',
      close: 'Затваряне',
      create: 'Създаване',
      edit: 'Обработка',
      copy: 'Копиране',
      back: 'Назад',
      remove: 'Премахни',
      abort: 'Отказ',
      finished: 'Готово',
      ok: 'ОК',
      cancel: 'Отказ'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
