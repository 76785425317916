
import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Dark from '@/mixins/dark'
import UserAvatar from '@/components/UserAvatar/UserAvatar.vue'
import CountryFlag from '@/components/CountryFlag/CountryFlag.vue'
import { logout, logoutAll } from '@/helpers/logout'
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue'

const UserStore = namespace('user')

/**
 * Benutzerbezogenes Menü mit Einstellungen zur Oberfläche, Möglichkeiten zum
 * Ausloggen und zum Ändern des Passworts und der Sprache.
 */
@Component({
  components: {
    UserAvatar,
    CountryFlag,
    LanguagePicker
  }
})
export default class UserMenu extends Mixins(Dark) {
  /**
   * Benutzername des angemeldeten Benutzers.
   */
  @UserStore.Getter('username')
  public username!: string

  /**
   * Gibt an, ob gerade ein Abmeldevorgang stattfindet.
   */
  public logoutInProgress = false

  /**
   * Meldet den angemeldeten Benutzer ab.
   *
   * @param all - Wenn `true`, wird der Benutzer von allen Sessions/Geräten
   * abgemeldet.
   */
  public async logout(all = false): Promise<void> {
    this.logoutInProgress = true

    if (all) {
      await logoutAll()
    } else {
      await logout()
    }

    this.$router.push({ name: 'login' })

    this.logoutInProgress = false

    this.$emit('canClose')
  }

  /**
   * Prüft ob der Client als Online erkannt worden ist.
   *
   * @returns `true`, wenn Client online ist.
   */
  public get isOnline(): boolean {
    return this.$store.getters['connectivity/online']
  }
}
