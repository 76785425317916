import { getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'
import { UITheme } from '@/store/ui/theme.interface'
import {
  DefaultLightTheme,
  DefaultDarkTheme,
  TypeThemeList,
  TypeTheme
} from '@/constants/theme'
import store from '@/store'

/**
 * Lädt die Theme-Informationen vom Server und erweitert das Ergebnis um die
 * Fallback Daten. Ebenfalls werden die Daten im UI-Store aktualisiert.
 *
 * Diese Funktion spricht Online- und Offline-Service direkt an, denn an diesem
 * Punkt wurde noch nicht bestimmt, ob der Online- oder Offline-Service
 * verwendet werden soll.
 *
 * @param refreshStore - Die Einstellungen im UI-Store auch aktualisieren. Dies
 * wird standardmäßig durchgeführt.
 * @returns Objekt mit den Themen-Daten.
 */
export async function loadThemeData(
  refreshStore = true
): Promise<TypeThemeList> {
  const result: TypeThemeList = { dark: {}, light: {} }
  let themeCfg: TypeThemeList | undefined

  try {
    // Online-Service abfragen
    const service = await getService<TypeThemeList>('theme', Connection.Online)

    const result = await service.find()

    if (Array.isArray(result) && result[0]) {
      themeCfg = result[0]
    }
  } catch (ex) {
    // Offline-Service anfragen, wenn die Anfrage an den Online-Service
    // fehlschlägt
    const service = await getService<TypeThemeList>('theme', Connection.Offline)

    const result = await service.find()
    if ('data' in result && Array.isArray(result.data) && result.data[0]) {
      themeCfg = result.data[0]
    }
  } finally {
    const storeTheme: UITheme = { light: {}, dark: {} }
    let darkTheme: TypeTheme | undefined
    let lightTheme: TypeTheme | undefined

    if (themeCfg) {
      // Einstellungen vom Dark-Theme
      if ('dark' in themeCfg && themeCfg.dark) {
        darkTheme = Object.assign({}, DefaultDarkTheme, themeCfg.dark)

        if ('backgroundImage' in darkTheme) {
          if (typeof darkTheme.backgroundImage === 'string') {
            storeTheme.dark.backgroundImage = darkTheme.backgroundImage.trim()
          }

          darkTheme.backgroundImage = undefined
        }

        if ('backgroundColor' in darkTheme) {
          if (typeof darkTheme.backgroundColor === 'string') {
            storeTheme.dark.backgroundColor = darkTheme.backgroundColor
            darkTheme.loginBackgroundColor = darkTheme.backgroundColor
          }

          darkTheme.backgroundColor = undefined
        }

        if (
          'customLogo' in darkTheme &&
          typeof darkTheme.customLogo === 'string' &&
          !!darkTheme.customLogo
        ) {
          storeTheme.dark.customLogo = darkTheme.customLogo
        }
        darkTheme.customLogo = undefined

        if (
          'loginLogo' in darkTheme &&
          typeof darkTheme.loginLogo === 'string' &&
          !!darkTheme.loginLogo
        ) {
          storeTheme.dark.loginLogo = darkTheme.loginLogo
        }
        darkTheme.loginLogo = undefined
      }

      // Einstellungen vom Light-Theme
      if ('light' in themeCfg && themeCfg.light) {
        lightTheme = Object.assign({}, DefaultLightTheme, themeCfg.light)

        if ('backgroundImage' in lightTheme) {
          if (typeof lightTheme.backgroundImage === 'string') {
            storeTheme.light.backgroundImage = lightTheme.backgroundImage.trim()
          }

          lightTheme.backgroundImage = undefined
        }

        if ('backgroundColor' in lightTheme) {
          if (typeof lightTheme.backgroundColor === 'string') {
            storeTheme.light.backgroundColor = lightTheme.backgroundColor
            lightTheme.loginBackgroundColor = lightTheme.backgroundColor
          }

          lightTheme.backgroundColor = undefined
        }

        if (
          'customLogo' in lightTheme &&
          typeof lightTheme.customLogo === 'string' &&
          !!lightTheme.customLogo
        ) {
          storeTheme.light.customLogo = lightTheme.customLogo
        }
        lightTheme.customLogo = undefined

        if (
          'loginLogo' in lightTheme &&
          typeof lightTheme.loginLogo === 'string' &&
          !!lightTheme.loginLogo
        ) {
          storeTheme.light.loginLogo = lightTheme.loginLogo
        }
        lightTheme.loginLogo = undefined
      }
    }

    if (refreshStore) {
      store.commit('ui/setTheme', storeTheme)
    }

    result.dark = darkTheme || { ...DefaultDarkTheme }
    result.light = lightTheme || { ...DefaultLightTheme }
  }

  return result
}
