import { HookContext } from '@feathersjs/feathers'
import { Connection } from '../connection.enum'
import { OfflineEntry } from '../offline-entry.interface'
import { getService } from '../../helpers/feathers'
import store from '@/store'

/**
 * Dieser Feathers-Hook erneuert und löscht den gespeicherten Offline-Cache
 * von eplas. Dieser wird aber nur ausgeführt, wenn die Authentifizierung des
 * Benutzers bestätigt wird, es werden die Daten gespeichert, oder der Benutzer
 * sich erfolgreich abgemeldet hat, es werden die Daten gelöscht.
 *
 * **ACHTUNG:** Speichern und Löschen der Offline-Daten MUSS ASYNCHRONE
 * durchgeführt werden!
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, mit angepassten Datensatz bzw. Ergebnis
 */
export function refreshOfflineCache(context: HookContext): HookContext {
  if (
    context.path === 'auth' &&
    context.type === 'after' &&
    store.getters['connectivity/online']
  ) {
    const paths = [
      'offline-data/employees',
      'offline-data/checklist-categories',
      'collections'
    ]

    for (const path of paths) {
      if (path === 'collections') {
        if (context.method === 'create') {
          Promise.all([
            getService<OfflineEntry>('checklist/procedure'),
            getService<OfflineEntry>('audits/offline', Connection.Offline),
            getService<OfflineEntry>('checklists-collections/deep')
          ]).then(
            async ([serviceNew, serviceAudits, serviceData]): Promise<void> => {
              const userID = context.result.user.id as number
              const requests = []
              const total = await serviceAudits
                .find({ query: { $limit: 0, createdBy: userID } })
                .then((result): number =>
                  'data' in result && typeof result.total === 'number'
                    ? result.total
                    : 0
                )

              requests.push(
                serviceNew.find().then(
                  (result): unknown[] =>
                    (Array.isArray(result) ? result : [])
                      .filter((x): boolean => !!x.offline_available)
                      .map((x): unknown => x.id as unknown),
                  (): unknown[] => []
                )
              )

              for (let i = 0; i < total; i += 100) {
                requests.push(
                  serviceAudits
                    .find({
                      query: { $offset: i, $limit: 100, createdBy: userID }
                    })
                    .then(
                      (x): unknown[] =>
                        x && 'data' in x && Array.isArray(x.data)
                          ? x.data.map((x): unknown[] => x.checklists)
                          : [],
                      (): unknown[] => []
                    )
                )
              }

              Promise.all(requests).then(
                async (result): Promise<void> => {
                  const checklistID: number[] = result
                    .flat(4)
                    .filter(
                      (x, i, o): boolean =>
                        typeof x === 'number' && x > 0 && o.indexOf(x) === i
                    ) as number[]

                  for (const id of checklistID) {
                    await serviceData.get(id)
                  }
                },
                (): void => {}
              )
            },
            (): void => {}
          )
        }
      } else {
        getService<OfflineEntry>(path, Connection.Offline).then(
          async (offlineService): Promise<void> => {
            const resultTotal = await offlineService
              .find({ query: { $limit: 0 } })
              .then((result): number =>
                'data' in result && typeof result.total === 'number'
                  ? result.total
                  : 0
              )

            if (context.method === 'remove') {
              if (path !== 'checklists-collections/deep' && resultTotal) {
                await offlineService.remove(null)
              }
            } else if (context.method === 'create') {
              if (resultTotal <= 0) {
                const onlineService = await getService<OfflineEntry>(path)

                const result = await onlineService.find({
                  query: { $limit: 0, $orderBy: 'id' }
                })

                if (Array.isArray(result)) {
                  await offlineService.create(result)
                }
              }
            }
          }
        )
      }
    }
  }

  return context
}
