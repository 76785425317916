import { Component, Vue } from 'vue-property-decorator'
import { Permission } from '@nxt/permissions'
import { PermissionHelper } from '@/helpers/permission-helper'

/**
 * Mixin zum prüfen von Berechtigungen des angemeldeten Benutzers.
 */
@Component({})
export class PermissionMixin extends Vue {
  /**
   * Prüft, ob der angemeldete Benutzer die erforderliche Berechtigung hat.
   *
   * @param permission - Erforderliche Berechtigung.
   * @returns true, wenn der Benutzer die erforderliche Berechtigung hat,
   * ansonsten false.
   */
  public hasPermission(permission: Permission): boolean {
    return PermissionHelper.hasPermission(permission)
  }

  /**
   * Prüft, ob der angemeldete Benutzer alle erforderlichen Berechtigungen hat.
   *
   * @param permissions - Alle erforderlichen Berechtigungen.
   * @returns true, wenn der Benutzer alle erforderlichen Berechtigungen hat,
   * ansonsten false.
   */
  public hasPermissions(permissions: Permission[]): boolean {
    return PermissionHelper.hasPermissions(permissions)
  }
}
