import { Component, Vue } from 'vue-property-decorator'

/**
 * Mixin für das Ladeanimation-Overlay
 */
@Component({})
export class OverlayMixin extends Vue {
  /**
   * Schaltet die Ladeanimation ein
   */
  public toggleLoadingAnimationOn(): void {
    const overlay = document.getElementById('overlay')
    if (overlay && !overlay.classList.contains('visible')) {
      overlay.classList.toggle('visible')
    }
  }

  /**
   * Schaltet die Ladeanimation aus
   */
  public toggleLoadingAnimationOff(): void {
    const overlay = document.getElementById('overlay')
    if (overlay && overlay.classList.contains('visible')) {
      overlay.classList.toggle('visible')
    }
  }
}
