
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { CountryCode } from '@/constants/country-code.enum'
import CountryFlag from '@/components/CountryFlag/CountryFlag.vue'
import { RawLocation } from 'vue-router'

const LanguageStore = namespace('language')

/**
 * Spracheintrag für das Select.
 */
interface LanguageItem {
  /**
   * Name der Sprache in Englisch.
   */
  name: string

  /**
   * Sprachkürzel.
   */
  value: string
}

/**
 * Select zur Auswahl der aktiven Sprache.
 */
@Component({
  components: {
    CountryFlag
  }
})
export default class LanguagePicker extends Vue {
  /**
   * Gibt an, ob diese Instanz für die Login-Maske verwendet werden soll.
   * Beeinflusst die Darstellung und Sprachliste.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public login!: boolean

  /**
   * Gibt an, ob die Sprach aus der URL ausgelesen werden soll
   */
  @Prop({ type: Boolean, required: false, default: false })
  public byUrl!: boolean

  /**
   * Verwendet statt `<v-select>` eine `<v-list-group>`.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public list!: boolean

  /**
   * Auswählbare Sprachen nach dem Login.
   */
  public limitedLanguageList: string[] = [CountryCode.DE, CountryCode.EN]

  /**
   * Liste von aktiven Sprachen.
   */
  @LanguageStore.State('active')
  public activeLanguageList!: CountryCode[]

  /**
   * Gibt an, ob die `<v-list-group>` beim `list` Modus momentan geöffnet ist.
   */
  public listGroupOpen = false

  /**
   * Setzt die Sprache auf die Sprache aus der URL
   */
  public created(): void {
    if (this.byUrl) {
      this.setLocaleFromRoute()
    }
  }

  /**
   * Erzeugt ein Array mit Select-Items basierend auf den Sprachkürzeln.
   *
   * @returns Pfad zum Hintergrundbild.
   */
  public get languages(): LanguageItem[] {
    const list = this.activeLanguageList || this.limitedLanguageList

    return list.map(value => ({
      name: this.$t(`system.languages.${value}`).toString(),
      value
    }))
  }

  /**
   * Gibt das Label bzw. den Namen der aktiven Sprache zurück.
   *
   * @returns Label der Sprache (bspw. "Deutsch").
   */
  public get languageLabel(): string {
    return (
      this.languages.find(language => language.value === this.$i18n.locale)
        ?.name || '?'
    )
  }

  /**
   * Ruft die Mutation language/enableOverriddenByUser aus dem Store auf.
   */
  @LanguageStore.Mutation('enableOverriddenByUser')
  public enableOverriddenByUser(): void {}

  /**
   * Setzt die i18n Locale auf die in der Route angegebene Locale, falls der
   * Parameter gegeben und ein unterstütztes Sprachkürzel ist.
   */
  public setLocaleFromRoute(): void {
    const { locale } = this.$route.params

    if (
      !locale ||
      (this.$store.state.language.active as string[]).indexOf(locale) === -1
    ) {
      this.updateRouteLocale()
      return
    }

    this.$i18n.locale = locale

    this.$store.commit('language/enableOverriddenByUser')
  }

  /**
   * Aktualisiert das Localekürzel der Route, wenn sich die Sprache via i18n
   * ändert.
   */
  @Watch('$i18n.locale')
  public updateRouteLocale(): void {
    if (this.byUrl) {
      this.$route.params.locale = this.$i18n.locale
      sessionStorage.setItem('language', this.$i18n.locale)
      this.$router.push(this.$route as RawLocation)
    }
  }

  /**
   * Event-Handler für Klick auf ein Item (bei `list`-Modus). Ändert die aktive
   * Sprache.
   *
   * @param language - Spracheintrag, auf den gewechselt werden soll.
   */
  public handleItemClick(language: LanguageItem): void {
    this.$i18n.locale = language.value

    sessionStorage.setItem('language', language.value)

    this.enableOverriddenByUser()

    this.listGroupOpen = false
  }
}
