import { ActionTree } from 'vuex'
import { NotificationState } from './notification-state.interface'
import { NotificationPayload } from './notification-payload.interface'
import { RootState } from '../root-state.interface'

/**
 * Füllt eine Payload mit unausgefüllten Optionen mit Standardwerten
 *
 * @param payload - Payload für Toaster
 * @returns NotificationPayload - Payload mit Standardwerten
 */
function addingStandard(payload: NotificationPayload): NotificationPayload {
  if (!payload.timeout) {
    payload.timeout = 2000
  }
  if (!payload.text) {
    switch (payload.type) {
      case 'success':
        payload.text = 'Aktion erfolgreich'
        break
      case 'info':
        payload.text = 'Information'
        break
      case 'error':
        payload.text = 'Ein Fehler ist aufgetreten'
        break
      case 'warning':
        payload.text = 'Achtung'
        break
      default:
        payload.type = 'error'
        payload.text = 'Ein Fehler ist aufgetreten'
        break
    }
  }
  if (!payload.btnText && payload.btnText !== '') {
    payload.btnText = 'Schließen'
  }
  return payload
}

export const actions: ActionTree<NotificationState, RootState> = {
  /**
   * Führt je nach Bedarf die Mutation enableSnack, oder disableSnack aus, je
   * nachdem, ob eine entsprechende Payload mitgeschickt wird. Dann wir die
   * Snackbar angezeigt, oder wieder ausgeblendet
   *
   * @param payload - NotificationPayload-Objekt mit Optionen für den Toast
   */
  toggleToast({ state, commit }, payload: NotificationPayload): void {
    if (payload) {
      const filledPayload = addingStandard(payload)
      if (!state.lock && state.queue.length < 1) {
        commit('enableToast', filledPayload)
      } else if (filledPayload.timeout) {
        commit('enqueue', filledPayload)
        if (!state.lock) {
          setTimeout((): void => {
            commit('enableToast', state.queue[0])
            commit('dequeue')
          }, filledPayload.timeout * state.queue.length)
        } else {
          const lockInterval = setInterval((): void => {
            if (!state.lock) {
              commit('enableToast', state.queue[0])
              commit('dequeue')
              clearInterval(lockInterval)
            }
          }, 100)
        }
      } else {
        commit('enqueue', filledPayload)
        setTimeout((): void => {
          commit('enableToast', state.queue[0])
          commit('dequeue')
        }, 2000 * state.queue.length)
      }
    } else {
      commit('disableToast')
      state.callback()
    }
  }
}
