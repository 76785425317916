import { ConnectivityState } from './connectivity-state.interface'

export const getters = {
  /**
   * Gibt `true` zurück, wenn der Client online ist, ansonsten `false`.
   *
   * @param state - [[ConnectivityState]] aus dem Store.
   * @returns Onlinestatus.
   */
  online: (state: ConnectivityState): boolean => state.online,

  /**
   * Gibt `true` zurück, wenn der Client offline ist, ansonsten `false`.
   *
   * @param state - [[ConnectivityState]] aus dem Store.
   * @returns Offilnestatus.
   */
  offline: (state: ConnectivityState): boolean => !state.online
}
