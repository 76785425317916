import { Application, Service } from '@feathersjs/feathers'
import feathers, { rest, authentication } from '@feathersjs/client'
import store from '@/store'
import { app as appOffline } from '../offline/feathers'
import { Connection } from '../offline/connection.enum'
import { saveEntriesHook } from '../offline/hooks/opportunistic-update/save-entries.hook'
import { refreshOfflineCache } from '../offline/hooks/refresh-offline-cache.hook'
import { authOffline } from '../offline/hooks/auth-offline.hook'
import { cookieStorage } from './cookie-storage'
import { config } from '@/config'
import { PermissionHelper } from '@/helpers/permission-helper'

// Stellt die Verbindungen zu den Services vom Server (Online) und Client
// (Offline) her und bereitet die Route zur Authentifizierung vor

/**
 * Feathers-Instanz für den Online-Modus
 */
const appOnline = feathers()

const preAuthQueue: Function[] = []
const fnQueue: Function[] = []

let preAuth = false
let ready = false

config.client().then((clientConfig): void => {
  const restClient = rest(clientConfig.api.url)

  appOnline.configure(restClient.fetch(window.fetch))

  preAuth = true

  let fn: Function | void

  while ((fn = preAuthQueue.pop())) {
    fn()
  }

  appOnline.on('authenticated', async (response): Promise<void> => {
    localStorage.setItem('lastUser', JSON.stringify(response.user))

    await store.dispatch('user/updateUser', response.user)
    await PermissionHelper.revokePermissionsOnInvalidLicense()
    ready = true

    let fn: Function | void

    while ((fn = fnQueue.pop())) {
      fn()
    }
  })
})

appOnline.configure(
  authentication({
    storage: cookieStorage,
    path: '/auth'
  })
)

// Fügt globale Hooks der Feathers-Instanz hinzu die innerhalb des Online-Modus
// verwendet wird
appOnline.hooks({
  before: authOffline,
  after: [authOffline, refreshOfflineCache, saveEntriesHook]
})

/**
 * Ruft die übergebene Funktion auf, sobald die Konfiguration geladen wurde.
 *
 * @param fn - Aufzurufende Funktion.
 */
export function preAuthReady(fn: Function): void {
  if (preAuth) {
    fn()
  } else {
    preAuthQueue.push(fn)
  }
}

/**
 * Ruft die übergebene Funktion auf, sobald das Benutzer-Objekt geladen wurde.
 *
 * @param fn - Aufzurufende Funktion.
 */
export function onReady(fn: Function): void {
  if (ready) {
    fn()
  } else {
    fnQueue.push(fn)
  }
}

/**
 * Gibt [[appOnline]] zurück, sobald die Anwendung [[ready]] ist.
 *
 * @returns [[appOnline]]-Objekt.
 */
function appOnReady(): Promise<Application<unknown>> {
  return new Promise((resolve): void => {
    preAuthReady((): void => {
      resolve(appOnline)
    })
  })
}

/**
 * Gibt die passende Feathers-Instanz zurück, jenachdem ob der Online oder
 * Offline-Modus gerade benötigt wird.
 *
 * @param mode - Modus festlegen/erzwingen
 * @returns Passenden Feathers-Instanz für Online oder Offline
 */
export async function getApp(
  mode: Connection = Connection.Auto
): Promise<Application<unknown>> {
  if (
    (mode === Connection.Auto && store.getters['connectivity/offline']) ||
    mode === Connection.Offline
  ) {
    return appOffline
  }

  if (ready) {
    return appOnline
  }

  const app = await appOnReady()

  return app
}

/**
 * Gibt die den Service aus der Feathers-Instanz zurück, die gerade anhand des
 * aktuellen Verbindungsmodus passen würde - also entweder die Online oder
 * Offline-Variante.
 *
 * @param name - Name des Service
 * @param mode - Modus festlegen/erzwingen
 * @returns Angegebener Service aus dem Online- oder Offline-Modus
 */
export async function getService<T = unknown>(
  name: string,
  mode: Connection = Connection.Auto
): Promise<Service<T>> {
  const app = await getApp(mode)

  return app.service(name)
}

/**
 * Prüft ob der angegebene Service als Offline-Service exestiert
 *
 * @param name - Name des Service
 * @returns TRUE wenn Offline-Service exestiert sonst FALSE
 */
export function existsAsOfflineService(name: string): boolean {
  return Object.keys(appOffline.services).indexOf(name) !== -1
}
