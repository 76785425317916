import { MutationTree } from 'vuex'
import { UIState } from './ui-state.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { UITheme } from './theme.interface'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { MenuBadgeSet, MenuBadgeItem } from './menu-badge.interface'

export const mutations: MutationTree<UIState> = {
  /**
   * Aktiviert die mobile Suche.
   *
   * @param state - State des UI-Moduls.
   */
  enableMobileSearch(state: UIState): void {
    state.mobileSearch = true
  },
  /**
   * Deaktiviert die mobile Suche und setzt den globalen Suchstring auf ''.
   *
   * @param state - State des UI-Moduls.
   */
  disableMobileSearch(state: UIState): void {
    state.globalSearch = ''
    state.mobileSearch = false
  },
  /**
   * Deaktiviert die mobile Suche, wenn der globale Suchstring leer ist.
   *
   * @param state - State des UI-Moduls.
   */
  disableMobileSearchIfEmpty(state: UIState): void {
    if (!state.globalSearch) {
      state.mobileSearch = false
    }
  },
  /**
   * Zeigt die globalen Filter an.
   *
   * @param state - State des UI-Moduls.
   */
  showGlobalFilter(state: UIState): void {
    state.showGlobalFilter = true
  },
  /**
   * Blendet die globalen Filter aus.
   *
   * @param state - State des UI-Moduls.
   */
  hideGlobalFilter(state: UIState): void {
    state.showGlobalFilter = false
  },
  /**
   * Setzt den globalen Suchstring.
   *
   * @param state - State des UI-Moduls.
   * @param term - Suchstring.
   */
  setGlobalSearchTerm(state: UIState, term: string): void {
    if (!term) {
      term = ''
    }

    state.globalSearch = term
  },
  /**
   * Setzt die Spalten, die von der globalen Suche verwendet werden dürfen.
   *
   * @param state - State des UI-Moduls.
   * @param columnName - Name der Spalten.
   */
  setGlobalSearchColumns(state: UIState, columnName: string[] | string): void {
    state.globalSearchColumns = Array.isArray(columnName)
      ? columnName
          .map((x): string => (typeof x === 'string' ? x.trim() : ''))
          .filter((x): boolean => x !== '')
      : typeof columnName === 'string' && columnName.trim() !== ''
      ? [columnName.trim()]
      : []
  },
  /**
   * Spalten des angezeigten Tabellen-Sourcedata-Objektes
   *
   * @param state - State des UI-Moduls.
   * @param columns - Vorhandene Tabellenspalten.
   */
  setColumns(state: UIState, columns: Column[]): void {
    state.columns = columns
  },
  /**
   * Legt die Filter fest in denen in der angezeigten Tabelle gesucht werden
   * soll
   *
   * @param state - State des UI-Moduls.
   * @param filters - Filter durch den Benutzer.
   */
  setFilters(state: UIState, filters: Filter[]): void {
    state.filters = filters
  },
  /**
   * Legt die Einstellungen für die Themes fest.
   *
   * @param state - State des UI-Moduls.
   * @param theme - Einstellungen für die Themes
   */
  setTheme(state: UIState, theme: UITheme): void {
    state.theme = theme
  },
  /**
   * Setzt den Wert von einem Badge des Menüs.
   *
   * @param state - State des UI-Moduls.
   * @param item - Menüeintrag und der Wert für dessen Badges.
   */
  setMenuBadge(state: UIState, item: MenuBadgeItem): void {
    if (typeof item.value === 'undefined') {
      if (state.menuBadges.hasOwnProperty(item.key)) {
        delete state.menuBadges[item.key]
      }
    } else {
      state.menuBadges[item.key] = item.value
    }
  },
  /**
   * Setzt den Wert aller Badges im den Menüs neu.
   *
   * @param state - State des UI-Moduls.
   * @param menuBadge - Alle neue Werte für die Bages des Menüs.
   */
  setMenuBadges(state: UIState, menuBadge: MenuBadgeSet): void {
    state.menuBadges = menuBadge
  }
}
