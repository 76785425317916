import { HooksObject, HookContext } from '@feathersjs/feathers'
import { defaultSaving } from '../../hooks/default-saving.hook'

/**
 * Hooks für die Variablen-Werte der Checkliste
 */
export const VariablesDeepHooks: HooksObject = {
  before: [defaultSaving],
  after: [
    defaultSaving,
    (context: HookContext): HookContext => {
      if (context.method === 'find') {
        context.result = context.result.total > 0 ? context.result.data[0] : {}
      }
      return context
    }
  ],
  error: []
}
