import { Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { API } from './api'

/**
 * Client-Einstellungen.
 */
export class Client {
  /**
   * Einstellungen zur API-/Backend-Verbindung
   */
  @Type((): Function => API)
  @ValidateNested()
  public api: API = new API()
}
