import { ActionTree } from 'vuex'
import { ConnectivityState } from './connectivity-state.interface'
import { RootState } from '../root-state.interface'

export const actions: ActionTree<ConnectivityState, RootState> = {
  /**
   * Führt die Mutation "setOnline" aus.
   */
  setOnline({ commit }): void {
    commit('setOnline')
  },

  /**
   * Führt die Mutation "setOffline" aus.
   */
  setOffline({ commit }): void {
    commit('setOffline')
  }
}
