
import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { DatabaseComparator } from '@/constants/database-comparator.enum'
import { Conversion } from '@/constants/conversion.enum'
import {
  isColumnArray,
  isFilterList,
  isStringList
} from '@/helpers/type.helper'
import { SelectValues } from './SelectValues.interface'
import { Property2Values } from './Property2Values.interface'

/**
 * Dynamisches Filtermenü zur Auswahl von Listenfiltern
 */
@Component({})
export default class FilterList extends Vue {
  /**
   * Datum von dem aus eine Tabellenspalte gefiltert werden soll.
   */
  public dateFrom: string[] = []

  /**
   * Datum bis zu dem eine Tabellenspalte gefiltert werden soll.
   */
  public dateTo: string[] = []

  /**
   * Ausgewählte Optionen von den Filtern aus den Spalten [[`selectColumns`]].
   */
  public selectValues: SelectValues = {}

  /**
   * Enthält die Property der Tabellenspalten, die für die Boolean-Suche
   * verwendet werden. Bezieht sich auf die Spalten aus [[`booleanColumns`]].
   */
  public enableBooleanColumns: string[] = []

  /**
   * Enthält die Property der Tabellenspalten, die explizit für eine Suche
   * ausgewählt wurden, um nur darin zu suchen. Bezieht sich auf die Spalten aus
   * [[`searchableColumns`]].
   */
  public searchColumns: string[] = []

  /**
   * Trigger zum Öffnen und Schließen der Datepicker 'Von'.
   */
  public menuFrom = []

  /**
   * Trigger zum Öffnen und Schließen der Datepicker 'Bis'.
   */
  public menuTo = []

  /**
   * Ermöglicht das Blockieren der internen Watchers. Dadurch können Loops
   * verhindert werden, die beim setzen von neuen  Filter auftreten können.
   * Folgende Watcher und dessen Methoden können blockiert werden:
   *
   * | # | Methode                         | Watcher auf die Eigenschaft(en) |
   * | - | ------------------------------- | ------------------------------- |
   * | 0 | [[`onChangeBooleanColumns`]]    | `enableBooleanColumns`          |
   * | 1 | [[`onChangeDateColumns`]]       | `dateFrom` und `dateTo`         |
   * | 2 | [[`onChangeSearchableColumns`]] | `searchColumns`                 |
   * | 3 | [[`onChangeSelectColumns`]]     | selectValues                    |
   * | 4 | [[`onExternFilterChange`]]      | `value`                         |
   * | 5 | [[`onValueSearchColumns`]]      | `valueSearchColumns`            |
   *
   * Beim Ausführen der Methoden wird die Blockierung wieder entfernt.
   */
  protected isValueUpdating: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false
  ]

  /**
   * Die vorausgewählten Filtern, als Liste.
   */
  @Prop({
    required: false,
    default: (): [] => [],
    validator: isFilterList
  })
  public value!: Filter[]

  /**
   * Die Spalten von [[searchableColumns]], die durchsucht werden sollen. Wenn
   * ein Suchstring angegeben wurde.
   */
  @Prop({
    required: false,
    default: (): string[] => [],
    validator: isStringList
  })
  public valueSearchColumns!: string[]

  /**
   * Erhält die Spalten einer Tabelle aus dem Store, in denen gesucht werden
   * kann (searchable === true) und die strings nach denen gesucht werden kann
   * enthalten, z.B. Name, Vorname, etc.
   */
  @Prop({ required: false, default: (): [] => [], validator: isColumnArray })
  public searchableColumns!: Column[]

  /**
   * Erhält die Spalten einer Tabelle aus dem Store, in denen gesucht werden
   * kann (searchable === true) und die Datumswerte enthalten, die gefiltert
   * werden sollen
   */
  @Prop({ required: false, default: (): [] => [], validator: isColumnArray })
  public dateColumns!: Column[]

  /**
   * Erhält die Spalten einer Tabelle aus dem Store, in denen gesucht werden
   * kann (searchable === true) und die einen Boolean-Zustand enthalten, der
   * gefiltert werden soll
   */
  @Prop({ required: false, default: (): [] => [], validator: isColumnArray })
  public booleanColumns!: Column[]

  /**
   * Erhält die Spalten einer Tabelle aus dem Store, die in den Filtern als
   * Select ausgegeben werden sollen.
   */
  @Prop({ required: false, default: (): [] => [], validator: isColumnArray })
  public selectColumns!: Column[]

  /**
   * Vue-Hook 'created', es werden die aktuell im Storge gesetzten Filter als
   * anzeige Werte übernommen.
   */
  public created(): void {
    this.onExternFilterChange(this.value)
    this.onValueSearchColumns(this.valueSearchColumns)
  }

  /**
   * Beobachtet die Property [[this.value]]. Übernimmt die Änderungen an den
   * Filtern und zeigt diese als aktuelle Einstellung an.
   *
   * @param filters - Die neuen Filter
   */
  @Watch('value')
  public onExternFilterChange(filters?: Filter[]): void {
    if (this.isValueUpdating[4]) {
      return
    }

    this.isValueUpdating.fill(true, 0, 5)

    try {
      const filterValues: Property2Values = {}
      const resultBoolean: string[] = []
      const resultSelect: SelectValues = {}

      if (Array.isArray(filters) && filters.length) {
        filters.forEach((filter): void => {
          if (filter.conversion === Conversion.Date) {
            const column = `${filter.column}${
              filter.comparator === DatabaseComparator.BiggerOrEqual
                ? '_from'
                : filter.comparator === DatabaseComparator.SmallerOrEqual
                ? '_to'
                : ''
            }`
            filterValues[column] = filter.values
          } else if (filter.conversion === Conversion.Boolean) {
            const value = filter.values[0]

            if (value === '0' || value === 'false') {
              filterValues[`${filter.column}-false`] = filter.values
            } else {
              filterValues[`${filter.column}-true`] = filter.values
              filterValues[filter.column] = filter.values
            }
          } else {
            filterValues[filter.column] = filter.values
          }
        })
      }

      this.booleanColumns.forEach((column): void => {
        const values = filterValues[column.property]

        if (Array.isArray(values)) {
          const value = values[0]

          if (column.property.split('-')[1] === 'false') {
            if (value === 'false' || value === '0') {
              resultBoolean.push(column.property)
            }
          } else if (value === 'true' || value === '1') {
            resultBoolean.push(column.property)
          }
        }
      })

      this.selectColumns.forEach((column): void => {
        const values = filterValues[column.property]

        if (Array.isArray(values) && values.length !== 0) {
          if (column.filterMultiple !== true) {
            resultSelect[column.property] = values[0]
          } else {
            resultSelect[column.property] = values.filter(
              (x): boolean => !!x
            ) as string[]
          }
        }
      })

      this.dateColumns.forEach((column, index): void => {
        const property = column.property
        const single = (filterValues[property] || [])[0]
        const from = (filterValues[`${property}_from`] || [])[0] || single
        const to = (filterValues[`${property}_to`] || [])[0] || single

        if (from) {
          this.dateFrom[index] = from.split('T')[0]
        } else {
          this.dateFrom[index] = ''
        }

        if (to) {
          this.dateTo[index] = to.split('T')[0]
        } else {
          this.dateTo[index] = ''
        }
      })

      this.enableBooleanColumns = resultBoolean
      this.selectValues = resultSelect
    } catch (ex) {
      /* Gibt die Fehlern in der Browser-Konsole aus, die beim Parsen der
       * übergebenen Filtern (durch das Property 'value') aufgetreten sind.
       */
      // eslint-disable-next-line no-console
      console.error(
        `FilterList: found invalid filters in property 'value'\n`,
        filters,
        `\n`,
        ex
      )
    } finally {
      this.$nextTick((): void => {
        this.isValueUpdating.fill(false, 0, 4)
      })
    }
  }

  /**
   * Beobachtet das Property [[this.valueSearchColumns]] und reicht dessen
   * Änderungen an die interne Eigenschaft [[this.searchColumns]] weiter.
   *
   * @param searchColumn - Spalten, die von der Suche berücksichtigt werden.
   */
  @Watch('valueSearchColumns')
  public onValueSearchColumns(searchColumn?: string[]): void {
    if (this.isValueUpdating[5]) {
      return
    }

    const resultSearch: string[] = []

    if (Array.isArray(searchColumn)) {
      this.searchableColumns.forEach((column): void => {
        if (searchColumn.indexOf(column.property) !== -1) {
          resultSearch.push(column.property)
        }
      })
    }

    this.searchColumns = resultSearch
  }

  /**
   * Übernimmt die geänderten Optionen von den [[`booleanColumns`]]-Filtern und
   * Triggert das Event `input`. Das Event bekommt das komplette neue
   * [[`FilterSet`]] übergeben.
   */
  @Watch('enableBooleanColumns')
  public onChangeBooleanColumns(): void {
    if (this.isValueUpdating[0]) {
      return
    }

    this.applyFilters()
  }

  /**
   * Übernimmt die geänderten Optionen von den [[`dateColumns`]]-Filtern und
   * Triggert das Event `input`. Das Event bekommt das komplette neue
   * [[`FilterSet`]] übergeben.
   */
  @Watch('dateFrom', { deep: true })
  @Watch('dateTo', { deep: true })
  public onChangeDateColumns(): void {
    if (this.isValueUpdating[1]) {
      return
    }

    this.applyFilters()
  }

  /**
   * Übernimmt die geänderten Optionen von den [[`searchableColumns`]]-Filtern
   * und Triggert das Event `change-search-columns`.
   */
  @Watch('searchColumns')
  public onChangeSearchableColumns(): void {
    if (this.isValueUpdating[2]) {
      return
    }

    this.changeSearchColumns()
  }

  /**
   * Übernimmt die geänderten Optionen von den [[`selectColumns`]]-Filtern und
   * Triggert das Event `input`. Das Event bekommt das komplette neue
   * [[`FilterSet`]] übergeben.
   */
  @Watch('selectValues', { deep: true })
  public onChangeSelectColumns(): void {
    if (this.isValueUpdating[3]) {
      return
    }

    this.applyFilters()
  }

  /**
   * Löscht das ausgewählte Start- oder Enddatum, wenn das Datum gesetzt ist.
   *
   * @param index - Indexs, zu dem Array von [[`dateFrom`]] oder [[`dateTo`]].
   * @param fromDate - Bei `true` wird [[`dateFrom`]], sonst [[`dateTo`]],
   * verwendet.
   */
  public onClearDate(index: number, fromDate = true): void {
    if (fromDate) {
      if (this.dateFrom[index]) {
        this.dateFrom[index] = ''
      }
    } else if (this.dateTo[index]) {
      this.dateTo[index] = ''
    }
  }

  /**
   * Löscht die ausgewählten Option von einem Filtern aus den Spalten
   * [[`selectColumns`]], wenn dieser gesetzt worden ist.
   *
   * @param property - Name der Spalte, bzw. dessen zugeordnetes Property.
   */
  public onClearSelect(property?: string): void {
    if (typeof property === 'string' && this.selectValues[property]) {
      this.selectValues[property] = undefined
    }
  }

  /**
   * Triggert das Event `change-search-columns`. Dieser bekommt alle Spalten
   * übergeben, die durchsucht werden dürfen.
   *
   * @returns - Liste mit allen Spalten, die durchsucht werden dürfen.
   */
  @Emit()
  public changeSearchColumns(): string[] {
    this.isValueUpdating.fill(true, 5, 6)
    this.$nextTick((): void => {
      this.isValueUpdating.fill(false, 5, 6)
    })
    return [...this.searchColumns]
  }

  /**
   * Übernimmt die eingestellten Optionen für die Filter und triggert das Event
   * `input`. Dieses bekommt das neu erstellte [[`FilterSet`]] übergeben.
   *
   * @returns - Das neue [[`FilterSet`]], entsprechend mit neuen Filterwerten
   */
  @Emit('input')
  public applyFilters(): Filter[] {
    this.isValueUpdating.fill(true, 4, 5)
    this.$nextTick((): void => {
      this.isValueUpdating.fill(false, 4, 5)
    })
    const filters: Filter[] = []

    for (const prop in this.selectValues) {
      const values = this.selectValues[prop]

      if (Array.isArray(values)) {
        if (values.length) {
          filters.push({
            column: prop,
            comparator: DatabaseComparator.In,
            values: values
          })
        }
      } else if (typeof values === 'string' && values !== '') {
        filters.push({
          column: prop,
          comparator: DatabaseComparator.Equal,
          values: [values]
        })
      }
    }

    if (this.dateTo.length > 0) {
      for (let i = this.dateTo.length - 1; i >= 0; i--) {
        const column = this.dateColumns[i]
        const dateTo = this.dateTo[i]

        if (column && typeof dateTo === 'string' && dateTo !== '') {
          filters.push({
            column: column.property,
            comparator: DatabaseComparator.SmallerOrEqual,
            values: [dateTo + 'T23:59:59Z'],
            conversion: Conversion.Date
          })
        }
      }
    }

    if (this.dateFrom.length > 0) {
      for (let i = this.dateFrom.length - 1; i >= 0; i--) {
        const column = this.dateColumns[i]
        const dateFrom = this.dateFrom[i]

        if (column && typeof dateFrom === 'string' && dateFrom !== '') {
          filters.push({
            column: column.property,
            comparator: DatabaseComparator.BiggerOrEqual,
            values: [dateFrom + 'T00:00:00Z'],
            conversion: Conversion.Date
          })
        }
      }
    }

    if (this.enableBooleanColumns.length !== 0) {
      this.enableBooleanColumns.forEach((prop): void => {
        const property = prop ? prop.trim() : ''

        if (property) {
          const parts = property.split('-', 2)

          filters.push({
            column: parts[0],
            comparator: DatabaseComparator.Equal,
            values: [parts[1] === 'false' || parts[1] === '0' ? '0' : '1'],
            conversion: Conversion.Boolean
          })
        }
      })
    }

    return filters
  }
}
