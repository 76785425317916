import { HookContext } from '@feathersjs/feathers'
import { getService } from '../../helpers/feathers'
import { Connection } from '../connection.enum'
import store from '@/store'

/**
 * Verwaltet das Login des Benutzers, damit dieser auch im Offline-Modus als
 * eingeloggt erkamnnt werden kann.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, mit angepassten Query-Parmater
 */
export async function authOffline(context: HookContext): Promise<HookContext> {
  if (context.path === 'auth') {
    const authService = await getService('auth', Connection.Offline)

    if (context.method === 'create') {
      if (context.type === 'before') {
        if (store.getters['connectivity/offline']) {
          const result = await authService.get(1).catch((): null => null)

          if (result === null) {
            throw new Error('missing jwt token')
          }

          context.result = result
        }
      } else if (context.type === 'after') {
        if (store.getters['connectivity/online']) {
          await authService.get(1).then(
            (): void => {
              authService.update(1, context.result)
            },
            (): void => {
              authService.create(context.result)
            }
          )
        }
      }
    } else if (
      context.method === 'remove' &&
      context.type === 'after' &&
      store.getters['connectivity/online']
    ) {
      localStorage.removeItem('license')
      localStorage.removeItem('licenseExpiredDate')
      localStorage.removeItem('lastUser')
      await authService.remove(1).catch((): null => null)
    }
  }

  return context
}
