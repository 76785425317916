
import { CountryCode } from '@/constants/country-code.enum'
import { getApp } from '@/helpers/feathers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Statement } from './statement.interface'
import GenericError from '@/components/GenericError/GenericError.vue'
import { GenericErrorData } from '@/interfaces/GenericErrorData.interface'

/**
 * Zeigt ein Statement aus dem Statement-Service (Server) an.
 */
@Component({
  components: {
    GenericError
  }
})
export default class FormattedStatement extends Vue {
  /**
   * Zu ladendes Statement.
   */
  @Prop({ type: String, required: true })
  public type!: string

  /**
   * Gibt an, ob ein Fehler aufgetreten ist.
   */
  private error: GenericErrorData | null = null

  /**
   * Gibt an, ob momentan Daten vom Server geladen werden.
   */
  private loading = true

  /**
   * Statement-Daten.
   */
  private statement?: Statement

  /**
   * HTML in der aktiven Sprache zum Statement.
   *
   * @returns HTML-String.
   */
  private get statementHtml(): string {
    if (!this.statement) {
      return ''
    }

    return this.statement.language[this.$i18n.locale as CountryCode] || ''
  }

  /**
   * Ruft [[this.loadFromServer]] auf.
   */
  public created(): void {
    this.loadFromServer()
  }

  /**
   * Lädt das Statement vom Server. Wird beim Erstellen der Komponente und beim
   * Ändern von [[this.type]] aufgerufen.
   */
  @Watch('type')
  private async loadFromServer(): Promise<void> {
    try {
      this.statement = await (await getApp())
        .service('statements')
        .get(this.type)

      this.loading = false
    } catch (ex) {
      this.error = {
        code: ex?.data?.errorCode,
        replacements: ex?.data?.details
      }
    }
  }
}
