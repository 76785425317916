
import { Component, Prop, Vue } from 'vue-property-decorator'
import StatementCenter from '@/components/StatementCenter/StatementCenter.vue'

/**
 * Bereich mit Versionsnummer und Buttons für Informationen und Hilfe.
 */
@Component({
  components: {
    StatementCenter
  }
})
export default class StatementSection extends Vue {
  /**
   * Legt die Schriftfarbe fest (bspw. `primary` oder `white`), basierend auf
   * den Vuetify-Farbklassen (bspw. `white` wird zu `white--text`).
   */
  @Prop({ type: String, required: false, default: 'primary' })
  private color!: string

  /**
   * Aktiver StatementCenter Tab.
   */
  private statementCenterTab = 0

  /**
   * Default Tab
   */
  private dTab = ''
}
