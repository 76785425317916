
import { namespace, Getter } from 'vuex-class'
import { Component, Mixins } from 'vue-property-decorator'
import { Permission, Legacy, ALL } from '@nxt/permissions'
import { PermissionMixin } from '@/mixins/permission.mixin'
import { getService } from '@/helpers/feathers'
import { EnvironmentMixin } from '@/mixins/environment.mixin'
import { OfflineEntry } from '@/offline/offline-entry.interface'
import { MenuBadgeSet } from '@/store/ui/menu-badge.interface'
import { DashboardTranslationsWithID } from '@/interfaces/DashboardTranslationsWithID.interface'
import store from '@/store'

/**
 * Menüeintrag unformatiert
 */
interface MenuItem {
  /**
   * Schlüssel für den Menueintrag, wird unteranderen für das Mapping des Badge-
   * Wertes verwendet.
   */
  key?: string

  /**
   * Inhalt des Labels
   */
  name: string

  /**
   * Icon des Eintrages
   */
  icon?: string

  /**
   * Ziel URL
   */
  to?: string

  /**
   * Untergeordnete Einträge
   */
  children?: MenuItem[]

  /**
   * Erforderliche Berechtigung.
   */
  permission?: Permission

  /**
   * Gibt an, ob der Eintrag auf aktiv gesetzt wird
   */
  active?: boolean

  /**
   * Ob ein Badge an diesem Menüeintrag ausgegeben werden soll.
   */
  badgeDisplay?: boolean

  /**
   * Der Inhalt des Badges des Menüeintrags. Wenn gesetzt, wird
   * [[this.badgeDisplay]] automatisch als `true` ausgewertet!
   */
  badgeContent?: number | string

  /**
   * Wenn true, wird der Eintrag nur im Entwicklungsmodus angezeigt.
   */
  developmentOnly?: boolean

  /**
   * Wenn true, wird der Eintrag nur angezeigt, wenn man online ist.
   */
  onlineOnly?: boolean
}

/**
 * Menüeintrag formatiert
 */
export interface MenuItemNew extends MenuItem {
  /**
   * Eindeutige Identifikationsnummer
   */
  id: number

  /**
   * Untergeordnete Einträge
   */
  children?: MenuItemNew[]

  /**
   * Wenn true, ist das Item gesperrt.
   */
  disabled?: boolean
}

/**
 * Dashboard-Listeninformationen unformatiert
 */
interface DashboardItem {
  /**
   * ID des Dashboards
   */
  id: number

  /**
   * Rollen-ID des Dashboards
   */
  propertyID?: number

  /**
   * Bezeichnung des Dashboards
   */
  name?: string

  /**
   * Icon für das Dashboard
   */
  icon?: string
}

/**
 * Interface für Vorgangsobjekte, unformatiert
 */
interface ActivityItem {
  /**
   * ID des Vorgangs
   */
  id: number
  /**
   * Name des Vorgangs
   */
  label: string
  /**
   * Zusätzliche Informationen zum Vorgang
   */
  extra_info?: string
  /**
   * Standard Collection-Switch
   */
  default_module_collection?: boolean
  /**
   * ID der Kategorie
   */
  category: number
  /**
   * Online/Offline-Switch
   */
  offline_available?: boolean
}

/**
 * Anzeigeinformationen für die Links zum klassischen Eplas.
 */
interface LinkVisibilityInfos {
  /**
   * Information, ob der Link zum klassischen Dashboard angezeigt werden soll.
   */
  showLegacyDashboardLink: boolean

  /**
   * Wenn aktiv, werden die Links zu den Audits in der Seitennavigation
   * angezeigt.
   */
  showAuditLinks: boolean

  /**
   * Wenn aktiv, wird der Link zum Gefahrstoffimport angezeigt
   */
  showSubstanceImport: boolean
}

/**
 * Interface für ServiceObjekte
 */
interface ServiceObject {
  /**
   * Route des Services
   */
  service: string
  /**
   * Funktionsname zum aufrufen der richtigen auzuführenden Funktion
   */
  function: string
  /**
   * Es werden die Informationen von diesem Service auch Offline ausgegeben.
   */
  offlineAvailable?: boolean
}

const UIStore = namespace('ui')

/**
 * Menü an der linken Seite
 */
@Component({})
export default class SideNavigation extends Mixins(
  PermissionMixin,
  EnvironmentMixin
) {
  /**
   * Gibt an, ob die mobile Suche aktiv ist.
   */
  @UIStore.State('menuBadges')
  public menuBadges!: MenuBadgeSet

  /**
   * Objekt mit abzufragenden Services & Funktionen
   */
  public services: ServiceObject[] = [
    {
      service: 'dashboard-navigation',
      function: 'addDashboardItems'
    },
    {
      service: 'checklist/procedure',
      function: 'addActivityItem',
      offlineAvailable: true
    },
    {
      service: 'side-navigation',
      function: 'toggleLinks'
    }
  ]

  /**
   * Menüeintrag unformatiert
   */
  public menuItems: MenuItem[] = [
    {
      name: 'sideNavigation.subheader.start',
      children: [
        {
          developmentOnly: true,
          name: 'sideNavigation.listItem.checklistTest',
          icon: 'mdi-gamepad-variant',
          to: '/test_checklists'
        }
      ]
    },
    {
      name: 'sideNavigation.subheader.activity',
      children: []
    },
    {
      name: 'sideNavigation.subheader.overview',
      children: []
    },
    {
      name: 'sideNavigation.subheader.administration',
      children: [
        {
          to: '/dashboardRoles',
          name: 'sideNavigation.listItem.dashboardAdministration',
          icon: 'mdi-view-dashboard-variant',
          onlineOnly: true,
          permission: {
            permission: Legacy.Permission298,
            mask: ALL
          }
        },
        {
          developmentOnly: true,
          name: 'sideNavigation.header.system',
          icon: 'mdi-settings-box',
          children: [
            {
              to: '/settings',
              name: 'sideNavigation.listItem.settings',
              icon: 'mdi-tune',
              permission: {
                permission: Legacy.Permission1,
                mask: ALL
              }
            }
          ],
          onlineOnly: true
        }
      ]
    },
    {
      developmentOnly: true,
      name: 'sideNavigation.subheader.develop',
      children: [
        {
          name: 'sideNavigation.listItem.playground',
          icon: 'mdi-gamepad-square',
          to: '/spielwiese'
        },
        {
          name: 'sideNavigation.listItem.playgroundWidgets',
          icon: 'mdi-widgets',
          to: '/spielwiese_widgets'
        },
        {
          name: 'sideNavigation.listItem.playgroundTree',
          icon: 'mdi-tree',
          to: '/spielwiese_baum'
        },
        {
          name: 'Spielwiese: Liste',
          icon: 'mdi-table',
          to: '/spielwiese_list'
        },
        {
          name: 'Debug: Connectivity',
          icon: 'mdi-lan-connect',
          to: '/debug/connectivity'
        }
      ]
    },
    {
      name: 'sideNavigation.subheader.classic',
      children: [
        {
          name: 'sideNavigation.listItem.classicAdministration',
          to: '/classicAdmin',
          icon: 'mdi-account-key',
          onlineOnly: true,
          active: false
        }
      ]
    }
  ]

  /**
   * Übersetzungsdaten der Dashboards aus dem Store.
   */
  @Getter('dashboardTranslations/dashboards')
  public translations!: DashboardTranslationsWithID[]

  /**
   * Setzt den Link zur klassischen eplas10l-Administration auf "active",
   * wenn man den Administrations-Haken in eplas10l im Mitarbeiter gesetzt hat.
   *
   * `active` kann erst in der created-Funktion aufgerufen werden, weil die
   * Store-Informationen beim Initialisieren von `this.menuItems` noch nicht
   * zur Verfügung stehen.
   */
  private setAdministrationMenuItem(): void {
    const lv1 = this.menuItems.find(
      i => i.name === 'sideNavigation.subheader.classic'
    )

    const lv2 = lv1?.children?.find(
      i => i.name === 'sideNavigation.listItem.classicAdministration'
    )

    if (lv2) {
      lv2.active = this.$store.getters['user/adminFlag']
    }
  }

  /**
   * Holt sich beim Erstellen der Seite die Liste verfügbarer Dashboards und
   * Vorgänge des Users
   */
  public async created(): Promise<void> {
    const eplasIsOnline = this.$store.getters['connectivity/online']
    let entries: OfflineEntry[] = []

    for (const serviceData of this.services) {
      if (eplasIsOnline || serviceData.offlineAvailable) {
        try {
          const service = await getService<OfflineEntry>(serviceData.service)
          await service.find().then(async (result): Promise<void> => {
            if (Array.isArray(result)) {
              if (serviceData.service === 'dashboard-navigation') {
                result.forEach(res => {
                  if (res.translations) {
                    store.commit('dashboardTranslations/addDashboard', {
                      id: res.id,
                      translations: res.translations
                    })
                  }
                })
              }
              entries = result
            } else if ('total' in result && Array.isArray(result.data)) {
              const total: number =
                typeof result.total === 'number' ? result.total : 0
              const limit: number =
                typeof result.limit === 'number' ? result.limit : 0
              entries = result.data

              if (total > limit) {
                for (let offset = limit; offset < limit; offset += limit) {
                  const data = await service.find({
                    query: { $offset: offset }
                  })

                  if ('total' in data && Array.isArray(data.data)) {
                    entries.push(...data.data)
                  }
                }
              }
            }
          })
        } catch (error) {
          throw new Error(`Service not available. Error ${error}`)
        }
        await this.getFunction(serviceData.function, entries)
      }
    }

    this.setAdministrationMenuItem()
  }

  /**
   * Führt eine bestimmte Funktion basierend auf ihrem Namen aus
   *
   * @param functionName - Name der aufgerufenen Funktion
   * @param result - Ergebniss des Response
   */
  public getFunction(functionName: string, result: OfflineEntry[]): void {
    if (result.length !== 0) {
      this[functionName as keyof SideNavigation](result)
    }
  }

  /**
   * Baut ein Listen-Element mit den Vorgängen für [[this.menuItems]] und fügt
   * diese hinzu
   *
   * @param activity - Array mit Vorgangsinformationen
   */
  public addActivityItem(activity: ActivityItem[]): void {
    const activityItem: MenuItem = {
      name: 'sideNavigation.header.activity',
      icon: 'mdi-file-document-edit',
      children: []
    }

    for (const item of activity) {
      let activityItemChild: MenuItem
      if (item.offline_available) {
        activityItemChild = {
          name: item.label,
          icon: 'mdi-airplane-off',
          to: `/audit/offline/new/${item.id}`,
          onlineOnly: false
        }
      } else {
        activityItemChild = {
          name: item.label,
          icon: 'mdi-format-list-checks',
          to: `/reroute/audit/create/${item.id}`,
          onlineOnly: true
        }
      }
      if (typeof activityItem.children === 'undefined') {
        continue
      } else {
        activityItem.children.push(activityItemChild)
      }
    }

    if (Array.isArray(this.menuItems[1].children)) {
      this.menuItems[1].children.push(activityItem)
    }
  }

  /**
   * Baut ein Listen-Element mit den Dashboards für [[this.menuItems]] und fügt
   * dieses hinzu
   *
   * @param dashboards - Array das Informationen für das Dashboard beinhaltet
   */
  public addDashboardItems(dashboards: DashboardItem[]): void {
    const menuItem: MenuItem = {
      name: 'sideNavigation.header.dashboard',
      icon: 'mdi-view-dashboard',
      children: [],
      onlineOnly: true
    }

    for (const dashboard of dashboards) {
      const menuItemChild: MenuItem = {
        name: dashboard.name || 'sideNavigation.listItem.dashboard',
        icon: dashboard.icon || 'mdi-view-dashboard',
        to: `/dashboard/${dashboard.id}`
      }

      if (typeof menuItem.children === 'undefined') {
        continue
      }
      if (menuItemChild.name === 'sideNavigation.listItem.dashboard') {
        menuItem.children.splice(0, 0, menuItemChild)
      } else {
        menuItem.children.push(menuItemChild)
      }
    }

    if (typeof this.menuItems[0].children === 'undefined') {
      return
    }
    this.menuItems[0].children.push(menuItem)
  }

  /**
   * Fügt, abhängig von der jeweiligen Sichtbarkeitsoption, Links zum Bereich
   * "klassisches Eplas" in der Seitennavigation hinzu.
   *
   * @param item - Array enthält ein Objekt, das die Sichtbarkeitsinformationen
   * für die Anzeige einiger Navigationslinks enthält.
   */
  public toggleLinks(item: LinkVisibilityInfos[]): void {
    if (typeof this.menuItems[5].children === 'undefined') {
      this.menuItems[5].children = []
    }

    if (item[0].showLegacyDashboardLink) {
      this.menuItems[5].children.push({
        name: 'sideNavigation.listItem.classicDashboard',
        to: '/classicDashboard',
        icon: 'mdi-account-convert',
        onlineOnly: true,
        children: []
      })
    }

    if (!!item[0].showAuditLinks) {
      this.menuItems[2].children = [
        {
          key: 'audit_list_online',
          name: 'sideNavigation.listItem.auditsOnline',
          icon: 'mdi-format-list-checks',
          to: '/audit/online',
          onlineOnly: true
        },
        {
          key: 'audit_list_offline',
          name: 'sideNavigation.listItem.auditsOffline',
          icon: 'mdi-airplane-off',
          to: '/audit/offline'
        }
      ]
    }

    if (item[0].showSubstanceImport) {
      this.menuItems[3].children?.push({
        name: 'sideNavigation.listItem.substanceImport',
        icon: 'mdi-flask-outline',
        to: '/substanceimport',
        onlineOnly: true,
        permission: {
          permission: Legacy.Permission81,
          mask: ALL
        }
      })
    }
  }

  /**
   * Gibt an, ob ein Menu-Punkt aufgrund der active-Eigenschaft ausgeblendet
   * wird.
   *
   * @param item - MenuItem
   * @returns boolean
   */
  private menuItemIsActive = (item: MenuItem): boolean =>
    item.hasOwnProperty('active') ? (item.active as boolean) : true

  /**
   * Gibt das Menü formatiert mit IDs aus und prüft ob der Nutzer das Recht hat
   * den Listeneintrag zu sehen.
   *
   * @returns formatiertes Menü
   */
  public get menuItemsComputed(): MenuItemNew[] {
    /**
     * Diese Hilfsfunktion prüft ob der angegebene Menüeintrag angezeigt
     * werden darf. Hierbei werden alle Einträge für den Entwicklungsmodus
     * außerhalb des Entwicklungsmodus ausgefiltert.
     *
     * @param item - Eintrag der geprüft wird.
     * @returns Ob der Eintrag angezeigt werden soll
     */
    const isDisplay: (item: MenuItem) => boolean = (item): boolean => {
      if (!this.menuItemIsActive(item)) {
        return false
      }

      return this.isDevelopment || !item.developmentOnly
    }

    let incrementalID = 0
    const menuItems = this.menuItems
    const activePath = this.$route.path
    const menuItemsNew: MenuItemNew[] = []
    const badgesData = this.menuBadges
    let existsBadges = false
    let displayBadges = false
    let contentBadges: string | number | undefined

    for (const menuData of menuItems.filter(isDisplay)) {
      if (!Array.isArray(menuData.children) || !menuData.children.length) {
        continue
      }

      const menuDataNewChildren: MenuItemNew[] = []

      for (const childData of menuData.children.filter(isDisplay)) {
        if (childData.permission && !this.hasPermission(childData.permission)) {
          continue
        }

        const menuDataNewChildChildren: MenuItemNew[] = []
        let menuDataNewChildActive = false

        if (Array.isArray(childData.children) && childData.children.length) {
          for (const newChild of childData.children.filter(isDisplay)) {
            if (
              newChild.permission &&
              !this.hasPermission(newChild.permission)
            ) {
              continue
            }

            // Badges-Anzeige ermitteln
            contentBadges = newChild.badgeContent

            if (
              newChild.key &&
              badgesData.hasOwnProperty(newChild.key) &&
              badgesData[newChild.key] !== ''
            ) {
              contentBadges = badgesData[newChild.key]
            }

            displayBadges =
              typeof contentBadges !== 'undefined' || !!newChild.badgeDisplay

            if (!existsBadges && displayBadges) {
              existsBadges = true
            }

            // Menüeintrag erstellen (3. Ebene)
            if (this.displayChecker(newChild)) {
              if (this.$te(newChild.name) === true) {
                menuDataNewChildChildren.push({
                  id: incrementalID++,
                  name: this.$t(newChild.name) as string,
                  to: newChild.to,
                  icon: newChild.icon,
                  badgeDisplay: displayBadges,
                  badgeContent: contentBadges
                })
              } else {
                menuDataNewChildChildren.push({
                  id: incrementalID++,
                  name: newChild.name,
                  to: newChild.to,
                  icon: newChild.icon,
                  badgeDisplay: displayBadges,
                  badgeContent: contentBadges
                })
              }
            }

            if (newChild.to === activePath || childData.active) {
              menuDataNewChildActive =
                this.$store.getters['connectivity/online']
            }
          }
        }

        if (childData.to || menuDataNewChildChildren.length) {
          // Badges-Anzeige ermitteln
          contentBadges = childData.badgeContent

          if (
            childData.key &&
            badgesData.hasOwnProperty(childData.key) &&
            badgesData[childData.key] !== ''
          ) {
            contentBadges = badgesData[childData.key]
          }

          displayBadges =
            typeof contentBadges !== 'undefined' || !!childData.badgeDisplay

          if (!existsBadges && displayBadges) {
            existsBadges = true
          }
          // Menüeintrag erstellen (2. Ebene)
          if (this.$te(childData.name) === true) {
            menuDataNewChildren.push({
              id: incrementalID++,
              name: this.$t(childData.name) as string,
              icon: childData.icon,
              to: childData.to,
              badgeDisplay: displayBadges,
              badgeContent: contentBadges,
              active: menuDataNewChildActive,
              children: menuDataNewChildChildren,
              disabled: this.displayChecker(childData)
            })
          } else {
            menuDataNewChildren.push({
              id: incrementalID++,
              name: childData.name,
              icon: childData.icon,
              to: childData.to,
              badgeDisplay: displayBadges,
              badgeContent: contentBadges,
              active: menuDataNewChildActive,
              children: menuDataNewChildChildren,
              disabled: this.displayChecker(childData)
            })
          }
        }
      }

      if (menuDataNewChildren.length) {
        // Badges-Anzeige ermitteln
        contentBadges = menuData.badgeContent

        if (
          menuData.key &&
          badgesData.hasOwnProperty(menuData.key) &&
          badgesData[menuData.key] !== ''
        ) {
          contentBadges = badgesData[menuData.key]
        }

        displayBadges =
          typeof contentBadges !== 'undefined' || !!menuData.badgeDisplay

        if (!existsBadges && displayBadges) {
          existsBadges = true
        }
        // Menüeintrag erstellen (1. Ebene)
        if (this.displayChecker(menuData)) {
          if (this.$te(menuData.name) === true) {
            menuItemsNew.push({
              id: incrementalID++,
              name: this.$t(menuData.name) as string,
              badgeDisplay: displayBadges,
              badgeContent: contentBadges,
              children: menuDataNewChildren
            })
          } else {
            menuItemsNew.push({
              id: incrementalID++,
              name: menuData.name,
              badgeDisplay: displayBadges,
              badgeContent: contentBadges,
              children: menuDataNewChildren
            })
          }
        }
      }
    }

    this.$emit('eplas-display-badges', existsBadges)
    return menuItemsNew
  }

  /**
   * Gibt die richtige Style-Klasse anhand des Connection-Status zurück
   *
   * @param enabled - Gibt an, ob der Eintrag aktiv ist.
   * @returns Name der CSS Klasse die benutzt werden soll
   */
  public connectionClass(enabled: boolean): string {
    if (this.$store.getters['connectivity/offline'] && !enabled) {
      return 'list--item-disabled'
    }
    return 'list--item-enabled'
  }

  /**
   * Überprüft, ob das übergebene Item abhängig vom aktuellen Modus angezeigt
   * werden darf.
   *
   * @param item - Das Item, das geprüft werden soll.
   * @returns Gibt zurück, ob das Item angezeigt werden soll.
   */
  public displayChecker(item: MenuItem): boolean {
    if (
      this.$store.getters['connectivity/online'] ||
      (this.$store.getters['connectivity/offline'] && !item.onlineOnly)
    ) {
      return true
    }
    return false
  }

  /**
   * Gibt den Namen des Navigations-Elements zurück.
   *
   * @param element - Das Navigations-Element, für das der Name zurückgegeben
   * werden soll.
   * @returns Name des übergebenen Navigations-Elements.
   */
  public getName(element: MenuItem): string {
    let res = this.$t(element.name).toString()
    if (element.to) {
      const id = parseInt(element.to.substring(11))
      if (element.to.includes('dashboard')) {
        const translations = this.translations
        if (translations) {
          translations.forEach(translation => {
            if (translation.id === id) {
              const currentTranslation = translation.translations.find(
                translation => translation.language === this.$i18n.locale
              )

              const fallbackTranslation = translation.translations.find(
                translation =>
                  translation.language === this.$i18n.fallbackLocale
              )

              if (currentTranslation && currentTranslation.translation) {
                res = currentTranslation.translation
              } else if (
                fallbackTranslation &&
                fallbackTranslation.translation
              ) {
                res = fallbackTranslation.translation
              }
            }
          })
        }
      }
    }
    return res
  }
}
