/**
 * Vergleichsoperatoren für den [[Filter]], die für Datenbankanfragen verwendet
 * werden.
 *
 * Replik auf der Server-Seite, unter
 * `/server/src/feathers/database-comparator.enum.ts`. Alle Anpassungen
 * entsprechend auch dort durchführen!
 */
export enum DatabaseComparator {
  /**
   * Prüft, ob Wert A exakt Wert B entspricht.
   */
  Equal = '=',

  /**
   * Prüft, ob Wert A von Wert B abweicht.
   */
  NotEqual = '!=',

  /**
   * Prüft, ob Wert A größer als Wert B ist.
   */
  Bigger = '>',

  /**
   * Prüft, ob Wert A kleiner als Wert B ist.
   */
  Smaller = '<',

  /**
   * Prüft, ob Wert A kleiner als oder exakt Wert B ist.
   */
  BiggerOrEqual = '>=',

  /**
   * Prüft, ob Wert A größer als oder exakt Wert B ist.
   */
  SmallerOrEqual = '<=',

  /**
   * Prüft, ob Wert A "ähnlich wie" Wert B ist. Groß-/Kleinschreibung wird nicht
   * beachtet. Für Details zum TSQL "LIKE" siehe
   * <https://docs.microsoft.com/sql/t-sql/language-elements/like-transact-sql>.
   */
  Like = 'like',

  /**
   * Prüft ob Wert A sich zwischen Wert B und C (inklusiv) befindet. Geeignet
   * für Zahlen und Datumswerte.
   */
  Between = 'between',

  /**
   * Prüft ob sich Wert A in einem Set von Werten befindet.
   */
  In = 'in'
}
