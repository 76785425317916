import { HooksObject } from '@feathersjs/feathers'
import { employeePicker } from '../hooks/employee-picker.hook'

/**
 * Hooks für die Checklist-Zelle
 */
export const PickerEmployees: HooksObject = {
  before: [employeePicker],
  after: [],
  error: []
}
