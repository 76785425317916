import { HookContext } from '@feathersjs/feathers'
import { BadRequest } from '@feathersjs/errors'

/**
 * Wandelt einen Zellenwert von der Checkliste entsprechend um, dass dieser
 * gespeichert werden kann. Dem Zellenwert können Einstellungen mit angegeben
 * werden, die von diesem oder vorhergegangenen Hooks beachtet werden können.
 *
 * @param context - Hook-Kontext - Kommmt von Feathers
 * @returns Hook-Kontext, Eigenschaft `data` verändert
 */
export function convertValue(context: HookContext): HookContext {
  try {
    context.data = context.data.value
  } catch (ex) {
    context.data = null
    throw new BadRequest('Input validation error!', ex)
  }

  return context
}
