import { HookContext } from '@feathersjs/feathers'
import { BadRequest, NotImplemented } from '@feathersjs/errors'
import { DeepMapping } from './deep-mapping.class'

/**
 * Feathers-Hook für den Service 'checklists-collections/deep'. Innerhalb des
 * Service wird die komplette Service-Funktion durchgeführt. Die übergebenen
 * Einträge werden über andere Offline-Services gespeichert. Dieses wird von der
 * Klasse [[DeepMapping]] übernommen.
 * Da der Service 'checklists-collections/deep' keine Funktionalität besitzt,
 * muss dieser als `before`-Hook eingetragen werden!
 *
 * @param context - Hook-Kontext - Kommt von Feathers
 * @returns Hook-Kontext, mit angepassten Query-Parameter
 */
export async function deepMapping(context: HookContext): Promise<HookContext> {
  if (!context.params) {
    context.params = { query: {} }
  } else if (!context.params.query) {
    context.params.query = {}
  }

  if (
    context.type === 'before' &&
    context.path === 'checklists-collections/deep'
  ) {
    const entryID = context.params._entryID || context.id

    if (entryID) {
      const parsedID =
        typeof entryID !== 'number' ? parseInt(entryID.toString(), 10) : entryID

      if (!isNaN(parsedID) && parsedID !== 0) {
        // Wenn die Zahl
        // - `> 0`: ID einer Collection
        // - `< 0`: ID eines Modules
        context.params.query = Object.assign(
          context.params.query || {},
          parsedID > 0
            ? { $collectionID: parsedID, $moduleID: 0 }
            : { $collectionID: 0, $moduleID: parsedID * -1 }
        )
      }
    }

    const query = context.params.query || {}
    if ('$collectionID' in query && '$moduleID' in query) {
      const deepMapping = new DeepMapping(context)

      if (context.method === 'create' || context.method === 'update') {
        context.result = await deepMapping.saveCollection()
      } else if (context.method === 'get') {
        context.result = await deepMapping.getCollection()
      } else if (context.method === 'remove') {
        context.result = await deepMapping.remove()
      } else {
        throw new NotImplemented(`method '${context.method}' isn't implemented`)
      }
    } else {
      throw new BadRequest(
        `params missing, '$collectionID' or '$moduleID' required`
      )
    }
  }

  return context
}
