import { MutationTree } from 'vuex'
import { ConnectivityState } from './connectivity-state.interface'

export const mutations: MutationTree<ConnectivityState> = {
  /**
   * Setzt [[state.online]] auf `true`.
   *
   * @param state - [[ConnectivityState]] aus dem Store.
   */
  setOnline(state: ConnectivityState): void {
    state.online = true
  },

  /**
   * Setzt [[state.online]] auf `false`.
   *
   * @param state - [[ConnectivityState]] aus dem Store.
   */
  setOffline(state: ConnectivityState): void {
    state.online = false
  }
}
