import localforage from 'localforage'
import {
  Application as Feathers,
  HooksObject,
  ServiceMethods
} from '@feathersjs/feathers'
import localStorage from 'feathers-localstorage'
import { PaginationConfig } from './pagination-config.interface'
import { AllowMultiEvents } from './allow-multi-events.enum'
import { buildCreateEntriesHook } from './hooks/opportunistic-update/create-entries.hook'
import { fixParams } from './hooks/fix-params.hook'

/**
 * Liste aller Events die von Basic-Service den Support für mehrere Eintrqge
 * besitzen
 */
export const supportedAllowMultiEvents: Readonly<AllowMultiEvents[]> = [
  AllowMultiEvents.Created,
  AllowMultiEvents.Updated,
  AllowMultiEvents.Removed
]

/**
 * Standardwert für `Standardanzahl der Einträge pro Seite`
 */
export const paginationDefaultValue = 15

/**
 * Standardwert für `Maximalanzahl der Einträge pro Seite`
 */
export const paginationMaxValue = 100

/**
 * Dummy-Service für den Offline-Modus. Dieser wird vom [[BaseService]]
 * verwendet, wenn dieser keine Funktion enthalten soll und alles über die
 * Feathers-Hooks abgearbeitet werden kann.
 */
class DummyService implements Partial<ServiceMethods<unknown>> {
  /**
   * Dummy 'Find'-Methode für den Feathers Service.
   */
  public async find(): Promise<void> {}

  /**
   * Dummy 'Get'-Methode für den Feathers Service.
   */
  public async get(): Promise<void> {}

  /**
   * Dummy 'Create'-Methode für den Feathers Service.
   */
  public async create(): Promise<void> {}

  /**
   * Dummy 'Update'-Methode für den Feathers Service.
   */
  public async update(): Promise<void> {}

  /**
   * Dummy 'Patch'-Methode für den Feathers Service.
   */
  public async patch(): Promise<void> {}

  /**
   * Dummy 'Remove'-Methode für den Feathers Service.
   */
  public async remove(): Promise<void> {}

  /**
   * Dummy 'Setup'-Methode für den Feathers Service.
   */
  public setup(): void {}
}

/**
 * Standardservice für den Offline-Modus
 */
export class BaseService {
  /**
   * Name der Applikation, sollte nicht verändert werden
   */
  protected _appName = 'eplas-offline'

  /**
   * Beschreibung von der Applikation
   */
  protected _appDescription = 'eplas offline modus store'

  /**
   * Name des Service
   */
  protected _service: string

  /**
   * Name der Datenbank
   */
  protected _database: string

  /**
   * Primary-Key der Datenbank
   */
  protected _id: string

  /**
   * Startwert für die ID-Spalte
   */
  public startID = 1

  /**
   * Ein Objekt mit der Zuweißung von mit ID zu einem Element für die
   * Vorinitialisierung des Datenspeichers.
   * Siehe: {@link https://github.com/feathersjs-ecosystem/feathers-localstorage}
   */
  public store: object | null = null

  /**
   * Liste mit Custom-Service-Events
   */
  public events: string[] = []

  /**
   * Paginations-Einstellung
   */
  protected _pagination: PaginationConfig = {
    max: paginationMaxValue,
    default: paginationDefaultValue
  }

  /**
   * Liste mit erlaubten Query-Paramatern.
   * Siehe: {@link https://github.com/crcn/sift.js#supported-operators}
   */
  public whitelist: string[] = [
    '$exists',
    '$eq',
    '$mod',
    '$all',
    '$and',
    '$or',
    '$nor',
    '$not',
    '$size',
    '$type',
    '$regex',
    '$where',
    '$elemMatch'
  ]

  /**
   * Events die mit mehrere Einträge umgehen können
   */
  protected _multi: AllowMultiEvents[]

  /**
   * Das passende Objekt für den Hook für den Service
   */
  protected _hook: HooksObject | null = null

  /**
   * Erstellt einen neuen Standard-Offline-Service
   *
   * @param service - Servicename, wird auch für Adresse und Tabelle verwendet
   * @param database - Datenbankname
   * @param hook - Hook für den Service
   * @param pk - Primary-Key Spalte der Tabelle (Standard: 'id')
   * @param allowMulti - Welche Events mit mehreren Einträge umgehen können
   * > `create`: Liste mit Objekten
   * > `update`: ID erlaubt den Wert NULL
   * > `remove`: ID erlaubt den Wert NULL
   * > true: alle Events
   * > false: keine Events (Standard)
   */
  public constructor(
    service: string,
    database: string,
    hook: HooksObject | null = null,
    pk = 'id',
    allowMulti: boolean | AllowMultiEvents[] = false
  ) {
    this._service = service.trim()
    this._database = database.trim().toLowerCase()
    this._id = pk.trim()

    if (this._service === '') {
      throw new Error('BaseService: service name is missing')
    } else if (this._database === '') {
      throw new Error(
        `BaseService: database is missing by service '${this._service}'`
      )
    } else if (this._id === '') {
      throw new Error(`primary key is missing by service '${this._service}'`)
    }

    this._hook = hook
    this._multi = !Array.isArray(allowMulti)
      ? allowMulti === true
        ? supportedAllowMultiEvents.slice(0)
        : []
      : allowMulti.filter(function (x: AllowMultiEvents): boolean {
          return supportedAllowMultiEvents.indexOf(x) !== -1
        })
  }

  /**
   * Name des Service - wird auch als Tabelle und Adresse verwendet
   *
   * @returns Akteuller DB-Name ohne Prefix
   */
  public get service(): string {
    return this._service
  }

  /**
   * Name der Datenbank
   *
   * @returns Akteuller DB-Name ohne Prefix
   */
  public get database(): string {
    return this._database
  }

  /**
   * Der Primary-Key der Tabelle
   *
   * @returns bei welchen Events
   */
  public get primaryKey(): string {
    return this._id
  }

  /**
   * Die aktuelle Hook-Struktur des Services
   *
   * @returns HookObjekt des Services
   */
  public get hook(): Readonly<HooksObject> | null {
    return this._hook
  }

  /**
   * Events die mit mehrere Einträge umgehen können
   * > `create`: Liste mit Objekten
   * > `update`: ID erlaubt den Wert NULL
   * > `remove`: ID erlaubt den Wert NULL
   *
   * @returns bei welchen Events
   */
  public get multi(): AllowMultiEvents[] {
    return this._multi
  }

  /**
   * Standardanzahl der Einträge pro Seite, wenn `$limit` fehlt
   *
   * @returns aktuellen Wert
   */
  public get paginationDefault(): number {
    return this._pagination.default
  }

  /**
   * > min. 1 ansonsten Standardwert
   *
   * @param value - neuen Wert
   */
  public set paginationDefault(value: number) {
    this._pagination.default = value >= 1 ? value : paginationDefaultValue
  }

  /**
   * Maximalanzahl der Einträge pro Seite
   *
   * @returns aktuellen Wert
   */
  public get paginationMax(): number {
    return this._pagination.max
  }

  /**
   * > min. 1 ansonsten Standardwert
   *
   * @param value - neuen Wert
   */
  public set paginationMax(value: number) {
    this._pagination.max = value >= 1 ? value : paginationMaxValue
  }

  /**
   * Einstellungsobjekt für die Pagination des Service
   *
   * @returns aktuellen Wert
   */
  public get pagination(): PaginationConfig {
    return this._pagination
  }

  /**
   * Regestiert den Service an einer Instanz vom Feathers
   *
   * @param app - Feathers-Instanz, wo der Service Regestiert werden soll
   * @param dummyService - Bei `true` wird nur der [[DummyService]] verwendet.
   * Dadurch werden `before`-Hooks vorausgesetzt!
   */
  public register(app: Feathers<unknown>, dummyService = false): void {
    try {
      const connection = localforage.createInstance({
        name: this._appName,
        storeName: this.database,
        description: this._appDescription,
        driver: localforage.INDEXEDDB
      })

      const hookEvent = buildCreateEntriesHook(this.primaryKey)

      if (this._hook === null) {
        this._hook = {
          before: {
            create: [hookEvent],
            update: [hookEvent],
            find: [fixParams]
          },
          after: [],
          error: []
        }
      } else if (this._hook.before) {
        if (Array.isArray(this._hook.before)) {
          this._hook.before = [hookEvent, ...this._hook.before, fixParams]
        } else if (typeof this._hook.before === 'function') {
          this._hook.before = [hookEvent, this._hook.before, fixParams]
        } else if (
          'all' in this._hook.before ||
          'create' in this._hook.before ||
          'update' in this._hook.before ||
          'remove' in this._hook.before ||
          'find' in this._hook.before
        ) {
          if (Array.isArray(this._hook.before.all)) {
            this._hook.before.all = [hookEvent, ...this._hook.before.all]
          } else if (typeof this._hook.before.all === 'function') {
            this._hook.before.all = [hookEvent, this._hook.before.all]
          } else {
            this._hook.before.all = [hookEvent]
          }

          if (Array.isArray(this._hook.before.find)) {
            this._hook.before.find = [...this._hook.before.find, fixParams]
          } else if (typeof this._hook.before.find === 'function') {
            this._hook.before.find = [this._hook.before.find, fixParams]
          } else {
            this._hook.before.find = [fixParams]
          }
        } else {
          this._hook.before = [hookEvent, fixParams]
        }
      } else {
        this._hook.before = [hookEvent, fixParams]
      }

      if (dummyService !== true) {
        app.use(
          this.service,
          localStorage({
            storage: connection,
            name: this.service,
            id: this.primaryKey,
            startId: this.startID,
            store: this.store,
            events: this.events,
            paginate: this.pagination,
            whitelist: this.whitelist,
            multi: this.multi
          })
        )
      } else {
        app.use(this.service, new DummyService())
      }

      app.service(this.service).hooks(this._hook)
    } catch (ex) {
      const errorMsg = `failed to registered service '${this.service}'`
      throw new Error(errorMsg)
    }
  }
}
