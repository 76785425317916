import { CountryCode } from '@/constants/country-code.enum'

/**
 * Liste an unterstützten Sprachkürzeln.
 */
export const languageList = [
  CountryCode.CZ,
  CountryCode.DE,
  CountryCode.EN,
  CountryCode.FR,
  CountryCode.HR,
  CountryCode.HU,
  CountryCode.IT,
  CountryCode.NL,
  CountryCode.PL,
  CountryCode.RO,
  CountryCode.RU,
  CountryCode.TR,
  CountryCode.GR,
  CountryCode.BG,
  CountryCode.UK,
  CountryCode.FI,
  CountryCode.ES,
  CountryCode.SL
]
