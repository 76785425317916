
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const UIStore = namespace('ui')

/**
 * Seitentitel für die App-Leiste.
 */
@Component({})
export default class PageTitle extends Vue {
  /**
   * Gibt an, ob die mobile Suche gerade aktiv ist.
   */
  @UIStore.State('mobileSearch')
  public mobileSearch!: boolean

  /**
   * Prüft, ob es sich um einen Sprachstring handelt und gibt den Titel zurück
   *
   * @returns Seitentitel als String
   */
  public get metaTitle(): string {
    let title
    if (this.$route.meta !== undefined) {
      title = this.$route.meta.title
    } else {
      title = ''
    }

    if (this.$te(title)) {
      return this.$t(title).toString()
    }

    return title
  }
}
