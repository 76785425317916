
import { Component, Mixins, Prop } from 'vue-property-decorator'
import TabbedCard from '@/components/TabbedCard/TabbedCard.vue'
import { EnvironmentMixin } from '@/mixins/environment.mixin'
import FormattedStatement from '@/components/FormattedStatement/FormattedStatement.vue'

/**
 * Infobereich der Anwendung mit Versionsinformationen, Changelog und Hinweisen
 * zu verwendeter Drittanbietersoftware.
 */
@Component({
  components: {
    TabbedCard,
    FormattedStatement
  }
})
export default class StatementCenter extends Mixins(EnvironmentMixin) {
  /**
   * Legt den default-Tab fest
   */
  @Prop({ type: String, required: true })
  public selected?: string

  /**
   * Tab Items (Statements).
   */
  private items: string[] = ['imprint', 'privacy']

  /**
   * Anzuzeigende Tabs (Sprachkeys).
   *
   * @returns Liste von Sprachstrings.
   */
  private get tabs(): string[] {
    return this.items.map(item => `statementCenter.tabs.${item}`)
  }
}
