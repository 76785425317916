// Wird von Webpack zu Build-Zeit definiert.
declare const VERSION_SEMVER: string
declare const VERSION_CHANNEL: string
declare const VERSION_COMMIT: string

import Vue from 'vue'
import Vuex from 'vuex'
import { RootState } from './root-state.interface'
import { ui } from './ui'
import { notification } from './notifications'
import { user } from './user'
import { connectivity } from './connectivity'
import { language } from './language'
import { dashboardTranslations } from './dashboardTranslations'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  state: {
    version: VERSION_SEMVER,
    channel: VERSION_CHANNEL,
    commit: VERSION_COMMIT,
    environment: process.env.NODE_ENV || 'unknown'
  },
  getters: {
    fullVersion: (state): string => {
      return (
        `${state.version}-` +
        `${state.channel}-` +
        `${state.commit}-` +
        `${
          state.environment === 'development'
            ? 'dev'
            : state.environment === 'production'
            ? 'prod'
            : 'noenv'
        }`
      )
    }
  },
  modules: {
    ui,
    notification,
    user,
    connectivity,
    language,
    dashboardTranslations
  }
})
