export const nl = {
  externalLogin: {
    rule: {
      required: 'Vul alle verplichte velden in.',
      email: 'Het e-mailadres is niet geldig',
      number: 'Dit veld heeft geen nummer'
    },
    buttonLabel: {
      check: 'Controle',
      proceed: 'Verder',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Aanmelden',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Meer informatie'
    },
    field: {
      lastName: 'Achternaam',
      firstName: 'Voornaam',
      company: 'Firma',
      country: 'Land van oorsprong:',
      visitReason: 'Reden voor bezoek',
      email: 'E-mail',
      id: 'Identiteitskaartnummer',
      street: 'Straat/nr.',
      zip: 'Postcode',
      location: 'Plaats',
      phone: 'Telefoon',
      birthdate: 'Geboortedatum',
      training: 'Opleiding',
      salutation: 'Aanhef',
      gender: 'Geslacht:',
      titel: 'Titel',
      prefixName: 'Voorvoegsel',
      suffixName: 'Toevoegsel',
      city: 'Stad',
      workingTime: 'Dienstjaren',
      workingTasks: 'Werkzaam als',
      citizenship: 'Nationaliteit',
      healthInsurance: 'Ziektekostenverzekering',
      healthInsuranceName: 'Naam van de ziektekostenverzekeraar',
      healthInsuranceZip: 'Verzekeraar: postcode',
      healthInsuranceCity: 'Verzekeraar: stad',
      healthInsuranceStreet: 'Verzekeraar: straat',
      birthplace: 'Geboorteplaats',
      letterSalutation: 'Aanhef',
      adressAddition: 'Adrestoevoeging',
      hireDate: 'Datum van indiensttreding',
      leavingDate: 'Datum van ontslag',
      fax: 'Fax'
    },
    gender: {
      notSpecified: 'Geen informatie',
      femail: 'Vrouwelijk',
      male: 'Mannelijk',
      divers: 'Divers'
    },
    salutation: {
      notSpecified: '-',
      female: 'Mevrouw',
      male: 'Mijnheer'
    },
    error: {
      maxLength: 'De maximum lente is oversschreden',
      noVisitReason: 'Deze taal is niet voorhanden. Kies een andere taal.'
    }
  },
  error: {
    0x0000: {
      title: 'Fout',
      text: 'Algemene fout: { message}'
    },
    0x0001: {
      title: 'Fout bij validatie input',
      text: 'Vul de gegevens in de betreffende velden correct in'
    },
    0x0002: {
      title: 'Wederkerende externe medewerker',
      text: 'Wederkerende externe medewerkers zijn niet toegestaan.'
    },
    0x0003: {
      title: 'Server is niet bereikbaar',
      text: 'Server is momenteel niet bereikbaar: {details}'
    },
    0x0004: {
      title: 'Geen API-Secret beschikaar',
      text: 'API-Secret voor de communicatie met de server is niet beschikbaar'
    },
    0x0005: {
      title: 'Geen basis URL beschikbaar',
      text: 'Basis-URL van het eplas frontend is niet beschikbaar'
    },
    0x0006: {
      title: 'Probleem bij het inloggen',
      text: 'Uw verzoek om in te loggen bij eplas kon niet worden uitgevoerd. Neem contact op met uw contactpersoon bij het bedrijf.'
    },
    0x0007: {
      title: 'Uw token is ongeldig',
      text: 'Uw token is ongeldig'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Wachtwoord',
      username: 'Gebruikersnaam'
    },
    welcomeMessage: {
      text: 'Welkom bij eplas!'
    },
    forwardMessage: {
      title: 'Uw heeft met succes ingelogd!',
      text: 'U wordt zo meteen verder geleid...'
    },
    buttonLabel: {
      login: 'Aanmelden'
    },
    loginMethod: {
      local: 'Aanmelden',
      saml: 'Bedrijfslogin',
      ldap: 'Windows login',
      external: 'Externe medewerker(s)',
      pin: 'Pincode-invoer'
    },
    forgotPassword: {
      button: 'Wachtwoord vergeten?'
    },
    error: {
      noOfflineLogin: 'Geen wachtwoord beschikbaar in off-line modus',
      username: 'Login mislukt. Controleer uw gebruikersnaam en wachtwoord.',
      usernameValidation: 'Gebruikersnaam vereist',
      passwordValidation: 'Wachtwoord vereist'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Herhaal wachtwoord',
      setPasswordConfirm: 'Wachtwoord aanmaken',
      setPasswordTitle: 'Verander uw wachtwoord als u voor het eerst inlogt',
      success: 'Wachtwoord met succes gewijzigd'
    },
    passwordRules: {
      title: 'Richtlijnen voor wachtwoorden',
      maximumLength: 'Het wachtwoord is te lang.',
      minimumLength: 'Het wachtwoord is te kort',
      minimumDigits: 'Het wachtwoord bevat niet genoeg cijfers',
      minimumUpperCase: 'Het wachtwoord bevat niet genoeg hoofdletters',
      minimumLowerCase: 'Het wachtwoord bevat niet genoeg kleine letters',
      minimumSpecialCharacters:
        'Het wachtwoord bevat niet genoeg speciale tekens',
      allowedSpecialCharacters: 'Het wachtwoord bevat niet-toegestane tekens.',
      general: 'Er is een fout opgetreden bij de wijziging van het wachtwoord.',
      passwordConfirmationValidation: 'De twee wachtwoorden komen niet overeen.'
    },
    buttonLabel: {
      login: 'Naar  login-pagina'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Wachtwoord vergeten?',
      email: 'E-mailadres van uw gebruiker',
      confirm: 'Verzend e-mail',
      error: 'Het e-mailadres is ongeldig',
      cancel: 'Annuleren',
      hint: 'Met die link kunt u een nieuw wachtwoord aanmaken. Neem contact op met de systeembeheerder als u uw e-mailadres vergeten bent of als uw gebruiker geen e-mailadres heeft.',
      emailSent: 'De e-mail is verstuurd.'
    }
  },
  passwordReset: {
    title: 'Wijzig uw wachtwoord'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Gegevens updaten'
    },
    text: {
      welcome: 'Fijn dat u zich voor het eerst bij e-plas aanmeldt',
      employeeInfos:
        'Vul de hieronder gegvraagde gegevens in en controleer of ze kloppen.',
      password: 'Kies een wachtwoord'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'intern mobiel',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'U bent nu online',
        offline: 'U bent nu offline'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Afdrukken',
      search: 'Zoeken',
      close: 'Sluiten',
      create: 'Aanmaken',
      edit: 'Bewerken',
      copy: 'Kopiëren',
      back: 'Terug',
      remove: 'Verwijderen',
      abort: 'Overslaan',
      finished: 'Klaar',
      ok: 'OK',
      cancel: 'Annuleren'
    }
  },
  router: {
    title: {
      login: 'Log in'
    }
  }
}
