import { Storage } from '@feathersjs/authentication-client'
import jsCookie from 'js-cookie'

/**
 * Zum Feathers-Authentifizierungs-Client kompatible Storage-Schnittstelle, die
 * Cookies zum Speichern verwendet.
 */
class CookieStorage implements Storage {
  /**
   * Liest den Wert aus einem Cookie aus.
   *
   * @param key - Key bzw. Name des Cookies.
   * @returns Wert des Cookies oder nichts, wenn der Cookie nicht existiert.
   */
  public getItem(key: string): Promise<string | void> {
    return new Promise((resolve): void => {
      resolve(jsCookie.get(key))
    })
  }

  /**
   * Aktualisiert den Wert eines Cookies oder legt einen an, falls kein Cookie
   * mit dem Key existiert. Das `same-site`-Attribut wird auf `strict` gesetzt,
   * um CSRF zu mitigieren.
   * Siehe <https://caniuse.com/#feat=same-site-cookie-attribute>.
   *
   * @param key - Key bzw. Name des Cookies.
   * @param value - Wert, auf den der Cookie gesetzt werden soll.
   * @returns Leere Promise.
   */
  public setItem(key: string, value: string | object): Promise<void> {
    jsCookie.set(key, value, {
      sameSite: 'strict',
      secure: process.env.NODE_ENV !== 'development',
      expires: 365
    })

    return new Promise((resolve): void => {
      resolve()
    })
  }

  /**
   * Entfernt einen Cookie.
   *
   * @param key - Key bzw. Name des Cookies.
   * @returns Leere Promise.
   */
  public removeItem(key: string): Promise<void> {
    jsCookie.remove(key)

    return new Promise((resolve): void => {
      resolve()
    })
  }
}

/**
 * Standardinstanz für [[CookieStorage]].
 */
export const cookieStorage = new CookieStorage()
