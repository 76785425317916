import { HookContext, Params } from '@feathersjs/feathers'
import { BadRequest, NotFound } from '@feathersjs/errors'
import { OfflineEntry } from '../../../offline-entry.interface'
import { CustomPaginated } from '../../../../interfaces/CustomPaginated.interface'
import { DeepMappingEntry } from './deep-mapping-entry.interface'

/**
 * Klasse mit der Funktionalität für den Service 'checklists-collections/deep'.
 * Dieser wird durch den Hook [[deepMapping]] aufgerufen.
 *
 * Zum Speichern der Informationen werden die nachfolgenden Services verwendet:
 * - 'checklists-collections/deep/mapping'
 * - 'checklists-collections/deep/collections'
 */
export class DeepMapping {
  /**
   * Hook-Kontext eines Feathers-Hooks. Besitzt alle Informationen der
   * eigentlichen Abfrage und die benötigten Feathers-Instanzen.
   */
  private hookContext: HookContext

  /**
   * Service-Pfad für die Mapping-Einträge
   */
  private servicePathMapping = 'checklists-collections/deep/mapping'

  /**
   * Service-Pfad für die Collection-Einträge
   */
  private servicePathCollection = 'checklists-collections/deep/collections'

  /**
   * ID der Collection, wird im Konstruktor gesetzt. Ist keine ID vorhanden, ist
   * dieses Property auf 0 gesetzt.
   */
  public readonly collectionID: number

  /**
   * ID des Modules, wird im Konstruktor gesetzt. Ist keine ID vorhanden, ist
   * dieses Property auf 0 gesetzt.
   */
  public readonly moduleID: number

  /**
   * Reihenfolge der Sortierung, wird für das Wiederfinden des passenden
   * Eintrages benötigt.
   */
  public readonly order: string

  /**
   * Erzeugt eine neue Instanz von [[DeepMapping]]. Dieser wird mit den Kontext
   * eines Feathers-Hook erzeugt.
   *
   * @param hookContext - Kontext eines Feathers-Hook
   */
  public constructor(hookContext: HookContext) {
    if (!hookContext.params || !hookContext.params.query) {
      throw new BadRequest(
        `params missing, '$collectionID' or '$moduleID' required`
      )
    }

    const query = hookContext.params.query
    this.hookContext = hookContext

    if (query.$moduleID) {
      const moduleID =
        typeof query.$moduleID === 'number'
          ? query.$moduleID
          : parseInt(query.$moduleID, 10)

      this.moduleID = !isNaN(moduleID) && moduleID > 0 ? moduleID : 0
    } else {
      this.moduleID = 0
    }

    // Wenn Module-ID gesetzt ist kann Collection-ID ignoriert werden
    if (query.$collectionID && this.moduleID === 0) {
      const collectionID =
        typeof query.$collectionID === 'number'
          ? query.$collectionID
          : parseInt(query.$collectionID, 10)

      this.collectionID =
        !isNaN(collectionID) && collectionID > 0 ? collectionID : 0
    } else {
      this.collectionID = 0
    }

    if (query.$orderBy) {
      const orderBy = query.$orderBy.toString().trim().toLowerCase()
      const orderDir =
        (query.$orderDirection || '').toString().toLowerCase().trim() !== 'desc'
          ? 'asc'
          : 'desc'

      this.order = `${orderBy || 'default'} ${orderDir}`
    } else {
      this.order = `default asc`
    }
  }

  /**
   * Gibt das Query-Objekt für das Finden des Verknüpfungseintrages zurück.
   *
   * @returns Query zum finden des Verknüpfungseintrages
   */
  public getParams(): Params {
    return {
      query: {
        queryCollectionID: this.collectionID,
        queryModuleID: this.moduleID,
        queryOrder: this.order
      }
    }
  }

  /**
   * Gibt den Verknüpfungseintrag zurück. Diese wird anhand der Query-Parmatern
   * des Hooks gesucht, der dem Konstruktor mitgegeben wurde.
   *
   * @returns Passender Verknüpfungseintrag.
   */
  public async getEntry(): Promise<DeepMappingEntry> {
    const params = this.getParams()
    const result: CustomPaginated<unknown> = await this.hookContext.app
      .service(this.servicePathMapping)
      .find(params)
      .catch(
        (): CustomPaginated<unknown> => ({
          total: 0,
          data: [],
          skip: 0,
          limit: 0
        })
      )

    if (!result || result.total === 0) {
      throw new NotFound(`entry not found`, params)
    }

    return result.data[0] as DeepMappingEntry
  }

  /**
   * Gibt die passende verknüpfte Collection zurück. Diese wird anhand der
   * Query-Parmatern des Hooks gesucht, der dem Konstruktor mitgegeben wurde.
   *
   * @returns Checklisten Collection als [[OfflineEntry]]-Objekt
   */
  public async getCollection(): Promise<OfflineEntry> {
    const service = this.hookContext.app.service(this.servicePathCollection)
    const entry: DeepMappingEntry = await this.getEntry()
    const result = (await service.get(entry.collectionID)) as OfflineEntry
    return result
  }

  /**
   * Speichert die Collection und dessen Verknüpfungseintrag im Offline-Speicher.
   * Alle benötigten Daten werden direkt über den Hook-Kontext ausgelesen,
   * dieser wurde dem Konstruktor mitgegeben.
   *
   * @returns Der gespeicherte Verknüpfungseintrag.
   */
  public async saveCollection(): Promise<DeepMappingEntry> {
    const context = this.hookContext
    const collection: OfflineEntry = context.data as OfflineEntry

    // Hook-Kontext prüfen
    if (context.method !== 'update' && context.method !== 'create') {
      throw new BadRequest(
        `saveCollection supported only 'update' and 'create'`,
        context.method
      )
    } else if (!collection.id || collection.id < 0) {
      throw new BadRequest(
        'property `id` from `collection` is invalid',
        collection
      )
    }

    // Collection speichern
    const serviceCollection = context.app.service(this.servicePathCollection)
    const existCollection = await serviceCollection.get(collection.id).then(
      (result: OfflineEntry): boolean => !!result,
      (): boolean => false
    )

    if (existCollection) {
      await serviceCollection.update(collection.id, collection)
    } else {
      await serviceCollection.create(collection)
    }

    // Verknüpfungseintrag speichern
    const mapping = await this.getEntry().then(
      (result): DeepMappingEntry => result as DeepMappingEntry,
      (): DeepMappingEntry => ({
        collectionID: collection.id,
        queryCollectionID: this.collectionID,
        queryModuleID: this.moduleID,
        queryOrder: this.order
      })
    )
    const serviceMapping = context.app.service(this.servicePathMapping)
    let result

    if (mapping && mapping.id) {
      result = await serviceMapping.patch(mapping.id, {
        collectionID: collection.id
      })
    } else {
      result = await serviceMapping.create(mapping)
    }

    return result
  }

  /**
   * Löscht den entsprechenden Verknüpfungseintrag und gibt diesen zurück. Der
   * verwendete Collection-Eintrag wird nicht immer mit gelöscht. Dieser wird
   * nur gelöscht wenn es keine anderen Verknüpfungseinträge mit Verweise auf
   * ihn existieren.
   *
   * @returns Der gelöschte Verknüpfungseintrag.
   */
  public async remove(): Promise<DeepMappingEntry> {
    const service = this.hookContext.app.service(this.servicePathMapping)
    const entry = await this.getEntry()
    const result = await service.remove(entry.id)

    await service.find({ query: { collectionID: entry.collectionID } }).then(
      (result: CustomPaginated<unknown>): void => {
        if (result.total === 0) {
          this.hookContext.app
            .service(this.servicePathCollection)
            .remove(entry.collectionID)
            .catch((): void => {})
        }
      },
      (): void => {}
    )

    return result
  }
}
