import { VuetifyThemeVariant } from 'vuetify/types/services/theme'

/**
 * Alias für [[VuetifyThemeVariant]], wo aber alle Properties optional sind.
 */
export type TypeTheme = Partial<VuetifyThemeVariant>

/**
 * Liste mit den Einstellungen für die jeweiligen Themes.
 */
export type TypeThemeList = Record<'dark' | 'light', TypeTheme>

/**
 * Die Standardfarben für das Dark-Theme.
 */
export const DefaultDarkTheme: Readonly<TypeTheme> = {
  primary: '#6aa0c1',
  secondary: '#1d2c35',
  accent: '#1c2a33'
}

/**
 * Die Standardfarben für das Light-Theme.
 */
export const DefaultLightTheme: Readonly<TypeTheme> = {
  primary: '#f59c00',
  secondary: '#ffac19',
  accent: '#476c81',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
}
