/**
 * Konvertierungsvorgänge von einem String in einen anderen Datentyp.
 *
 * Replik auf der Server-Seite, unter `/server/src/feathers/conversion.enum.ts`. Alle Anpassungen
 * entsprechend auch dort durchführen!
 */
export enum Conversion {
  /**
   * Konvertiert den String mit `parseFloat` in eine Zahl.
   */
  Number = 'number',

  /**
   * Konvertiert den String in einen Boolean. Gültige Werte sind:
   * - `'1'`, `'true'` für `true`
   * - `'0'`, `'false'` für `false`
   */
  Boolean = 'boolean',

  /**
   * Konvertiert den String in ein `Date`. Dazu wird der String an den
   * `new Date()`-Konstruktor übergeben.
   */
  Date = 'date'
}
