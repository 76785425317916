import { checkPermissions, Permission, Legacy } from '@nxt/permissions'
import store from '@/store'

/**
 * Stellt statische Funktionen zum Überprüfen der Berechtigungen des
 * angemeldeten Benutzers bereit.
 */
export class PermissionHelper {
  /**
   * Prüft, ob der angemeldete Benutzer die erforderliche Berechtigung hat.
   *
   * @param permission - Erforderliche Berechtigung.
   * @returns true, wenn der Benutzer die erforderliche Berechtigung hat,
   * ansonsten false.
   */
  public static hasPermission(permission: Permission): boolean {
    return this.hasPermissions([permission])
  }

  /**
   * Prüft, ob der angemeldete Benutzer alle erforderlichen Berechtigungen hat.
   *
   * @param permissions - Alle erforderlichen Berechtigungen.
   * @returns true, wenn der Benutzer alle erforderlichen Berechtigungen hat,
   * ansonsten false.
   */
  public static hasPermissions(permissions: Permission[]): boolean {
    const userPermissions = store.getters['user/permissions']

    return checkPermissions(permissions, userPermissions)
  }

  /**
   * Entzieht dem Benutzer alle Berechtigungen - mit Ausnahme
   * von "Einstellung", sofern vorhanden - wenn die Lizenz abgelaufen ist
   */
  public static async revokePermissionsOnInvalidLicense(): Promise<void> {
    const licenseVal = localStorage.getItem('license')
    const userObj = localStorage.getItem('lastUser')

    if (
      licenseVal &&
      (licenseVal === '0' || licenseVal === '-1') &&
      userObj !== null
    ) {
      const userObjParsed = userObj ? JSON.parse(userObj) : ''
      const permissions: Permission[] = userObjParsed.permissions

      const settingsPermission = permissions.find(
        v => v.permission === Legacy.Permission1
      )
      const changedPermissions = settingsPermission ? [settingsPermission] : []

      userObjParsed.permissions = changedPermissions
      localStorage.setItem('lastUser', JSON.stringify(userObjParsed))
      await store.dispatch('user/updateUser', userObjParsed)
    }
  }
}
