import { ActionTree } from 'vuex'
import { UserState } from './user-state.interface'
import { RootState } from '../root-state.interface'

export const actions: ActionTree<UserState, RootState> = {
  /**
   * Führt die Mutation 'updateUser' aus.
   *
   * @param user - Benutzer-Objekt.
   * @returns Leere Promise.
   */
  updateUser({ commit }, user: unknown): Promise<void> {
    return new Promise((resolve): void => {
      resolve(commit('updateUser', user))
    })
  }
}
