import { UIState } from './ui-state.interface'

export const state: UIState = {
  globalSearch: '',
  mobileSearch: false,
  showGlobalFilter: false,
  globalSearchColumns: [],
  columns: [],
  menuBadges: {},
  filters: [],
  theme: {
    light: {},
    dark: {}
  }
}
