export const hr = {
  externalLogin: {
    rule: {
      required: 'Molimo, popunite sva obvezna polja.',
      email: 'Adresa e-pošte nije važeća.',
      number: 'Ovo polje mora sadržavati broj.'
    },
    buttonLabel: {
      check: 'Provjera',
      proceed: 'Dalje',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Prijava',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Dodatne informacije'
    },
    field: {
      lastName: 'Prezime',
      firstName: 'Ime',
      company: 'Tvrtka',
      country: 'Zemlja podrijetla',
      visitReason: 'Razlog posjeta',
      email: 'Adresa e-pošte',
      id: 'Br. isprave',
      street: 'Ulica / br.',
      zip: 'Poštanski broj',
      location: 'Mjesto',
      phone: 'Telefon',
      birthdate: 'Datum rođenja',
      training: 'Obrazovanje',
      salutation: 'Oslovljavanje',
      gender: 'Spol',
      titel: 'Titula',
      prefixName: 'Prefiks imena',
      suffixName: 'Sufiks imena',
      city: 'Grad',
      workingTime: 'Radno vrijeme',
      workingTasks: 'Radi u svojstvu',
      citizenship: 'Državljanstvo',
      healthInsurance: 'Zavod za zdravstveno osiguranje',
      healthInsuranceName: 'Ime zavoda za zdravstveno osiguranje',
      healthInsuranceZip: 'Zavod za zdravstveno osiguranje: Poštanski broj',
      healthInsuranceCity: 'Zavod za zdravstveno osiguranje: Grad',
      healthInsuranceStreet: 'Zavod za zdravstveno osiguranje: Ulica',
      birthplace: 'Mjesto rođenja',
      letterSalutation: 'Oslovljavanje u pismu',
      adressAddition: 'Adresni dodatak',
      hireDate: 'Datum zaposlenja',
      leavingDate: 'Datum prestanka zaposlenja',
      fax: 'Telefaks'
    },
    gender: {
      notSpecified: 'Nema podatka',
      femail: 'ženski',
      male: 'muški',
      divers: 'različit'
    },
    salutation: {
      notSpecified: '-',
      female: 'Gospođa',
      male: 'Gospodin'
    },
    error: {
      maxLength: 'Prekoračena je maksimalna duljina',
      noVisitReason:
        'Na ovom jeziku nema razloga za posjetu. Molimo odaberite drugi jezik.'
    }
  },
  error: {
    0x0000: {
      title: 'Pogreška',
      text: 'Opća pogreška: {message}'
    },
    0x0001: {
      title: 'Pogreška pri provjeravanju valjanosti unosa',
      text: 'Molimo unesite podatke ispravno u odgovarajuće polje.'
    },
    0x0002: {
      title: 'Izvođač koji se vraća',
      text: 'Izvođači koji se ponavljaju nisu dopušteni.'
    },
    0x0003: {
      title: 'ne može doći do servera',
      text: 'Poslužitelj trenutno nije dostupan. Molimo pokušajte ponovo kasnije. Pojedinosti: {details}'
    },
    0x0004: {
      title: 'Nema dostupne tajne za API',
      text: 'Tajna API-ja za komunikaciju s poslužiteljem nije dostupna.'
    },
    0x0005: {
      title: 'Nije dostupan osnovni URL',
      text: 'Osnovni URL eplas frontenda nije dostupan.'
    },
    0x0006: {
      title: 'Problem s prijavom',
      text: 'Vaša registracija u eplasu nije mogla biti dovršena. Molimo kontaktirajte svoju kontakt osobu u tvrtki.'
    },
    0x0007: {
      title: 'Vaš token je nažalost nevažeći ili je istekao.',
      text: 'Vaš token je nevažeći'
    }
  },
  loginMask: {
    loginInput: {
      password: 'Zaporka',
      username: 'Ime korisnik/a(ce)'
    },
    welcomeMessage: {
      text: 'Dobro došli u eplas!'
    },
    forwardMessage: {
      title: 'Prijava uspješna!',
      text: 'Bit ćete preusmjereni svakog trenutka...'
    },
    buttonLabel: {
      login: 'Prijava'
    },
    loginMethod: {
      local: 'Prijava',
      saml: 'Prijava tvrtke',
      ldap: 'Windows prijava',
      external: 'Inozemn/i(a) zaposleni/k(ca)',
      pin: 'Unesite PIN'
    },
    forgotPassword: {
      button: 'Zaboravili ste zaporku?'
    },
    error: {
      noOfflineLogin: 'Prijava u izvanmrežnom načinu rada nije moguća!',
      username:
        'Prijava nije uspjela. Provjerite svoje korisničko ime i zaporku.',
      usernameValidation: 'Potrebno je korisničko ime.',
      passwordValidation: 'Potrebna je zaporka.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Ponovite zaporku.',
      setPasswordConfirm: 'Postavite zaporku.',
      setPasswordTitle: 'Prva prijava: Molimo promijenite svoju zaporku.',
      success: 'Zaporka uspješno promijenjena.'
    },
    passwordRules: {
      title: 'Pravila za postavljanje zaporke.',
      maximumLength: 'Zaporka je predugačka.',
      minimumLength: 'Zaporka je prekratka.',
      minimumDigits: 'Zaporka ne sadrži dovoljno znamenki.',
      minimumUpperCase: 'Zaporka ne sadrži dovoljno velikih slova.',
      minimumLowerCase: 'Zaporka ne sadrži dovoljno malih slova.',
      minimumSpecialCharacters: 'Zaporka ne sadrži nikakve posebne znakove.',
      allowedSpecialCharacters: 'Zaporka sadrži nevažeće posebne znakove.',
      general: 'Došlo je do pogreške prilikom promjene zaporke.',
      passwordConfirmationValidation: 'Zaporke se ne podudaraju.'
    },
    buttonLabel: {
      login: 'Idite na stranicu za prijavu'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Zaboravili ste zaporku?',
      email: 'Adresa e-pošte vašeg korisnika',
      confirm: 'Pošalji e-mail',
      error: 'Adresa e-pošte nije važeća.',
      cancel: 'Prekini',
      hint: 'Unesite adresu e-pošte vašeg korisnika. Zatim ćete dobiti e-poruku s vezom. Možete koristiti ovu vezu za odabir nove zaporke. Ako ne znate svoju adresu e-pošte ili vaš korisnik nije pohranio adresu e-pošte, obratite se svom odgovornom administratoru.',
      emailSent: 'E-poruka je poslana.'
    }
  },
  passwordReset: {
    title: 'Molimo promijenite svoju zaporku.'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Ažurirati podatke'
    },
    text: {
      welcome: 'Dobrodošli u eplas!',
      employeeInfos:
        'Molimo ispunite dolje prikazane podatke ili provjerite jesu li točni.',
      password: 'Molimo odaberite lozinku'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'interni mobilni',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Sada ste na mreži',
        offline: 'Sada ste izvan mreže'
      },
      languageOverridden:
        'Jezik koji ste prethodno odabrali nije dostupan na ovom sustavu. Jezik je postavljen na zadani jezik.'
    },
    general: {
      print: 'Ispiši',
      search: 'Traži',
      close: 'Zatvori',
      create: 'Kreiraj',
      edit: 'Uredi',
      copy: 'Kopiraj',
      back: 'Povratak',
      remove: 'Ukloni',
      abort: 'Preskoči',
      finished: 'Završeno',
      ok: 'Ok',
      cancel: 'Prekini'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
