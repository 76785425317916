import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
  de,
  en,
  fr,
  bg,
  cz,
  fi,
  gr,
  hr,
  hu,
  it,
  nl,
  pl,
  ro,
  ru,
  tr
} from './../assets/language-data'
import { state } from '@/store/language/state'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: state.default, // set locale
  fallbackLocale: state.fallback,
  messages: { de, en, fr, bg, cz, fi, gr, hr, hu, it, nl, pl, ro, ru, tr }
})
