import { HookContext, Service, Hook } from '@feathersjs/feathers'
import { OfflineEntry } from '../../offline-entry.interface'
import { Methods } from './methods.enum'

/**
 * Gibt den Hook [[CreateEntriesHook]] zurück, welcher mit den angegebenen
 * Primary-Key funktioniert.
 *
 * @param pkName - Name des Primary-Keys, der innerhalb des Hooks geprüft werden
 * soll
 * @returns Hook [[CreateEntriesHook]]
 */
export function buildCreateEntriesHook(pkName: string): Hook {
  /**
   * Dieser Hook ist für das opportunistisches Aktualisierungs der
   * Offline-Einträge relevant. Er fügt dem Eintrag benötigte Eintrqge hinzu,
   * wodurch diese später einfacher wiedergefunden und bearbeitet werden können.
   *
   * Alle Änderungen an den Eintrag, die immer beim hinzufügen eines neuen
   * Eintrages in die Offline-Datenbank passieren sollen, sollten durch diesen
   * Hook durchgeführt werden!
   *
   * @param context - Der [[HookContext]] der ausgeführten Abfrage von Feathers.
   * @returns Rückgabe des HookContext
   */
  return function createEntriesHook(
    context: HookContext<unknown, Service<unknown>>
  ): HookContext<unknown, Service<unknown>> {
    const method: Methods = context.method as Methods

    if (
      (method === Methods.Create || method === Methods.Update) &&
      context.type === 'before'
    ) {
      // `context.params._entryID` wird durch den Hook [[saveEntriesHook]]
      // gesetzt. Dieser entspricht dem Wert `context.id` der Online-Abfrage.
      // Entsprechend wird dieser hier wieder `context.id` zugeordnet, aber auch
      // nur wenn dieser ein String oder Number ist und `context.id` noch keinen
      // Wert besitzt.
      if (
        !context.id &&
        context.params &&
        ['string', 'number'].indexOf(typeof context.params._entryID) !== -1
      ) {
        context.id = context.params._entryID
      }

      // Setzt die Properties '_offlineAt' und '_existsOnlyOffline'', wenn der
      // zu speichernde Wert ein Objekt ist
      if (context.data) {
        if (!Array.isArray(context.data)) {
          const entry: OfflineEntry = context.data as OfflineEntry

          if (entry && typeof entry === 'object') {
            entry._offlineAt = new Date()
            entry._existsOnlyOffline = !entry[pkName]
          }
        } else {
          const entries: OfflineEntry[] = context.data as OfflineEntry[]

          if (entries.length) {
            for (const entry of entries) {
              // Abfangen von Fehlern bei der Eingabe der Einträge.
              if (entry && typeof entry === 'object') {
                entry._offlineAt = new Date()
                entry._existsOnlyOffline = !entry[pkName]
              }
            }
          }
        }
      }
    }

    return context
  }
}
