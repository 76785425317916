export const cz = {
  externalLogin: {
    rule: {
      required: 'Vyplňte všechna povinná pole.',
      email: 'E-mailová adresa je neplatná.',
      number: 'Toto pole musí obsahovat číslo.'
    },
    buttonLabel: {
      check: 'Zkontrolovat',
      proceed: 'Pokračovat',
      cancel: '@system.general.cancel',
      ok: '@system.general.ok',
      login: 'Přihlásit',
      back: '@system.general.back'
    },
    title: {
      additionalInfo: 'Další informace'
    },
    field: {
      lastName: 'Příjmení',
      firstName: 'Jméno',
      company: 'Firma',
      country: 'Země původu:',
      visitReason: 'Důvod návštěvy',
      email: 'E-mail',
      id: 'Průkaz č.',
      street: 'Ulice / č.',
      zip: 'PSČ',
      location: 'Místo',
      phone: 'Telefon',
      birthdate: 'Datum narození',
      training: 'Vzdělání',
      salutation: 'Oslovení',
      gender: 'Pohlaví',
      titel: 'Titulek',
      prefixName: 'Označení před jménem',
      suffixName: 'Přídomek',
      city: 'Město',
      workingTime: 'Pracovní doba',
      workingTasks: 'Zaměstnání jako',
      citizenship: 'Státní příslušnost',
      healthInsurance: 'Zdravotní pojišťovna',
      healthInsuranceName: 'Jméno zdravotní pojišťovny',
      healthInsuranceZip: 'Pojišťovna: PSČ',
      healthInsuranceCity: 'Pojišťovna: město',
      healthInsuranceStreet: 'Pojišťovna: ulice',
      birthplace: 'Místo narození',
      letterSalutation: 'Oslovení v dopise',
      adressAddition: 'Dodatek adresy',
      hireDate: 'Datum nástupu do zaměstnání',
      leavingDate: 'Datum výstupu',
      fax: 'Fax'
    },
    gender: {
      notSpecified: 'Žádný údaj',
      femail: 'ženský',
      male: 'mužský',
      divers: 'různý'
    },
    salutation: {
      notSpecified: '-',
      female: 'Paní',
      male: 'Pán'
    },
    error: {
      maxLength: 'Byla překročena maximální délka.',
      noVisitReason:
        'Důvody návštěvy nejsou k dispozici. Zvolte prosím jiný jazyk.'
    }
  },
  error: {
    0x0000: {
      title: 'Chyba',
      text: 'Všeobecná chyba: {message}'
    },
    0x0001: {
      title: 'Chyba při ověřování vstupu',
      text: 'Údaje zadejte správně do příslušného pole.'
    },
    0x0002: {
      title: 'Vracející se externí zaměstnanec',
      text: 'Návraty externích zaměstnanců nejsou povoleny.'
    },
    0x0003: {
      title: 'Nelze se spojit se serverem',
      text: 'Server je momentálně nedostupný. Zkuste to prosím později. Podrobnosti: {details}'
    },
    0x0004: {
      title: 'Není k dispozici žádné tajemství API',
      text: 'API secret  pro komunikaci se serverem není k dispozici.'
    },
    0x0005: {
      title: 'Základní url není k dispozici',
      text: 'Základní adresa URL frontendu není k dispozici.'
    },
    0x0006: {
      title: 'problém s přihlášením',
      text: 'Vaše registrace v systému eplas se nezdařila. Kontaktujte prosím svou kontaktní osobu.'
    },
    0x0007: {
      title:
        'Je nám líto, ale váš token je neplatný nebo jeho platnost vypršela.',
      text: 'Je nám líto, ale váš token je neplatný nebo jeho platnost vypršela.'
    }
  },
  loginMask: {
    loginInput: {
      password: '',
      username: 'Uživatelské jméno'
    },
    welcomeMessage: {
      text: 'Vítejte v eplas!'
    },
    forwardMessage: {
      title: 'Login úspěšný!',
      text: 'Okamžitě budete předáni...'
    },
    buttonLabel: {
      login: ''
    },
    loginMethod: {
      local: 'Přihlásit',
      saml: 'podnikové logování',
      ldap: 'Přihlášení do systému Windows',
      external: 'Externí zaměstnanec',
      pin: 'Zadejte kód PIN'
    },
    forgotPassword: {
      button: 'Zapomněli jste heslo?'
    },
    error: {
      noOfflineLogin: 'V režimu offline není k dispozici žádné přihlášení!',
      username:
        'Přihlášení se nezdařilo. Zkontrolujte své uživatelské jméno a heslo.',
      usernameValidation: 'Vyžaduje se uživatelské jméno.',
      passwordValidation: 'Vyžaduje se heslo.'
    }
  },
  setPasswordMask: {
    password: {
      passwordConfirmation: 'Opakujte heslo',
      setPasswordConfirm: 'Nastavit heslo',
      setPasswordTitle: 'První přihlášení: Změňte prosím své heslo',
      success: 'Heslo bylo úspěšně změněno'
    },
    passwordRules: {
      title: 'Pravidla pro zadávání hesel',
      maximumLength: 'Heslo je příliš dlouhé.',
      minimumLength: 'Heslo je příliš krátké.',
      minimumDigits: 'Heslo neobsahuje dostatečný počet číslic.',
      minimumUpperCase: 'Heslo neobsahuje dostatečný počet velkých písmen.',
      minimumLowerCase: 'Heslo neobsahuje dostatečný počet malých písmen.',
      minimumSpecialCharacters:
        'Heslo neobsahuje dostatečný počet speciálních znaků.',
      allowedSpecialCharacters: 'Heslo obsahuje zakázané speciální znaky.',
      general: 'Při změně hesla došlo k chybě.',
      passwordConfirmationValidation: 'Obě hesla se neshodují.'
    },
    buttonLabel: {
      login: 'Přejděte na přihlašovací stránku'
    }
  },
  forgotPasswordMask: {
    forgotPassword: {
      title: 'Zapomněli jste heslo?',
      email: 'E-mailová adresa uživatele',
      confirm: 'Poslat e-mail',
      error: 'E-mailová adresa je neplatná.',
      cancel: 'Zrušit',
      hint: 'Zadejte prosím e-mailovou adresu uživatele. Bude vám zaslán e-mail s odkazem. Pomocí tohoto odkazu můžete nastavit nové heslo. Pokud e-mailovou adresu neznáte nebo pokud váš uživatel žádnou e-mailovou adresu nemá, obraťte se na správce systému.',
      emailSent: 'E-mail byl odeslán.'
    }
  },
  passwordReset: {
    title: 'Prosím změňte své heslo'
  },
  pinLogin: {
    buttonLabel: {
      check: '@:externalLogin.buttonLabel.check',
      updateData: 'Aktualizovat data'
    },
    text: {
      welcome: 'Pěkné, že se poprvé přihlašujete do eplas.',
      employeeInfos:
        'Vyplňte prosím dole zobrazená data resp. zkontrolujte jejich správnost.',
      password: 'Zvolte prosím heslo'
    },
    field: {
      pin: 'PIN',
      firstName: '@:externalLogin.field.firstName',
      lastName: '@:externalLogin.field.lastName',
      email: '@:externalLogin.field.email',
      phone: '@:externalLogin.field.phone',
      mobile: 'interní mobil',
      birthday: '@:externalLogin.field.birthdate'
    },
    rule: {
      required: '@:externalLogin.rule.required',
      email: '@:externalLogin.rule.email'
    }
  },
  system: {
    notification: {
      connectivity: {
        online: 'Nyní jste online.',
        offline: 'Nyní jste offline.'
      },
      languageOverridden: ''
    },
    general: {
      print: 'Tisk',
      search: 'Hledání',
      close: 'Zavřít',
      create: 'Vytvořit',
      edit: 'Upravit',
      copy: 'kopírovat',
      back: 'Zpět',
      remove: 'Odstranit',
      abort: 'Přeskočit',
      finished: 'Hotovo',
      ok: 'Ok',
      cancel: 'Zrušit'
    }
  },
  router: {
    title: {
      login: ''
    }
  }
}
