/**
 * Die möglichen Events von den Offline-Service die mit mehreren Eintrqgen
 * umgehen können bzw. um dieses zu aktivieren
 */
export enum AllowMultiEvents {
  /**
   * Event: Hinzufügen/Erstellen
   * Das Create-Event erwartet dann ein Array und kein Objekt
   */
  Created = 'create',
  /**
   * Event: Updaten/Bearbeiten
   * Der `id`-Paramater erlaubt auch `NULL`
   */
  Updated = 'update',
  /**
   * Event: Löschen/Entfernen
   * Der `id`-Paramater erlaubt auch `NULL`
   */
  Removed = 'remove'
}
